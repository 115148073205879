import React, { useState } from "react";
import { IconButton, Popover, Typography, useTheme } from "@mui/material";
import Settings from "./Settings";
import { ReactComponent as GearIcon } from "icons/UI/gear.svg";
import useEventTracker from "api/hooks/useEventTracker";

const useStyles = ({ theme }) => ({
  btn: {
    color: theme.palette.text.muted,
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "16px",
    padding: "6px",
  },
  popoverPaper: {
    background: "rgb(29, 26, 45)",
    border: "1px solid " + theme.palette.border.popup,
    width: "513px",
  },
  title: {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#fff",
    padding: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default function ProjectSettings() {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const tracker = useEventTracker();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "settings-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    tracker.track(
      "Clicked project settings",
      "click",
      "",
      "Top bar / project settings"
    );
  };

  return (
    <div>
      <IconButton
        sx={styles.btn}
        onClick={handleOpen}
        id="ProjectSettingsButton"
        data-testid="project-settings-button"
      >
        <GearIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPopover-paper": styles.popoverPaper,
        }}
      >
        <Typography variant="subtitle1" sx={styles.title}>
          Project settings
        </Typography>

        <Settings handleClose={handleClose} />
      </Popover>
    </div>
  );
}

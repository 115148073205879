import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import Circle from "@mui/icons-material/FiberManualRecord";
import { pieChartColorMappings } from "./index";

const styles = {
  list: {
    flexGrow: 1,
  },
  li: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    maxWidth: {
      xs: "250px",
      md: "100%",
    },
  },
  liValue: {
    textAlign: "right",
    color: "#76758D",
  },
};

export default function PropertyList({ data }) {
  return (
    <List sx={styles.list}>
      {data.map(({ name, value }) => {
        const color = pieChartColorMappings[name.toLowerCase()];
        const percent = value * 100;
        return (
          <ListItem sx={styles.li} key={name}>
            <ListItemIcon sx={{minWidth: 35}}>
              <Circle style={{ color }} />
            </ListItemIcon>
            <ListItemText sx={styles.liName}>{name}</ListItemText>
            <ListItemText sx={styles.liValue}>
              {percent.toFixed(1)}%
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}

import React, { Suspense } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { DRAWER_CLOSED_WIDTH, ROUTE_PATHES } from "utils/constants";
import NavigationDrawer from "./NavigationDrawer";
import Summary from "./Overview/Summary";
import TopBar from "./TopBar";
import Preloader from "components/UI/Preloader";
import useTabs from "hooks/useTabs";
import useTitle from "hooks/useTitle";
import { Helmet } from "react-helmet";
import ProjectNotFound from "./ProjectNotFound";
import Drawers from "./Shared/Drawers/Drawers";
import useAppData from "api/hooks/useAppData";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { hasNarrativeFeed } from "./NarrativeFeed/utils";

// Lazy loading
const Overview = React.lazy(() => import("./Overview"));
const EntityExplorer = React.lazy(() => import("./EntityExplorer"));
const Visualize = React.lazy(() => import("./Visualize"));
const NarrativeBuilder = React.lazy(() => import("./NarrativeBuilder"));
const ReportGenerator = React.lazy(() => import("./ReportGenerator"));
const NarrativeFeed = React.lazy(() => import("./NarrativeFeed"));

const Root = styled(Box)({
  padding: 0,
  minHeight: "100vh",
});

const Main = styled("main")({
  width: "100%",
  flexGrow: 1,
  paddingTop: "60px",
  paddingLeft: DRAWER_CLOSED_WIDTH + "px",
});

const SummaryWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  borderRadius: "6px",
  position: "sticky",
  top: "60px",
  zIndex: theme.zIndex.drawer - 1,
}));

export default function DashboardPage() {
  // loads required data for dashboard page
  useAppData();

  const title = useTitle();

  // Routing
  const { currentPath } = useTabs();
  const { path, url } = useRouteMatch();
  const { projectName } = useParams();

  const defaultRedirectUrl = `${url.replace(/\/$/, "")}/${
    hasNarrativeFeed() ? ROUTE_PATHES.narrativeFeed : ROUTE_PATHES.overview
  }`;

  const showSummary =
    currentPath === ROUTE_PATHES.overview ||
    currentPath === ROUTE_PATHES.analyze;

  const showOnlyProjects = currentPath === ROUTE_PATHES.narrativeFeed;

  return (
    <Root>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <NavigationDrawer />

      <Main id="main_content">
        <TopBar onlyProjects={showOnlyProjects} />

        {showSummary && (
          <SummaryWrapper>
            <Summary />
          </SummaryWrapper>
        )}

        <Suspense fallback={<Preloader />}>
          <Switch>
            {hasNarrativeFeed() && (
              <Route path={[`${path}/narrativeFeed`]}>
                <NarrativeFeed />
              </Route>
            )}
            <Route path={[`${path}/overview`]}>
              <Overview />
            </Route>
            <Route path={[`${path}/visualize`]}>
              <Visualize />
            </Route>
            <Route path={[`${path}/analyze`]}>
              <EntityExplorer />
            </Route>
            <Route path={[`${path}/report`]}>
              <ReportGenerator />
            </Route>
            <Route path={[`${path}/discover`, `${path}/refine`]}>
              <NarrativeBuilder />
            </Route>
            <Route path={`${path}/not-found`}>
              <ProjectNotFound />
            </Route>
            <Route
              exact
              path={`${path}/`}
              render={() => {
                if (projectName) {
                  return <Redirect to={defaultRedirectUrl} />;
                }
                return null;
              }}
            />
          </Switch>
        </Suspense>
      </Main>

      <Drawers />
    </Root>
  );
}

import { monitorIdentity } from "api/monitoring/monitoring";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import { LOCAL_STORAGE_KEYS, NARRATIVE_FEED_KEY } from "utils/constants";

export default function useAppLogin() {
  const { dispatch } = useAppContext();
  const ldClient = useLDClient();

  const set = (user) => {
    if (!user) {
      return;
    }

    // set user
    dispatch({
      type: ACTIONS.SET_USER,
      payload: {
        ...user,
      },
    });

    // launch darkley
    ldClient.identify({
      kind: "user",
      key: user.username,
      name: user.username,
      email: user.email,
    });

    // monitoring
    monitorIdentity({
      username: user.username,
      email: user.email,
      id: user.id,
      organization: user.organization.name,
      orgId: user.organization.id,
    });

    // timezone
    const settings = user.settings;
    if (settings && settings.timezone) {
      const timezone = {
        Offset: settings.timezone.Offset,
        Name: settings.timezone.Name,
        Description: settings.timezone.Description,
      };

      dispatch({
        type: ACTIONS.SET_TIMEZONE,
        payload: timezone,
      });
    }

    // localstorage stuff
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER,
      JSON.stringify({
        username: user.username,
        id: user.id,
        email: user.email,
        orgId: user.organization?.id,
        orgName: user.organization?.name,
      })
    );

    localStorage.setItem(
      NARRATIVE_FEED_KEY,
      !!user.organization?.has_narrative_feed
    );
  };

  const clear = () => {
    localStorage.clear();

    dispatch({
      type: ACTIONS.SET_USER,
      payload: {},
    });
  };

  return {
    set: useCallback(set, [dispatch, ldClient]),
    clear: useCallback(clear, [dispatch]),
  };
}

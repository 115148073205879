import { Divider, Menu, MenuItem, Typography, Box, useTheme } from "@mui/material";
import { ReactComponent as DashboardIcon } from "icons/UI/Dashboard.svg";
import { PROJECT_ACTIONS } from "./utils/constants";
import {
  CONNECTORS,
  DATA_CONNECTORS_LOOKUP,
  getConnectors,
} from "./utils/dataConnectors";
import RestoreIcon from "@mui/icons-material/Restore";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = ({theme}) => ({
  popoverPaper: {
    background: theme.palette.background.$2,
    border: "1px solid " + theme.palette.background.$3,
    minWidth: "240px",
  },
  body: {
    padding: theme.spacing(1),
  },
  footer: {
    padding: theme.spacing(1.5),
    borderTop: "1px solid " + theme.palette.background.$3,
    width: "100%",
    "& .MuiButton-text": {
      color: "#6F7494",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  addBtn: {
    padding: theme.spacing(1),
  },
  iconWrap: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#6F7494",
  },
  itemLabel: {
    padding: "8px 16px",
    color: "#6F7494",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "15px",
  },
  menuItem: {
    color: "#AFAFAF",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "18px",
    "&:hover": {
      background: theme.palette.background.$3,
    },
  },
});

export default function TableActions({
  onAction,
  data,
  anchorEl,
  setAnchorEl,
}) {
  const flags = useFlags();

  const s3ConnectorConfig = DATA_CONNECTORS_LOOKUP.get(CONNECTORS.s3);
  const S3Icon = s3ConnectorConfig?.icon || null;

  const showProjectDashboardBtn = data && data.dataAvailable;
  const availableConnectors = getConnectors(flags);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const styles = useStyles({ theme });

  const handleImport = (target) => {
    onAction(PROJECT_ACTIONS.import, target);
    handleClose();
  };

  const handleS3Export = (target) => {
    onAction(PROJECT_ACTIONS.export, target);
    handleClose();
  };

  const handleConfigureAutoUpdates = () => {
    onAction(PROJECT_ACTIONS.configure_auto_updates);
    handleClose();
  };

  const handleDashboardClick = () => {
    onAction(PROJECT_ACTIONS.view_project);
    handleClose();
  };

  return (
    <Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          "& .MuiMenu-paper": styles.popoverPaper
        }}
        transformOrigin={{ vertical: -15, horizontal: "right" }}
      >
        {showProjectDashboardBtn && (
          <MenuItem
            disableRipple
            sx={styles.menuItem}
            onClick={handleDashboardClick}
            id="ViewProjectButton"
          >
            <Box sx={styles.iconWrap}>
              <DashboardIcon />
            </Box>
            Project Dashboard
          </MenuItem>
        )}

        {showProjectDashboardBtn && <Divider sx={{ my: 0.5 }} />}

        <Typography sx={styles.itemLabel}>ADD DATA</Typography>

        {availableConnectors.map((conf, i) => {
          const Icon = conf.icon || null;
          return (
            <MenuItem
              key={i}
              onClick={() => handleImport(conf)}
              disableRipple
              sx={styles.menuItem}
              id={conf.id + "Btn"}
            >
              <Box sx={styles.iconWrap}>
                <Icon />
              </Box>
              {conf.contextMenuActionLabel || conf.actionLabel}
            </MenuItem>
          );
        })}

        {data && !data?.autoUpdatesDisabled && flags.autoUpdates && (
          <MenuItem
            disableRipple
            sx={styles.menuItem}
            onClick={handleConfigureAutoUpdates}
          >
            <Box sx={styles.iconWrap}>
              <RestoreIcon fontSize="small" />
            </Box>{" "}
            {data?.jobSchedule ? "Edit" : "Configure"} Auto Updates
          </MenuItem>
        )}

        {flags.s3Output && s3ConnectorConfig && (
          <Box>
            <Divider sx={{ my: 0.5 }} />
            <Typography sx={styles.itemLabel}>EXPORT DATA</Typography>

            <MenuItem
              disableRipple
              sx={styles.menuItem}
              onClick={() => handleS3Export(s3ConnectorConfig)}
              id={s3ConnectorConfig.id + "ExportBtn"}
              data-testid={s3ConnectorConfig.id + "ExportBtn"}
            >
              <Box sx={styles.iconWrap}>
                <S3Icon />
              </Box>
              {s3ConnectorConfig.contextMenuExportLabel}
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
}

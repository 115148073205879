import { Alert, Box, Typography } from "@mui/material";
import Preloader from "./UI/Preloader";

export default function WidgetBody({ children, error, loading }) {
  if (error) {
    return <Alert severity="error">An error occured.</Alert>;
  }

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          minHeight: "64px",
          padding: "10px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Preloader />
      </Box>
    );
  }

  if (!children) {
    return <Typography sx={{ p: 1 }}>No results</Typography>;
  }

  return children;
}

import { Box, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import Hint from "components/UI/Hint";
import { PROJECT_ACTIONS } from "../utils/constants";

const tooltips = {
  [PROJECT_ACTIONS.import]:
    "The S3 bucket source folder is where your unprocessed CSV files will be uploaded",
  [PROJECT_ACTIONS.export]:
    "The S3 bucket destination folder is where your processed CSV files will be uploaded",
};

export default function S3Path({
  options,
  value,
  onChange,
  error,
  disabled,
  loading,
  loadingText,
  operationMode = PROJECT_ACTIONS.import,
}) {
  const tooltip = tooltips[operationMode] || "";
  const target =
    operationMode === PROJECT_ACTIONS.import ? "source" : "destination";

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mb: "8px",
        }}
      >
        <Typography
          sx={{
            color: "#AFAFAF",
            fontSize: "14px",
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            lineHeight: "19px",
          }}
        >
          Choose S3 bucket {target} folder
          {tooltip && <Hint tooltipText={tooltip} />}
        </Typography>
      </Box>

      <CustomDropdown
        id={"s3PathSelectDropdown_${target}"}
        title={`S3 bucket ${target} folder`}
        loading={loading}
        placeholder={"- Select -"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        options={options}
        searchEnabled={options.length > 5}
        error={error}
        disabled={disabled}
        loadingText={loadingText}
      />
    </Box>
  );
}

import { Tooltip } from "@mui/material";

const TooltipContent = (props) => {
  const Comp = props.contentComp;
  return <div>{Comp ? <Comp {...props} /> : props.text}</div>;
};

export default function CustomTooltip(props) {
  if (props.disabled) {
    return props.children;
  }

	return (
		<Tooltip 
			arrow
			title={<TooltipContent {...props} />} 
			placement={props.placement || "top"}
			PopperProps={{style:{zIndex:9999999}}}
			{...props.tooltipProps || {}}
		>
			{props.children}
		</Tooltip>
	);
}

import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = ({ theme }) => ({
  errorText: {
    color: "rgba(255,255,255,0.6)",
    fontSize: "12px",
  },
  errorContainer: {
    padding: theme.spacing(0, 4, 4, 4),
    marginLeft: "15px",
    marginBottom: theme.spacing(2),
  },
  errorTitle: {
    fontSize: "28px",
    lineHeight: "32px",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
});

export default function ProjectNotFound() {
  const theme = useTheme();

  const styles = useStyles({ theme });

  return (
    <Box sx={styles.errorContainer}>
      <Typography variant={"h3"} gutterBottom sx={styles.errorTitle}>
        Project not found
      </Typography>

      <Typography sx={styles.errorText}>
        The project you are looking for may have been moved renamed or deleted.
      </Typography>
    </Box>
  );
}

import { Button, Chip, Divider, IconButton, Typography, Box, useTheme } from "@mui/material";
import { deleteHarmProfile, setHarmProfile } from "api/endpoints/AnalyticsApi";
import CustomDropdown from "components/UI/CustomDropdown";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { removeHarmFromFilters } from "context/reducerUtilities";
import { DEFAULT_PROFILE } from "utils/constants";
import Hint from "components/UI/Hint";
import useEventTracker from "api/hooks/useEventTracker";
import { useParams } from "react-router";
import { ReactComponent as EditIcon } from "icons/UI/Edit.svg";
import { ReactComponent as DeleteIcon } from "icons/UI/Delete.svg";
import useMessage from "hooks/useMessage";

const useStyles = ({theme}) => ({
  row: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  label: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "22px",
    transform: "translate(0, 0) scale(1)",
  },
  spaceTop: {
    marginTop: theme.spacing(1),
  },
  muiItem: {
    fontSize: "13px",
    color: "#E7E7EF",
  },
  profileSelect: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  profileListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "60%",
    marginRight: "10px",
    borderRadius: "4px",
    background: "#302E4F",
  },
  manage: {
    padding: "10px 0px",
  },
  profileName: {
    flexGrow: 1,
    color: "#afafaf",
    fontSize: "14px",
  },
  btns: {},
  chipRoot: {
    height: "24px",
    fontSize: "12px",
    fontWeight: "500",
  },
  chipRootCustom: {
    background: "#0D341B",
    border: "1px solid #218746",
  },
  chipRootDefault: {
    border: "1px solid #6757d4",
    background:
      "linear-gradient(250.87deg, rgba(88, 88, 213, 0.3) -2.92%, rgba(113, 88, 213, 0.3) 106.15%)",
  },
});

export default function HarmTab() {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const tracker = useEventTracker();
  const showMessage = useMessage();
  
  const {
    dispatch,
    state: { harmProfiles, harmProfile, narrative, project },
  } = useAppContext();
  const narrativeIndex = parseInt(useParams().narrativeId) || 0;

  const resetHarm = () => {
    const analyze_filters = removeHarmFromFilters(narrative.analyze_filters);
    dispatch({
      type: ACTIONS.UPDATE_NARRATIVE,
      payload: {
        narrative: {
          ...narrative,
          analyze_filters,
        },
        index: narrativeIndex,
      },
    });
  };

  const handleProfileChange = async (e) => {
    const p = harmProfiles.find((d) => d.id === e.target.value);
    if (p && p.id !== harmProfile.id) {
      const setHarmProfileRes = await setHarmProfile(p.isDefault ? 0 : p.id);
      if (setHarmProfileRes) {
        dispatch({
          type: ACTIONS.SET_USER,
          payload: {
            ...setHarmProfileRes,
          },
        });
        dispatch({
          type: ACTIONS.SET_HARM_PROFILE,
          payload: p,
        });
        tracker.track(
          "Applied risk profile",
          "select",
          p.name,
          "Custom Risk Profile"
        );
        resetHarm();
      }
    }
  };

  const handleSetToDefault = async () => {
    if (harmProfile.id) {
      const setHarmProfileRes = await setHarmProfile(0);
      if (setHarmProfileRes) {
        tracker.track(
          "Applied risk profile",
          "select",
          "Blackbird default",
          "Custom Risk Profile"
        );
        dispatch({
          type: ACTIONS.SET_USER,
          payload: {
            ...setHarmProfileRes,
          },
        });
        dispatch({
          type: ACTIONS.SET_HARM_PROFILE,
          payload: DEFAULT_PROFILE,
        });
        resetHarm();
      }
    }
  };

  const handleCreate = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_PROFILE,
      payload: {},
    });
    dispatch({
      type: ACTIONS.SET_HARM_DRAWER_OPEN,
      payload: true,
    });
    // hidePopup();
  };

  // const handleCopy = (d) => {
  //   const copy = {
  //     id: getRandomId(),
  //     name: "copy of " + d.name,
  //     includeQuery: [...(d.includeQuery || [])],
  //     excludeQuery: [...(d.excludeQuery || [])],
  //     isDefault: false,
  //   };
  //   dispatch({
  //     type: ACTIONS.ADD_HARM_PROFILE,
  //     payload: copy
  //   });
  // };

  const handleDelete = async (d) => {
    if (d.id === harmProfile.id || d.id === globalProfile.id) {
      // profile in use
      showMessage("This profile is in use or it is a global profile", "error");
    } else {
      try {
        const deleteRes = await deleteHarmProfile(d.id);

        if (deleteRes) {
          dispatch({
            type: ACTIONS.REMOVE_HARM_PROFILE,
            payload: d,
          });
          showMessage("Profile deleted", "success");
          tracker.track(
            "Deleted risk profile",
            "remove",
            d.name,
            "Custom Risk Profile"
          );
        }
      } catch {
        showMessage("Error deleting Risk Profile", "error");
      }
    }
  };

  const handleEdit = (d) => {
    tracker.track(
      "Clicked edit risk profile",
      "click",
      d.name,
      "Custom Risk Profile"
    );

    dispatch({
      type: ACTIONS.EDIT_HARM_PROFILE,
      payload: d,
    });
  };

  const globalProfile = project.harmProfile || DEFAULT_PROFILE;

  return (
    <Box>
      {globalProfile && (
        <Box sx={styles.row}>
          <Box sx={styles.label}>Global Risk Profile</Box>
          <Box sx={styles.profileSelect}>
            <Chip
              sx={{
                ...styles.chipRoot,
                ...(globalProfile.isDefault ? styles.chipRootDefault : styles.chipRootCustom)
              }}
              label={globalProfile.name}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      )}

      <Divider light />

      {harmProfile && (
        <>
          <Divider light />
          <Box sx={styles.row}>
            <Box sx={styles.label}>Preview Risk Profile</Box>
            <Box sx={styles.profileSelect}>
              <Box sx={styles.dropdown}>
                <CustomDropdown
                  id="RiskProfileDropdown"
                  transparent
                  value={harmProfile.id}
                  onChange={handleProfileChange}
                  options={harmProfiles.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </Box>
              <Button
                data-testid="reset-risk-profile-button"
                disabled={harmProfile.isDefault}
                variant="outlined"
                color="primary"
                onClick={() => handleSetToDefault()}
                id="ResetButton"
              >
                Reset to default
              </Button>
              <Box sx={{ ml: 1 }}>
                <Hint
                  tooltipText={
                    "The Blackbird Default risk profile is an aggregation of multiple risk signals including anomalous, bot-like, toxicity, and negative sentiment."
                  }
                />
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Divider light />

      <Box sx={styles.row}>
        <Box sx={styles.label}>Manage Risk Profiles</Box>
        <Box sx={styles.manage}>
          {harmProfiles
            .filter((d) => !d.isDefault)
            .map((d, i) => (
              <Box sx={styles.profileListItem} key={i}>
                <Typography variant="caption" sx={styles.profileName}>
                  {d.name}
                </Typography>
                <Box sx={styles.btns}>
                  {/* <IconButton onClick={() => handleCopy(d)}>
                  <CopyIcon />
                </IconButton> */}
                  <IconButton
                    onClick={() => handleEdit(d)}
                    id="EditRiskProfileButton"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(d)}
                    id="DeleteRiskProfileButton"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
        </Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCreate}
          id="CreateRiskProfileButton"
          data-testid="create-risk-profile-button"
        >
          Create Risk Profile
        </Button>
      </Box>
    </Box>
  );
}

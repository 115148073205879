import { Box, Button } from "@mui/material";
import ConnectionConfig from "./ConnectionConfig";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addDataConnector } from "api/endpoints/UploadApi";
import useEventTracker from "api/hooks/useEventTracker";
import { useState } from "react";
import { useAppContext } from "context/Context";

export const states = {
  loading: "loading",
  complete: "complete",
  error: "error",
};

export default function CreateNewConnector({
  showBackButton,
  onChangeView,
  connectorConfig,
  buttonText = "Back to connections",
  onComplete,
  disabled,
}) {
  const tracker = useEventTracker();

  const {
    state: { user },
  } = useAppContext();

  const [state, setState] = useState(null);
  const [response, setResponse] = useState(null);

  const handleCreate = async (formData) => {
    try {
      setState(states.loading);
      setResponse(null);

      const dataProviderSpecificConfiguration = { ...formData };

      delete dataProviderSpecificConfiguration.api_key;
      delete dataProviderSpecificConfiguration.name;

      const payload = {
        data: {
          name: formData.name || "",
          apiKey: formData.api_key || "",
          dataProviderSpecificConfiguration,
          dataProvider: connectorConfig?.id,
          owner: user.id,
          organization: { id: user?.organization?.id },
          statusToggle: true,
        },
      };

      const resp = await addDataConnector(payload);

      onComplete(resp?.data);
      setState(states.complete);

      tracker.track(
        `Connected ${connectorConfig?.name} API`,
        "connect",
        connectorConfig?.name,
        `Data Connectors / Connect`
      );
    } catch (err) {
      setState(states.error);

      const errorData = err?.response?.data;
      const errorMessage = errorData?.error_msg;

      const response = {
        ...(errorData?.dataConnectorStatus || {}),
        error_msg: errorMessage || err.message
      }

      setResponse(response)

      tracker.track(
        `API connect failed`,
        "connect",
        {
          "Data source": connectorConfig?.displayName,
        },
        `Data Connectors / Connect`
      );
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mb: "8px",
        }}
      >
        {showBackButton && (
          <Button
            data-testid="create-new-connector-back-button"
            type="text"
            size="small"
            sx={{ fontWeight: "normal", whiteSpace: "nowrap" }}
            startIcon={<ArrowBackIcon />}
            onClick={onChangeView}
          >
            {buttonText}
          </Button>
        )}
      </Box>

      <ConnectionConfig
        disabled={disabled}
        fields={connectorConfig.connectionFields}
        buttonState={state}
        response={response}
        onSubmit={handleCreate}
      />
    </Box>
  );
}

import { Button, Typography, Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ACCEPTED_FILES, ERROR_CODE_MAP, ERROR_GENERIC, MAX_FILE_COUNT, MAX_FILE_SIZE } from "../utils/constants";
import UploadProgress from "./UploadProgress";
import { v4 as uuidv4 } from 'uuid';
import { getFileNameAndExtension } from "../utils/uploadUtils";

const useStyles = () => ({
  root: {},
  dropBox: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: "#6F7494",
    borderSpacing: "2px 8px",
    borderRadius: "4px",
    width: "100%",
    height: "100px",
  },
  dragInactiveParent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dropHere: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#AFAFAF",
  },
});

const Uploader = ({
  onFilesAccepted,
  onFilesRejected,
  uploading,
  processing,
  uploadProgress,
}) => {
  const styles = useStyles();

  const onDropAccepted = async (acceptedFiles) => {
    // Map/Object of the original filenames so we show the original file name during uploading
    let originalFileNamesMap = {}
    
    if (acceptedFiles && acceptedFiles.length > 0) {
      // Renames the files with a unique id so the user can't overwrite their existing files
      const renamedAcceptedFiles = acceptedFiles.map((file, index) => {
        const {name, extension} = getFileNameAndExtension(file);
        const newFileName = `${name}_${uuidv4()}.${extension}`;
        originalFileNamesMap[newFileName] = `${name}.${extension}`;
        return new File([file], newFileName, { type: file.type });
      });
      onFilesAccepted(renamedAcceptedFiles, originalFileNamesMap);
    }
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      const rejectData = rejectedFiles.map((item) => {
        const errorCode = item['errors'][0]['code'];
        return {
          name: item.file.name,
          errorCode: errorCode,
          errorMessage: ERROR_CODE_MAP[errorCode] ? ERROR_CODE_MAP[errorCode] : ERROR_GENERIC,
        }
      })
      onFilesRejected(rejectData);
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: ACCEPTED_FILES,
    maxFiles: MAX_FILE_COUNT,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    onDropAccepted,
    onDropRejected,
    noClick: () => {},
  });

  return (
    <Box sx={styles.root}>
      <Box {...getRootProps()} sx={styles.dropBox}>
        <input data-testid="upload-file-input" {...getInputProps()} />

        {uploading || processing ? (
          <UploadProgress
            uploadProgress={uploadProgress}
            processing={processing}
          />
        ) : isDragActive ? (
          <Box sx={styles.dropHere}>
            <Typography variant="body1" sx={styles.uploadText}>
              Drop here
            </Typography>
          </Box>
        ) : (
          <Box sx={styles.dragInactiveParent}>
            <Typography variant="subtitle2" sx={styles.uploadText} data-testid="upload-text">
              Drag files here or select file(s) to upload, or
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={open}
                id="InputFileButton"
                data-testid="input-file-button"
              >
                Browse Files
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Uploader;

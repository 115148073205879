import { getHarmText } from "components/UI/HarmDot";
import { rollups, ascending } from "d3-array";

export const descSort = (x, y) => {
  if (!x.sort_bot && y.sort_bot) return -1;
  if (x.sort_bot && !y.sort_bot) return 1;
  return y.value - x.value;
};

export const secondarySort = (data, sortField, firstColumnField) => {
  if (sortField === "is_harmful" || sortField === "harm") {
    return rollups(
      data.slice(),
      (arr) => {
        const sorted = arr.sort((a, b) => {
          return ascending(a[firstColumnField], b[firstColumnField]);
        });
        return sorted;
      },
      (d) => {
				if (sortField === "harm") {
					return !!d[sortField]
				}
				return getHarmText(d[sortField])
			}
    ).flatMap((d) => d[1]);
  }
  return data;
};

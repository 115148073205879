import { PLATFORM_CONFIG } from "./constants";
import { getPercent } from "./format";

// Merge the summary data for multiple platforms using reduce
export const mergeSummaries = (summary, platforms) => {
    return platforms.reduce((mergedSummary, platform) => {
        if (summary[platform]) {
            Object.keys(summary[platform]).forEach((key) => {
                if (!mergedSummary[key]) {
                    mergedSummary[key] = 0;
                }
                mergedSummary[key] += summary[platform][key] || 0;
            });
        }
        return mergedSummary;
    }, {});
}

// Get the summary data for a single platform or merged summary data for multiple platforms
export const getSummaryDataWithPlatform = (summary, platform) => {
    if (platform && summary) {
        if (Array.isArray(platform)) {
            if (platform.length === 1) {
                // If the array has only one platform, return the summary for that platform
                const firstPlatform = platform[0];
                return summary[firstPlatform] || {};
            } else if (platform.length > 1) {
                // If the array has multiple platforms, return the merged summary data
                return mergeSummaries(summary, platform);
            }
        } else {
            // If platform is a single platform, return the summary data
            return summary[platform] || {};
        }
    }
    return {};
}

/**
 * Get the breakdown values for a field_name for all platforms
 * @param {string[]} selectedPlatforms - The platforms to get the breakdown values for
 * @param {object} summary - The summary data
 * @param {string} field_name - The field name to get the breakdown values for
 * @param {string[]} platformBreakdownExclusion - The platforms to exclude from the breakdown
 * @returns {object} - The breakdown values for the field_name for each platform
 */
export const getBreakdownValues = (selectedPlatforms, summary, field_name, platformBreakdownExclusion = []) => {
    
    // If selectedPlatforms is not an array, convert it to an array
    if(!Array.isArray(selectedPlatforms)) selectedPlatforms = [selectedPlatforms];

    const breakdownValues = {};

    // Get the breakdown values for the field_name for each platform
    selectedPlatforms.forEach((platform) => {
        const value = summary?.[platform]?.[field_name] || 0;
        // Exclude the specified platforms from the breakdown
        if(!platformBreakdownExclusion.includes(platform)){
            breakdownValues[platform] = value
        }
    });
    return breakdownValues;
}

// Get breakdown values for platforms and filter out the "All" platform
export const prepareBreakdownValuesWithPercent = (selectedPlatforms, breakdownValues, platformBreakdownExclusion = []) => {
    if(!selectedPlatforms || !breakdownValues) return [];

    // Filter out the platforms specified in platformBreakdownExclusion
    selectedPlatforms = selectedPlatforms.filter((d) => !platformBreakdownExclusion.includes(d));
    
    // Check that seletedPlatforms is an array
    if(!Array.isArray(selectedPlatforms)) return [];

    // Get total for all platforms on that field_name
    let total = 0;
    selectedPlatforms.filter((d) => d !== PLATFORM_CONFIG.all).forEach((platform) => {
      total += breakdownValues[platform] || 0;
    });
    return selectedPlatforms.map((platform) => {
      return { 
        platform:PLATFORM_CONFIG[platform].name,
        value: breakdownValues[platform],
        percent: breakdownValues[platform] ? getPercent(breakdownValues[platform], total, 1) : null
      };
    }).filter((d) => d.platform !== "All");
}

export const aggregateBreakdownValues = (platforms, breakdownValues) => {
    // If platforms is not an array, convert it to an array
    if(!Array.isArray(platforms)) platforms = [platforms];

    var total = 0;
    platforms.forEach((platform) => {
        total += breakdownValues[platform] || 0;
    });
    return total;
}

// Merge multiple summary objects for each platform taking the highest value and return the merged summary
export const mergeSummaryObjects = (summary1, summary2) => {
    const highestSummary = {};

    // Get all unique platforms from both summaries
    const platforms = new Set([...Object.keys(summary1), ...Object.keys(summary2)]);

    platforms.forEach((platform) => {
        highestSummary[platform] = {};

        // Get all unique keys for the current platform
        const keys = new Set([
            ...Object.keys(summary1[platform] || {}),
            ...Object.keys(summary2[platform] || {})
        ]);

        keys.forEach((key) => {
            const value1 = summary1[platform]?.[key] || 0;
            const value2 = summary2[platform]?.[key] || 0;
            highestSummary[platform][key] = Math.max(value1, value2);
        });
    });

    return highestSummary;
}
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { resetPassword } from "api/endpoints/AnalyticsApi";
import { useQuery } from "utils/useQuery";

import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";

function PasswordResetPage() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toLogin, setToLogin] = useState(false);

  const query = useQuery();
  const confirmationCode = query.get("code");

  const onSubmit = async (event) => {
    event.preventDefault();
    setMessage(null);
    setLoading(true);
    setToLogin(false);
    try {
      await resetPassword(confirmationCode, password, passwordConfirmation);
      setMessage({
        type: "success",
        message: "Your password has been changed.",
      });
      setLoading(false);
      setTimeout(() => {
        setToLogin(true);
      }, 1500);
    } catch (error) {
      setMessage({
        type: "error",
        message:
          "Error resetting password: make sure the passwords match, are at least 6 characters long, and the code is valid.",
      });
      setToLogin(false);
      setLoading(false);
      console.error(error);
      localStorage.clear();
    }
  };

  if (!confirmationCode) return <Redirect to="/login" />;
  if (message && message.type === "success" && toLogin)
    return <Redirect to="/login" />;

  return (
    <LoginBackground>
      <Typography variant="h4">Hi!</Typography>

      <Typography variant="subtitle2" color={"text.muted"} sx={{ my: 2 }}>
        Enter your new password
      </Typography>

      {message && <Message {...message} />}

      <form autoComplete="true" onSubmit={onSubmit}>
        <Box sx={{ my: 2 }}>
          <Typography
            variant="label1"
            component="div"
            sx={{ mb: 1, color: "text.light" }}
            data-testid="password-reset-label"
          >
            Password
          </Typography>
          <InputValidate
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fieldProps={{
              type: "password",
              "data-testid": "password-reset-input"
            }}
          />
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography
            variant="label1"
            component="div"
            sx={{ mb: 1, color: "text.light" }}
            data-testid="password-reset-repeat-label"
          >
            Repeat Password
          </Typography>
          <InputValidate
            value={passwordConfirmation}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
            fieldProps={{
              type: "password",
              disabled: loading,
              "data-testid": "password-reset-repeat-input"
            }}
          />
        </Box>

        <Button
          type={"submit"}
          size={"large"}
          variant={"contained"}
          color={"primary"}
          fullWidth
          disabled={loading}
          data-testid="submit-button"
        >
          {loading ? (
            <CircularProgress color="secondary" size={24} thickness={3} />
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </LoginBackground>
  );
}

export default PasswordResetPage;

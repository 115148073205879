import HarmDrawer from "./HarmDrawer";
import UsersGroupDrawer from "./UsersGroupDrawer";
import HarmClassifierDrawer from "./HarmClassifierDrawer";
import { useAppContext } from "context/Context";
import StatsDrawer from "./StatsDrawer";

export default function Drawers() {
  const {
    state: {
      harmDrawerOpen,
      userGroupDrawerOpen,
      harmClassifierOpen,
      statsDrawerData,
    },
  } = useAppContext();

  return (
    <>
      {harmClassifierOpen && <HarmClassifierDrawer />}
      {harmDrawerOpen && <HarmDrawer />}
      {userGroupDrawerOpen && <UsersGroupDrawer />}
      {statsDrawerData && <StatsDrawer />}
    </>
  );
}

import React from "react";
import { Box, Button, Typography } from "@mui/material";

const styles = {
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& *:not(.progress_bar)": {
      zIndex: 2,
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "65%",
    px: 2,
    py: 1.5,
  },
  right: {
    width: "35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    padding: 4,
    pl: 2,
  },
  progressBar: {
    zIndex: 0,
    position: "absolute",
    height: "100%",
    backgroundColor: "#2E265F",
    borderRadius: "4px",
  },
  cancelBtn: {
    color: "#ffffff",
    borderColor: "#ffffff",
    opacity: 0.9,
    width: "100%",
  },
  progress: {
    fontWeight: 400,
    fontSize: "34px",
    lineHeight: "42.67px",
  },
  uploading: {
    fontWeight: 700,
    fontSize: "14px",
  },
  timeLeft: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.02em",
  },
  filename: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    marginBottom: "5px",
  },
};

export default function UploadProgress({
  uploadProgress,
  cancelUpload,
  processing,
}) {
  const { percent } = uploadProgress || {
    percent: 0,
    bytes: 0,
    timeLeft: 100,
    filename: "",
  };
  return (
    <Box sx={styles.root}>
      <Box
        className="progress_bar"
        sx={{ ...styles.progressBar, width: percent + "%" }}
      ></Box>

      <Box sx={styles.left}>
        <Box>
          <Typography sx={styles.uploading}>
            {percent < 100 ? (
              <>
                <span>Uploading </span>
                <Box
                  component="span"
                  sx={styles.filename}
                >{`${uploadProgress.filename} ...`}</Box>
              </>
            ) : processing ? (
              "Processing"
            ) : (
              "Finished"
            )}
          </Typography>
          <Typography sx={styles.uploading}>
            {percent < 100
              ? "UPLOADING..."
              : processing
              ? "PROCESSING..."
              : "FINISHED"}
          </Typography>
          {!processing && (
            <Typography sx={styles.progress}>{percent}%</Typography>
          )}
        </Box>
      </Box>

      {cancelUpload && (
        <Box sx={styles.right}>
          <Button
            variant="outlined"
            sx={styles.cancelBtn}
            onClick={cancelUpload}
            data-testid="upload-progress-cancel-upload-btn"
          >
            {percent < 100 ? "CANCEL UPLOAD" : "DONE"}
          </Button>
        </Box>
      )}
    </Box>
  );
}

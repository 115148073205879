import { Box, Button, Divider, Typography } from "@mui/material";
import ChildSummary from "./childSummary";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import Hint from "components/UI/Hint";

// Stats Overview Details component that displays the summary data for platforms
export default function Details({
  name,
  info,
  tooltipText,
  list,
  items,
  hiddenItems,
  viewMoreId,
  title,
  secondaryTitle,
  loading,
  showDivider,
}) {
  const { dispatch } = useAppContext();

  const viewMoreClick = () => {
    dispatch({
      type: ACTIONS.SET_STATS_DRAWER_OPEN,
      payload: {
        open: true,
        items,
        info,
        title,
        secondaryTitle,
        viewMoreId,
        entityType: "stats"
      },
    });
  };

  if (!list || !list.length) {
    return null;
  }

  return (
    <Box>
      {showDivider && list?.length > 0 && (
        <Box sx={{ py: 0.75, px: 2 }} className="detail_row">
          <Divider />
        </Box>
      )}

      {name && !loading && (
        <Box sx={{ display: "flex", alignItems: "center", py: 0.75, px: 2 }}>
          <Typography
            variant={"body2"}
            noWrap
            className="detail_row_title"
            sx={{
              color: "text.muted",
              fontSize: "12px",
              letterSpacing: "1.2px",
              textTransform: "uppercase",
              fontWeight: "400",
            }}
          >
            {name}
          </Typography>

          {tooltipText && <Hint tooltipText={tooltipText} dataTestId={name} />}
        </Box>
      )}

      {(list || []).map((li, i) => {
        return <ChildSummary {...li} key={i} loading={loading} />;
      })}

      {!!hiddenItems && !loading && (
        <Box sx={{ textAlign: "center" }}>
          <Button
            size="small"
            onClick={viewMoreClick}
            variant="text"
            sx={{ fontWeight: "normal" }}
            id={viewMoreId + "_MoreButton"}
          >
            + {hiddenItems} More
          </Button>
        </Box>
      )}
    </Box>
  );
}

import { Box, TextField, Typography } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import CustomDropdown from "components/UI/CustomDropdown";
import { useMemo } from "react";
import { SCHEDULE_OPTIONS } from "../utils/constants";

const styles = {
  label: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "15px",
    color: "#AFAFAF",
    marginBottom: "10px",
  },
  controls: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    borderBottom: "1px solid #302E4F",
    paddingBottom: "24px",
  },
  iconWrap: {
    color: "#6F7494",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "40px"
  },
  dropdowns: {
    width: "calc(100% - 30px)",
    display: "flex",
    alignItems: "flex-start",
  },
  dropdownWrap: {
    flexGrow: 1,
    padding: "0px 5px",
    "&:last-child": {
      paddingRight: "0px",
    },
  },
  timeInput: {
    "& .MuiInputBase-root": {
      background: "background.$1",
      borderColor: "border.main",
      borderWidth: "1px",
      borderStyle: "solid",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      height: "41.8px",
      borderRadius: "2px",
    },
    "& fieldset": {
      borderRadius: 0,
      borderColor: "transparent !important",
    },
    "& input::-webkit-calendar-picker-indicator": {
      color: "#6F7494",
    },
  },
};

const AutoUpdates = ({
  hideTitle,
  disabled,
  config = {},
  setConfig,
  error = {}
}) => {
  const selectedOption = useMemo(() => {
    return SCHEDULE_OPTIONS.find((x) => x.value === config.frequency);
  }, [config.frequency]);

  const handleConfigChange = (field, value) => {
    const _config = { ...config };
    _config[field] = value;
    setConfig(_config);
  };

  return (
    <Box>
      {!hideTitle && (
        <Typography sx={styles.label}>Auto update schedule</Typography>
      )}

      <Box sx={styles.controls}>
        <Box sx={styles.iconWrap}>
          <RestoreIcon />
        </Box>
        <Box sx={styles.dropdowns}>
          <Box sx={styles.dropdownWrap}>
            <CustomDropdown
              disabled={disabled}
              options={SCHEDULE_OPTIONS}
              value={config.frequency || ""}
              onChange={(e) => handleConfigChange("frequency", e.target.value)}
              placeholder={"- Frequency -"}
              error={!!error.frequency}
            />
          </Box>

          {selectedOption?.options ? (
            <Box sx={styles.dropdownWrap}>
              <CustomDropdown
                disabled={disabled}
                options={selectedOption?.options}
                value={config.day || ""}
                onChange={(e) => handleConfigChange("day", e.target.value)}
                placeholder={"- Day -"}
                error={!!error.day}
              />
            </Box>
          ) : null}

          {(selectedOption?.timePickers || []).map((field, i) => (
            <Box sx={styles.dropdownWrap} key={i}>
              <TextField
                disabled={disabled}
                sx={styles.timeInput}
                className="time_input"
                type={"time"}
                value={config[field] || ""}
                onChange={(e) => handleConfigChange(field, e.target.value)}
                size="small"
                error={!!error[field]}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AutoUpdates;

import {
  Button,
  ClickAwayListener,
  IconButton,
  Typography,
  Box
} from "@mui/material";
import SimpleTooltip from "components/UI/SimpleTooltip";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import useEventTracker from "api/hooks/useEventTracker";

const CancelJob = ({ onConfirm }) => {
  const tracker = useEventTracker();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    tracker.track(
      "Clicked cancel job",
      "click",
      "",
      "Data connectors / Job cancel"
    );
  };

  const handleYes = () => {
    tracker.track(
      "Canceled job",
      "click",
      "",
      "Data connectors / Job cancel"
    );
    handleTooltipClose()
    onConfirm();
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <SimpleTooltip
          PopperProps={{
            disablePortal: true,
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
          arrow={false}
          placement="left"
          background={"#272438"}
          maxwidth={"365px"}
          contentComp={
            <Box
              sx={{ display: "flex", alignItems: "center", minWidth: "320px" }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "normal",
                }}
              >
                Are you sure you want to cancel?
              </Typography>
              <Button
                data-testid="cancel-job-no-button"
                color="darkGrey"
                variant="text"
                size="small"
                onClick={handleTooltipClose}
                sx={{ ml: 1, mr: 1 }}
              >
                No
              </Button>
              <Button data-testid="cancel-job-yes-button" variant="contained" size="small" onClick={handleYes}>
                Yes
              </Button>
            </Box>
          }
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton
            onClick={handleTooltipOpen}
            variant="outlined"
            color="error"
            size="small"
            sx={{ whiteSpace: "nowrap", fontWeight: "normal" }}
          >
            <CancelIcon sx={{ width: "20px", height: "20px" }} />
          </IconButton>
        </SimpleTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CancelJob;

import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { DEFAULT_NARRATIVE } from "utils/constants";

export default function useProjectChange() {
  const {
    dispatch,
    state: { projects, project },
  } = useAppContext();

  const change = (projectName) => {
    const p = projects.find((x) => x.name === projectName);

    if (p.name === project.name) {
      return;
    }

    // clear platform
    dispatch({
      type: ACTIONS.SET_PLATFORM,
      payload: undefined,
    });

    // clear platforms list
    dispatch({
      type: ACTIONS.SET_PLATFORMS,
      payload: [],
    });

    // clear narratives list
    dispatch({
      type: ACTIONS.SET_LOADED_NARRATIVES,
      payload: [DEFAULT_NARRATIVE],
    });

    // reset narrative
    dispatch({
      type: ACTIONS.SELECT_NARRATIVE,
      payload: 0,
    });

    dispatch({
      type: ACTIONS.SELECT_PROJECT,
      payload: {},
    });

    dispatch({
      type: ACTIONS.SET_SPIKE_DATE,
      payload: null,
    });
  };

  return { change };
}

import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as ClockIcon } from "icons/Uploader/clock.svg";
import { useState } from "react";

const useStyles = () => ({
  root: {
    display: "flex",
    height: "calc(100% - 84px)",
    flexFlow: "column",
    justifyContent: "space-between",
  },
  icon: {
    textAlign: "center",
  },
  title: {
    color: "white",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    padding: "20px 0px",
  },
  subtitle: {
    color: "#AFAFAF",
    fontSize: "15px",
    lineHeight: "19px",
    textAlign: "center",
  },
  body: {
    overflowY: "auto",
    padding: "0px 35px"
  },
  footer: {
    padding: "24px",
    borderTop: "1px solid #302E4F",
    display: "flex",
    justifyContent: "space-between"
  },
});

function SendToQueue({ onCancel, onContinue }) {
  const styles = useStyles();

  const [sent, setSent] = useState(false);

  const handleSend = () => {
    setSent(true);
    onContinue();
  }

  return (
    <Box sx={styles.root}>
      <Box></Box>

      <Box sx={styles.body}>
        <Box sx={styles.icon}>
          <ClockIcon />
        </Box>
        <Typography sx={styles.title}>Send to Queue</Typography>
        <Typography sx={styles.subtitle}>
          Your documents will take 60 seconds to start processing, and you can
          cancel the operation during this time.
          <br></br>
          <br></br>
          After that, it won&apos;t be possible to cancel!
        </Typography>
      </Box>

      <Box sx={styles.footer}>
        <Button
          variant="contained"
          sx={{ minWidth: "125px" }}
          onClick={handleSend}
          disabled={sent}
          data-testid="send-to-queue-button"
        >
          {sent ? "Sent" : "Send to Queue"}
        </Button>

        <Button data-testid="send-to-queue-cancel-button" onClick={onCancel} variant="outlined" color="error">
          Cancel
        </Button>
      </Box>
    </Box>
  );
}

export default SendToQueue;

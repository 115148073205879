import backgroundImage from "images/login-bg.jpg";
import { Typography, Grid, Box, useTheme } from "@mui/material";
import BlackbirdTextLogo from "components/blackbird-text-logo";

const useStyles = ({theme}) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#191826",
    backgroundPosition: "center bottom",
  },
  outerContainer: {
    padding: theme.spacing(4, 4, 4, 4),
    [theme.breakpoints.up("sm")]: {
      height: "80%",
      paddingBottom: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  logo: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  text: {
    color: "#E8E9F3",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  mainTitle: {
    fontSize: "32px",
    lineHeight: "40px",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: "38px",
      lineHeight: "42px",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "45px",
      lineHeight: "54px",
    },
  },
  login: {
    padding: theme.spacing(2, 4, 4, 4),
    backgroundColor: "rgba(34, 34, 55, 0.3)",
    border: "1px solid #292942",
    width: "100%",
    borderRadius: "12px",
    backdropFilter: "blur(10px)",
    maxWidth: "410px",
  },
  credential: {
    marginBottom: theme.spacing(1),
  },
  forgotPassword: {
    marginTop: "1rem",
  },
  header: {
    marginTop: 0,
  },
  left: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      marginBottom: 0,
    },
  },
  right: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blackbirdLogoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  texts: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
  },
});

function LoginBackground({
  children,
  title = "Constellation Dashboard",
  subtitle,
}) {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <Box sx={styles.root}>
      <Grid
        sx={styles.outerContainer}
        container
        direction="row"
        justifyContent="space-around"
        spacing={0}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={5}
          sx={styles.left}
          direction="column"
          alignItems="flex-start"
        >
          <Box sx={styles.blackbirdLogoContainer}>
            <BlackbirdTextLogo login={true} />
          </Box>

          <Box sx={styles.texts}>
            <Typography
              variant={"h3"}
              gutterBottom
              sx={styles.mainTitle}
            >
              {title}
            </Typography>

            <Typography sx={styles.text}>
              {subtitle ||
                `The Constellation Risk Intelligence Dashboard empowers teams to
              proactively detect and contextualize information driven threats to
              accelerate response and mitigation.`}
            </Typography>
          </Box>

          <Box></Box>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          xs={12}
          sm={12}
          md={5}
          sx={styles.right}
        >
          {children && <Grid sx={styles.login}>{children}</Grid>}
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoginBackground;

import PropertyList from "./PropertyList";
import PieChart from "components/UI/PieChart";
import { descSort } from "utils/sort";
import { Box } from "@mui/material";

export default function DataDisplay({ data, setData, tab, shouldReanimate }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
					xs: "row",
					md: "column"
				},
      }}
    >
      <PieChart
        name={tab}
        data={data}
        setData={setData}
        shouldReanimate={shouldReanimate}
        showLabels={false}
        height={300}
        useTooltip={true}
      />
      <PropertyList data={data.sort(descSort)} />
    </Box>
  );
}

import { Box, Button, Chip, Divider, IconButton, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import { ReactComponent as EditIcon } from "icons/UI/Edit.svg";
// import { ReactComponent as DeleteIcon } from "icons/UI/Delete.svg";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { DEFAULT_CLASSIFIER } from "utils/constants";
import {
  // deleteClassifierProfile,
  setClassifierProfileAPI,
} from "api/endpoints/AnalyticsApi";

const styles = {
  row: {
    my: 1.5
  },
  label: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "22px",
    transform: "translate(0, 0) scale(1)",
  },
  spaceTop: {
    mt: 1,
  },
  muiItem: {
    fontSize: "13px",
    color: "#E7E7EF",
  },
  profileSelect: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  profileListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "60%",
    marginRight: "10px",
    borderRadius: "4px",
    background: "#302E4F",
  },
  manage: {
    padding: "10px 0px",
  },
  profileName: {
    flexGrow: 1,
    color: "#afafaf",
    fontSize: "14px",
  },
  btns: {},
  chipRoot: {
    height: "24px",
    fontSize: "12px",
    fontWeight: "500",
  },
  chipRootCustom: {
    background: "#0D341B",
    border: "1px solid #218746",
  },
  chipRootDefault: {
    border: "1px solid #6757d4",
    background:
      "linear-gradient(250.87deg, rgba(88, 88, 213, 0.3) -2.92%, rgba(113, 88, 213, 0.3) 106.15%)",
  },
};

export default function ClassifierTab() {
  const {
    dispatch,
    state: { harmClassifiers, harmClassifier, project },
  } = useAppContext();

  const setClassifierProfile = async (newProfile) => {
    const updateRes = await setClassifierProfileAPI(
      newProfile.id ? newProfile.id : null
    );

    if (updateRes) {
      dispatch({
        type: ACTIONS.SET_HARM_CLASSIFIER,
        payload: newProfile,
      });
    }
  };

  const handleProfileChange = async (e) => {
    const p = harmClassifiers.find((d) => d.id === e.target.value);
    if (p && p.id !== harmClassifier.id) {
      await setClassifierProfile(p);
    }
  };

  const handleSetToDefault = async () => {
    await setClassifierProfile(DEFAULT_CLASSIFIER);
  };

  const handleCreate = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_CLASSIFIER,
      payload: {},
    });
    dispatch({
      type: ACTIONS.SET_HARM_CLASSIFIER_OPEN,
      payload: true,
    });
    // hidePopup();
  };

  // const handleDelete = async (d) => {
  //   const deleteRes = await deleteClassifierProfile(d.id);
  //   if (deleteRes) {
  //     dispatch({
  //       type: ACTIONS.REMOVE_HARM_CLASSIFIER,
  //       payload: d,
  //     });
  //   }
  // };

  const handleEdit = (d) => {
    dispatch({
      type: ACTIONS.EDIT_HARM_CLASSIFIER,
      payload: d,
    });
  };

  const globalProfile = project.classifierProfile || DEFAULT_CLASSIFIER;
  const chipStyle = globalProfile.isDefault
    ? styles.chipRootDefault
    : styles.chipRootCustom;

  return (
    <Box>
      {globalProfile && (
        <Box sx={styles.row}>
          <Box sx={styles.label}>Global Classifier Profile</Box>
          <Box sx={styles.profileSelect}>
            <Chip
              sx={{...styles.chipRoot, ...chipStyle}}
              label={globalProfile.name}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      )}

      <Divider light />

      {harmClassifier && (
        <>
          <Divider light />
          <Box sx={styles.row}>
            <Box sx={styles.label}>Preview Classifier Profile</Box>
            <Box sx={styles.profileSelect}>
              <Box sx={styles.dropdown}>
                <CustomDropdown
                  transparent
                  value={harmClassifier.id}
                  onChange={handleProfileChange}
                  options={harmClassifiers.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </Box>
              <Button
                data-testid="classifier-reset-to-default-button"
                disabled={harmClassifier.isDefault}
                variant="outlined"
                color="primary"
                onClick={() => handleSetToDefault()}
              >
                Reset to default
              </Button>
            </Box>
          </Box>
        </>
      )}

      <Divider light />

      <Box sx={styles.row}>
        <Box sx={styles.label}>Manage Classifier Profiles</Box>
        <Box sx={styles.manage}>
          {harmClassifiers
            .filter((d) => !d.isDefault)
            .map((d, i) => (
              <Box sx={styles.profileListItem} key={i}>
                <Typography variant="caption" sx={styles.profileName}>
                  {d.name}
                </Typography>
                <Box sx={styles.btns}>
                  <IconButton onClick={() => handleEdit(d)}>
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton color="error" onClick={() => handleDelete(d)}>
                    <DeleteIcon />
                  </IconButton> */}
                </Box>
              </Box>
            ))}
        </Box>
        <Button data-testid="classifier-create-button" variant="outlined" color="primary" onClick={handleCreate}>
          Create Classifier Profile
        </Button>
      </Box>
    </Box>
  );
}

import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import JobDataReceipt from "./JobDataReceipt";
import GeneralInfo from "./GeneralInfo";
import JobStatus from "../JobStatus";
import styled from "@emotion/styled";
import { ReactComponent as JobProcessingIcon } from "icons/Uploader/job-processing-icon.svg";
import useEventTracker from "api/hooks/useEventTracker";
import { LEARN_MORE_DATA_RECEIPT } from "utils/constants";

const Header = styled("div")({
  backgroundColor: "#1C192B",
  position: "sticky",
  top: 0,
  zIndex: 1,
  padding: "20px 32px 20px 20px",
  borderBottom: "1px solid #282C38",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const Footer = styled("div")({
  backgroundColor: "#1C192B",
  position: "sticky",
  bottom: "0px",
  right: "0px",
  width: "100%",
  padding: "24px",
  borderTop: "1px solid #282C38",
});

export default function JobDetailsDrawer({ open, handleClose, job }) {
  const tracker = useEventTracker();

  if (!job) {
    return null;
  }

  const handleLearnMoreClick = () => {
    window.open(
      `/helpdesklogin?target_url=${LEARN_MORE_DATA_RECEIPT}`,
      "_blank"
    );

    tracker.track(
      "Clicked learn more",
      "click",
      { Context: "Job details drawer" },
      "Data connectors / Job details"
    );
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      transitionDuration={300}
    >
      <Box sx={{ width: 500, overflowY: "auto", flexGrow: 1 }}>
        <Header>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={handleClose}
              sx={{
                padding: 0,
                width: "34px",
                height: "34px",
                marginRight: "8px",
              }}
            >
              <CloseIcon />
            </IconButton>

            <Typography>Job Details | #{job.id}</Typography>
          </Box>

          <Box>
            <JobStatus isChild={true} value={job.status} />
          </Box>
        </Header>

        <Box sx={{ px: 4 }}>
          <GeneralInfo job={job} />

          <Divider />

          <Box
            sx={{
              pt: 4,
              pb: 2,
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
              }}
            >
              <JobProcessingIcon />
              <Typography>Document processing details</Typography>
            </Box>

            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                data-testid="job-details-drawer-learn-more-button"
                size="small"
                variant="text"
                sx={{
                  p: 0.5,
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
                onClick={handleLearnMoreClick}
              >
                Learn more
              </Button>
              <Typography color={"text.light"} variant="body2">
                about how we process documents
              </Typography>
            </Box>
          </Box>

          <JobDataReceipt job={job} />
        </Box>
      </Box>

      <Footer>
        <Button data-testid="job-details-drawer-close-button" onClick={handleClose} variant="outlined" color="primary">
          Close
        </Button>
      </Footer>
    </Drawer>
  );
}

import { FormControlLabel, Switch } from "@mui/material";

export default function S3ExportToggle({ checked, onChange }) {
  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          name="amazonS3"
        />
      }
      sx={{
        pl: 1,
        fontSize: "14px",
        color: "text.light",
        fontWeight: "normal",
      }}
      label="Export project to Amazon S3"
    />
  );
}

import { Box, FormControl, InputLabel } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const styles = {
  row: {
    marginTop: 1.5,
    marginBottom: 1.5,
  },
  label: {
    color: "#AFAFAF",
    fontSize: "15px",
    fontWeight: "400",
  },
};

export default function APIKeysTab({ hidePopup }) {
  const [openAIAPIKey, setOpenAIAPIKey] = useState("");

  const handleChange = () => {
    localStorage.setItem("openAIAPIKey", openAIAPIKey);
    hidePopup();
  };

  return (
    <Box sx={styles.row}>
      <InputLabel id="openai-key-label" sx={styles.label} shrink={true}>
        Set OpenAI API Key
      </InputLabel>

      <FormControl size="small" fullWidth sx={{ mt: 1 }}>
        <TextField
          data-testid="openai-key-input"
          autoFocus
          placeholder="Key"
          variant="outlined"
          size="small"
          value={openAIAPIKey}
          onChange={(e) => {
            setOpenAIAPIKey(e.target.value);
          }}
          type="password"
        />
        <Button
          data-testid="openai-key-save-button"
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: 2 }}
          onClick={handleChange}
        >
          Save api key
        </Button>
      </FormControl>
    </Box>
  );
}

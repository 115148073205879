import { useLocation } from "react-router-dom";
import { ROUTES } from "utils/constants";

const useTabs = () => {
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop();
  const fullPath = location.pathname;

  const currentTab = ROUTES.find(d => {
    if (d.children) {
      return d.path === currentPath ||
             d.children.some(x => x.path === currentPath) ||
             d.children.some(x => fullPath.includes(x.path))
    }
    return d.path === currentPath
  })

  return {
    currentPath: currentTab?.path,
    childPath: currentPath
  }
};

export default useTabs;

import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TimeZoneTab from "./TimeZoneTab";
import APIKeysTab from "./APIKeysTab";
import { useFlags } from "launchdarkly-react-client-sdk";

const WIDTH = "380px";

const styles = {
  root: {},
  btn: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  drawerPaper: {
    width: WIDTH,
    background: "background.$1",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "border.main",
  },
  header: {
    fontSize: "16px",
    fontWeight: "700",
    color: "white",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 20px 20px 15px",
  },
  backButton: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "16px",
  },
  content: {
    py: 2,
    px: 4,
  },
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
  divider: {
    borderColor: "rgba(99, 96, 123, 0.2)",
  },
};

export default function UserAccountDrawer({ open, handleClose }) {
  const flags = useFlags();

  return (
    <Box sx={styles.root}>
      <Drawer
        sx={{
          ...styles.drawer,
          "& .MuiDrawer-paper": styles.drawerPaper,
        }}
        anchor={"right"}
        open={open}
        onClose={handleClose}
      >
        <Box sx={styles.headerTitle}>
          <IconButton sx={styles.backButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography sx={styles.header}>Manage your profile</Typography>
        </Box>

        <Divider sx={styles.divider} />

        <Box sx={styles.content}>
          <TimeZoneTab />

          {flags.reportGeneration && <APIKeysTab hidePopup={handleClose} />}
        </Box>
      </Drawer>
    </Box>
  );
}

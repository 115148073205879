import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ReactComponent as ArrowIcon } from "icons/UI/arrowdown.svg";
import { ReactComponent as EditIcon } from "icons/UI/Edit.svg";
import { ReactComponent as DeleteIcon } from "icons/UI/Delete.svg";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  ListSubheader,
  Checkbox,
  FormHelperText,
  Box,
  useTheme,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import Hint from "./Hint";
import Preloader from "./Preloader";
import { isEqual } from "lodash";
import SearchInput from "components/UI/SearchInput";

const useStyles = ({ theme, ...props }) => {
  return {
    formControl: {
      width: "100%",
      marginTop: 0,
      borderRadius: "4px",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      "& > .MuiInputBase-root": {
        borderRadius: "4px",
        background: () => {
          if (props.transparent) {
            return "transparent";
          }
          return theme.palette.background.$1;
        },
        border: () => {
          if (props.transparent) {
            return null;
          }
          if (props.invalid) {
            return `1px solid ${theme.palette.error.main} !important`;
          }
          if (props.open) {
            return `1px solid ${theme.palette.indigo.$7} !important`;
          }
          return theme.palette.border.widget;
        },
        boxShadow: () => {
          if (props.transparent) {
            return null;
          }
          if (props.open) {
            return "0px 0px 0px 4px rgba(146, 129, 233, 0.20)";
          }
          return null;
        },
      },
      "& .MuiSelect-select": {
        padding: props.compact ? `0px 12px 0px 0px` : `8px 24px 8px 10px`,
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      "& .progress_circle": {
        minHeight: "18px",
      },
    },
    paperRoot: {
      borderRadius: "4px",
      background: theme.palette.background.$1,
      border: "1px solid #302E4F",
      boxShadow: "0px 4px 8px -3px #000000",
      marginTop: "4px",
      "& .MuiList-root": {
        padding: 0,
      },
      maxHeight: "400px",
    },
    selectedText: {
      background: "transparent",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      borderBottom: props.underline ? "1px dashed #FFFFFF !important" : null,
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "24px",
      color: "#fff",
    },
    placeholderTxt: {
      color: "#AFAFAF !important",
    },
    selected: {
      color: "#fff !important",
      backgroundColor: "transparent !important",
      borderLeft: props.multiple
        ? null
        : `3px solid ${theme.palette.primary.main} !important`,
      "& span:not(.MuiCheckbox-root)": {
        color: "#fff !important",
      },
    },
    defaultOption: {
      backgroundColor: "transparent !important",
    },
    iconBtn: {
      color: "rgba(231, 231, 239, 0.6)",
    },
    iconDelete: {
      color: "rgba(242, 133, 133, 0.6)",
    },
    root: {
      maxWidth: "600px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      height: "32px",
      padding: theme.spacing("5px", 1.5),
      color: "rgba(193, 191, 215, 0.99)",
      borderLeft: "3px solid transparent",
      paddingRight: "5px",
      "&:hover": {
        color: "#fff",
        "& span:not(.MuiCheckbox-root)": {
          color: "#fff !important",
        },
      },
      "& .MuiCheckbox-root": {
        paddingLeft: 0,
      },
      "& .MuiCheckbox-root:not(.Mui-checked)": {
        color: theme.palette.darkGrey.main,
      },
    },
    listItemText: {
      paddingRight: 1,
      "& .MuiTypography-root": {
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#AFAFAF",
        "&:hover": {
          color: "rgba(231, 231, 239, 1)",
        },
      },
    },
    subheader: {
      fontSize: "12px",
      lineHeight: "40px",
      fontWeight: 400,
      padding: theme.spacing(0, 1.5),
      borderLeft: "3px solid transparent",
      color: "#ffffff",
      textTransform: "uppercase",
      backgroundColor: theme.palette.background.$1,
    },
    footer: {
      borderTop: `1px solid ${theme.palette.background.dropdownActive}`,
      background: theme.palette.background.$1,
      padding: "4px",
      marginTop: "8px",
      position: "sticky",
      bottom: 0,
      zIndex: 2,
    },
    dropdownIcon: {
      width: "7px",
      height: "4px",
      position: "absolute",
      top: "45%",
      right: "10px",
      display: "inline-block",
      pointerEvents: "none",
    },
    inputItem: {
      position: "sticky",
      top: 0,
      zIndex: 2,
      width: "100%",
      lineHeight: "initial",
      padding: theme.spacing(1.5),
      backgroundColor: theme.palette.background.$1,
    },
    popupTitle: {
      color: "#6F7494",
      textTransform: "uppercase",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      lineHeight: "initial",
      padding: theme.spacing(1.5, 2, 1, 2),
      "& p": {
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: "normal",
      },
    },
  };
};

const CustomDropdown = ({
  options,
  value,
  onChange,
  underline,
  footerText,
  onFooterClick,
  onEdit,
  onDelete,
  disabled,
  placeholder,
  itemIcon,
  searchEnabled,
  title,
  hint,
  transparent,
  multiple = false,
  error,
  helperText = "This field is required",
  loading = false,
  loadingText = "Loading...",
  dataTestId = "selected-option",
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [internalValue, setInternalValue] = useState(value ?? "");

  const invalid =
    error && (!internalValue || (internalValue || []).length === 0);

  const theme = useTheme();
  const styles = useStyles({
    theme,
    transparent,
    underline,
    compact: !!rest.compact,
    multiple,
    invalid,
    open,
  });

  useEffect(() => {
    setVisibleOptions(options.flatMap((d) => [d.value, d.groupName]));
  }, [options]);

  useEffect(() => {
    setInternalValue(value ?? "");
  }, [value]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    // Set default to empty string to prevent uncontrolled state 
    setInternalValue(e.target.value ?? "");
    if (!multiple) {
      onChange(e);
    }
  };

  const handleApply = () => {
    handleClose();
    onChange({ target: { value: internalValue } });
  };

  const handleOpen = () => {
    setOpen(true);
    setSearchTerm("");
    setVisibleOptions(options.flatMap((d) => [d.value, d.groupName]));
    setInternalValue(value ?? "");
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEdit = (e, option) => {
    onEdit(option);
    stopImmediatePropagation(e);
    setOpen(false);
  };

  const handleDelete = (e, option) => {
    stopImmediatePropagation(e);
    onDelete(option);
  };

  const handleFooterClick = () => {
    setOpen(false);
    onFooterClick();
  };

  const renderSelected = (selected) => {
    if (loading) {
      return (
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "normal",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          component="div"
        >
          <Box>
            <Preloader size={18} thickness={7} />
          </Box>
          {loadingText}
        </Typography>
      );
    }

    if (multiple) {
      const selectedOptions = options
        .filter((d) => value.includes(d.value))
        .map((d) => d.label);

      let label = selectedOptions.join(", ");
      let isPlaceholder = false;

      if (!value.length && placeholder) {
        label = placeholder;
        isPlaceholder = true;
      }

      return (
        <Box
          sx={{
            ...styles.selectedText,
            ...(isPlaceholder ? styles.placeholderTxt : {}),
          }}
        >
          {label}
        </Box>
      );
    }

    let o = null;
    let isPlaceholder = false;

    if (selected === "" && placeholder) {
      o = { label: placeholder };
      isPlaceholder = true;
    } else {
      o = options.find((d) => d.value === selected);
    }

    let label = typeof selected !== "object" ? selected : "";

    if (o) {
      label = o.selectedLabel || o.label;
    }

    return (
      <Box
        sx={{
          ...styles.selectedText,
          ...(isPlaceholder ? styles.placeholderTxt : {}),
        }}
      >
        {label}
      </Box>
    );
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearchTerm(e.target.value);
    const val = e.target.value.toLowerCase();
    const filtered = options
      .filter((d) => {
        return (
          d.label.toLowerCase().includes(val) ||
          (d.groupName || "").toLowerCase().includes(val)
        );
      })
      .flatMap((d) => [d.value, d.groupName]);
    setVisibleOptions(filtered);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  const isValueSame = isEqual(value, internalValue);

  return (
    <FormControl variant="standard" sx={styles.formControl} id={rest.id}>
      <Select
        displayEmpty={!!placeholder}
        MenuProps={{
          style: { zIndex: 999999 },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: {
            "& .MuiMenu-paper": styles.paperRoot,
            "& .MuiMenu-select": styles.select,
            "& .Mui-selected": styles.selected,
          },
          MenuListProps: {
            tabIndex: "",
          },
        }}
        data-testid={dataTestId}
        IconComponent={() => (
          <ArrowIcon fontSize="small" style={styles.dropdownIcon} />
        )}
        renderValue={renderSelected}
        value={internalValue}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        disableUnderline
        disabled={!!disabled}
        variant="standard"
        multiple={multiple}
      >
        {title && (
          <ListSubheader sx={styles.popupTitle}>
            <Typography>{title}</Typography>
            {hint && <Hint tooltipText={hint} dataTestId={title} />}
          </ListSubheader>
        )}
        {searchEnabled && (
          <ListSubheader sx={styles.inputItem}>
            <SearchInput
              dataTestId={dataTestId + "-search-input"}
              value={searchTerm}
              onKeyDown={handleKeyDown}
              onChange={handleSearch}
            />
          </ListSubheader>
        )}
        {options.flatMap((option, i) => {
          return [
            option.showGroup && visibleOptions.includes(option.groupName) && (
              <ListSubheader
                key={"group_" + option.value}
                sx={styles.subheader}
              >
                {option.groupName}
              </ListSubheader>
            ),
            <MenuItem
              key={option.value + String(i)}
              value={option.value}
              sx={{
                ...styles.root,
                display:
                  searchEnabled && !visibleOptions.includes(option.value)
                    ? "none !important"
                    : null,
                borderBottom: option.highlighted ? "1px solid #282C38" : null,
              }}
              disabled={!!option.disabled}
            >
              {itemIcon && <ListItemIcon>{itemIcon}</ListItemIcon>}
              {multiple && (
                <Checkbox
                  data-testid={`${option.value}-checkbox`}
                  size="small"
                  color="primary"
                  disableRipple
                  checked={internalValue.indexOf(option.value) > -1}
                />
              )}
              <ListItemText
                data-testid={`${option.value}_Select`}
                sx={styles.listItemText}
              >
                {option.label}
              </ListItemText>

              {onEdit && option.editable && (
                <IconButton
                  size="small"
                  sx={styles.iconBtn}
                  data-testid={rest.id ? `${rest.id}_EditBtn_${i}` : null}
                  onClick={(e) => handleEdit(e, option.value)}
                >
                  <EditIcon />
                </IconButton>
              )}
              {onDelete && option.deletable && (
                <IconButton
                  sx={styles.iconDelete}
                  data-testid={rest.id ? `${rest.id}_DeleteBtn_${i}` : null}
                  onClick={(e) => handleDelete(e, option.value)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </MenuItem>,
          ];
        })}

        {footerText && onFooterClick && (
          <Box
            sx={{ ...styles.footer, display: "flex", justifyContent: "center" }}
          >
            <Button
              data-testid="custom-dropdown-footer-button"
              sx={{ fontWeight: 600, fontSize: "12px" }}
              startIcon={<AddCircle />}
              onClick={handleFooterClick}
            >
              {footerText}
            </Button>
          </Box>
        )}

        {multiple && !footerText && (
          <Box sx={styles.footer}>
            <Button
              data-testid="custom-dropdown-apply-button"
              color={isValueSame ? "darkGrey" : "primary"}
              sx={{ fontWeight: 600, fontSize: "12px" }}
              disabled={isValueSame}
              onClick={handleApply}
            >
              Apply
            </Button>
          </Box>
        )}
      </Select>
      {invalid && (
        <FormHelperText variant="outlined" error={invalid}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomDropdown;

import { refineDisplayName } from "./format";


export function resolveCohortDisplayName(cohort, modelLabels) {
  if (modelLabels) {
    const displayName = modelLabels[cohort];

    if (displayName) {
      return displayName;
    }
  }
  return refineDisplayName(formatCohortsElasticSearchField(cohort));
}

export function formatCohortsElasticSearchField(cohort) {
  return cohort ? cohort.replace("analysis.user-cohorts.", "").replace("analysis.cohorts.", "") : "";
}

import React, { useState, useCallback } from "react";
import { alpha, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DELETING } from "../../utils/constants";

const useStyles = ({theme}) => ({
  stagedText: {
    color: "#d32f2f",
  },
  disabledText: {
    color: "#77758E",
  },
  fill: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    padding: "0px 6px",
    alignItems: "center",
    minHeight: "45px",
  },
  spaceRight: {
    marginRight: theme.spacing(1),
    textAlign: "center",
    color: "#66ff00",
  },
  notUploaded: {
    minWidth: "150px",
  },
  noRows: {
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: "center",
    position: "absolute",
  },
  tableRoot: {
    border: "none",
    "& .MuiDataGrid-columnsContainer": {
      background: "#242234",
      fontSize: "13px",
      color: "#77758E",
      borderBottom: "none !important",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  cell: {
    borderBottom: "none !important",
    fontSize: "13px",
    color: "#ffffff",
    fontWeight: "normal",
    "& .theme--DELETED": {
      color: "black",
      "&:hover": {
        color: "black",
      },
    },
  },
  row: {
    background: theme.palette.background.$2,
    "&.Mui-selected": {
      background: "linear-gradient(90.31deg, #21243A 37.47%, #222934 81%)",
    },
  },
  table: {
    "& .MuiDataGrid-columnHeaders": {
      background: theme.palette.background.$1,
      border: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      fontWeight: "normal",
      color: "#6F7494",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "normal",
    },
    "& .theme--DELETED": {
      backgroundColor: alpha(
        theme.palette.action.disabled,
        theme.palette.action.selectedOpacity
      ),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.action.disabled,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
});

function DataGridTable({
  loading = false,
  columns,
  rows,
  selectedRows = [],
  onRowSelect,
  noDataText,
  pagination = true,
  checkboxDataTestId="data-grid-table-checkbox",
}) {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const [pageSize, setPageSize] = useState(10);

  const setThePageSize = useCallback(
    (newPageSize) => setPageSize(newPageSize),
    []
  );

  return (
    <DataGrid
      sx={{
        ...styles.table,
        "& .MuiDataGrid-root": styles.root,
        "& .MuiDataGrid-cell": styles.cell,
        "& .MuiDataGrid-row": styles.row,
      }}
      componentsProps={{
        baseCheckbox: {
          inputProps: {
            "data-testid": checkboxDataTestId,
          }
        }
      }}
      headerHeight={50}
      density={"compact"}
      autoHeight
      pagination={pagination}
      hideFooter={!pagination}
      pageSize={pagination ? pageSize : 100}
      rowsPerPageOptions={[5, 10, 15]}
      onPageSizeChange={setThePageSize}
      rows={rows}
      columns={columns}
      loading={loading}
      components={{
        NoRowsOverlay: () => (
          <Typography sx={styles.noRows} variant="body1">
            {noDataText || "No data"}
          </Typography>
        ),
      }}
      selectionModel={selectedRows.map((d) => d.id)}
      onSelectionModelChange={(model) => {
        if (onRowSelect) onRowSelect(model);
      }}
      checkboxSelection={!!onRowSelect}
      isRowSelectable={(params) => params.row.status !== DELETING}
      getRowClassName={(params) => {
        return `theme--${params.getValue(params.id, "status")}`;
      }}
    />
  );
}

export default DataGridTable;

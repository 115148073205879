import { isPlatform, sortPlatforms } from "utils/constants";
import { getPlatformLabel } from "utils/format";

const DATA_RECEIPT_MAPPING = [
  {
    label: "Estimated documents",
    field: "estimatedDocumentCount",
    sign: "positive",
    style: {
      show_sign: false,
      color: "text.light",
      gap: "bottom",
    },
  },

  {
    label: "Unsupported documents",
    field: "unsupported_documents",
    sign: "negative",
    style: {
      gap: "bottom",
      show_sign: true,
      color: "text.negative",
    },
  },

  {
    label: "Received Documents",
    field: "received_documents",
    sign: "positive",
    style: {
      show_sign: false,
      color: "text.light",
    },
  },

  {
    label: "Duplicates",
    field: "duplicated_documents",
    sign: "negative",
    style: { color: "text.negative", show_sign: true },
  },

  {
    label: "Incomplete",
    field: "incomplete_documents",
    sign: "negative",
    style: { show_sign: true, color: "text.negative" },
  },

  {
    label: "Unavailable",
    field: "unavailable_documents",
    sign: "negative",
    style: { show_sign: true, color: "text.negative" },
  },

  {
    field: "extracted_parent_documents",
    label: "Extended data",
    sign: "positive",
    style: {
      color: "text.positive",
      show_sign: true,
    },
  },

  {
    field: "processed_documents",
    label: (platform) => `${getPlatformLabel(platform)} total`,
    style: {
      color: "text.light",
      divider: true,
      show_sign: false,
    },
  },

  {
    field: "reprocessed_documents",
    label: "Updated documents",
    style: {
      color: "text.light",
      show_sign: false,
    },
  },

  {
    field: "new_documents",
    label: "New documents",
    getValue: (obj) => obj.processed_documents - obj.reprocessed_documents,
    style: {
      color: "text.light",
      show_sign: false,
    },
  },

  {
    field: "grand_total",
    label: "Total processed documents",
    style: {
      divider: true,
      color: "text.white",
      labelColor: "text.white",
      show_sign: false,
    },
  },
];

const mapping = new Map(
  DATA_RECEIPT_MAPPING.map((d, i) => [d.field, { ...d, order: i }])
);
export class DataReceipt {
  constructor(job) {
    this.job = job;
    this.processingSummary = {
      title: "Processing summary",
      defaultOpen: true,
      items: [],
    };
    this.incrementationSummary = {
      title: "Incrementation summary",
      defaultOpen: true,
      items: [],
    };
    this.processingBreakdown = {
      title: "Processing breakdown",
      defaultOpen: false,
      items: [],
    };
    this.incrementationBreakdown = {
      title: "Incrementation breakdown",
      defaultOpen: false,
      items: [],
    };

    if (!job?.exportResults?.error_code && job?.status === "Completed") {
      const processingFields = [
        "received_documents",
        "duplicated_documents",
        "incomplete_documents",
        "unavailable_documents",
        "extracted_parent_documents",
        "processed_documents",
      ];

      const incrementalFields = ["new_documents", "reprocessed_documents"];

      this.processingBreakdown.items = this.getBreakdown(processingFields);
      this.processingSummary.items = this.getSummary(
        this.processingBreakdown.items,
        processingFields
      );

      if (this.job.isIncremental) {
        this.incrementationBreakdown.items =
          this.getBreakdown(incrementalFields);
        this.incrementationSummary.items = this.getSummary(
          this.incrementationBreakdown.items,
          incrementalFields
        );
      }
    }
  }

  getCollapsibles() {
    if (this.job.isIncremental) {
      return [
        this.processingSummary,
        this.incrementationSummary,
        this.processingBreakdown,
        this.incrementationBreakdown,
      ];
    }

    return [
      this.processingSummary,
      this.processingBreakdown,
    ]
  }

  getBreakdown(fields) {
    const { exportResults } = this.job;

    const entries = Object.entries(exportResults || {}).sort(([a], [b]) => {
      if (isPlatform(a) && isPlatform(b)) {
        return sortPlatforms(a, b);
      }

      const indexA = mapping.get(a)?.order ?? 9999;
      const indexB = mapping.get(b)?.order ?? 9999;

      return indexA - indexB;
    });

    if (!entries.length) {
      return [];
    }

    const list = [];

    for (let [key, value] of entries) {
      // Platform mapping
      if (typeof value === "object") {
        const breakdownGroup = {
          platform: key,
          title: getPlatformLabel(key),
          items: [],
        };

        for (let field of fields) {
          const conf = mapping.get(field);
          const documentCount = conf.getValue
            ? conf.getValue(value)
            : value[field];

          if (!conf || !documentCount) continue;

          breakdownGroup.items.push({
            ...conf,
            value: documentCount,
            label: this.getLabel(conf, key),
          });
        }

        list.push(breakdownGroup);
      }
      // Unsupported
      else if (key === "unsupported") {
        const conf = mapping.get(key);

        if (conf && value) {
          list.unshift({
            ...conf,
            total: value,
            value: value,
            label: this.getLabel(conf, key),
          });
        }
      }
    }

    return list;
  }

  getSummary(breakdown, fields) {
    const list = [];

    const platforms = breakdown.filter((d) => d.items);

    for (let field of fields) {
      const sum = platforms.reduce((acc, curr) => {
        const item = curr.items.find((x) => x.field === field);
        acc += item?.value || 0;
        return acc;
      }, 0);

      if (sum) {
        const conf = mapping.get(
          field === "processed_documents" ? "grand_total" : field
        );

        list.push({
          ...conf,
          label: this.getLabel(conf, field),
          value: sum,
        });
      }
    }

    return list;
  }

  getEstimatedDocCount() {
    const conf = mapping.get("estimatedDocumentCount");
    if (!this.job[conf.field]) return null;
    return {
      ...conf,
      label: this.getLabel(conf),
      value: this.job[conf.field],
    };
  }

  getLabel(conf, key) {
    return typeof conf.label === "function" ? conf.label(key) : conf.label;
  }

  getMultiplier(conf) {
    return conf.sign === "negative" ? -1 : 1;
  }
}

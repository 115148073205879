import { Box, Button } from "@mui/material";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useCallback } from "react";
import { resolveCohortDisplayName } from "utils/cohorts";
import { EXCLUDED_COHORTS } from "utils/constants";
import { roundToOne } from "utils/format";

export default function ViewMoreCell({
  value,
  datum,
  type,
  entityType,
  title,
  secondaryTitle,
  limit,
}) {
  const {
    dispatch,
    state: { modelLabels },
  } = useAppContext();

  const getCohortsList = useCallback(
    (cohorts, datum) => {
      return cohorts
        .filter((d) => !EXCLUDED_COHORTS.includes(d))
        .map((cohort) => {
          const value = datum[cohort + "_ct"] || 0;
          const dividor = datum.post_ct;
          return {
            label: resolveCohortDisplayName(cohort, modelLabels),
            value: value,
            percent: dividor ? roundToOne((value / dividor) * 100) : null,
          };
        });
    },
    [modelLabels]
  );

  if (!value) {
    return null;
  }

  if (!Array.isArray(value)) {
    return value;
  }

  const isCohort = type === "userCohorts" || type === "topics";

  const viewMoreClick = () => {
    const allItems = isCohort
      ? getCohortsList(value, datum)
      : value.map((d) => {
          return {
            label: d,
            value: null,
            percent: null,
          };
        });

    dispatch({
      type: ACTIONS.SET_STATS_DRAWER_OPEN,
      payload: {
        rowId: datum.id,
        open: true,
        items: allItems,
        info: null,
        viewMoreId: type,
        hideBackdrop: true,
        secondaryTitle,
        title,
        entityType,
      },
    });
  };

  const items = value.slice(0, limit).map((d) => {
    if (isCohort) {
      return resolveCohortDisplayName(d, modelLabels);
    }
    return d;
  });
  const hiddenItems = Math.max(0, value.length - limit);

  const viewMore =
    value.length > limit ? (
      <Box>
        <Button
          data-testid="analyze-table-user-groups-button"
          size="small"
          onClick={viewMoreClick}
          variant="text"
          sx={{ fontWeight: "normal", py: 0.1 }}
        >
          + {hiddenItems} More
        </Button>
      </Box>
    ) : null;
  return (
    <Box sx={{ width: "100%", whiteSpace: "normal" }}>
      {items.join(", ")}
      {viewMore ? "..." : ""}
      {viewMore}
    </Box>
  );
}

import {
  initialState,
  STAGE_DOCS,
  SET_DOCS,
  SET_DATA_IMPORT_CONFIG,
  SET_SELECTED_FILES,
  SET_DATA_CONNECTOR,
  ADD_DOCS,
} from "../utils/constants";

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_DOCS: {
      return {
        ...state,
        documents: [...action.payload, ...state.documents],
      };
    }
    case SET_DOCS:
      return {
        ...state,
        documents: action.payload,
      };
    case STAGE_DOCS:
      return {
        ...state,
        stagedDocuments: action.payload,
      };
    case SET_DATA_IMPORT_CONFIG:
      return {
        ...state,
        dataImportConfig: action.payload,
      };
    case SET_SELECTED_FILES:
      return {
        ...state,
        selectedFilesForJob: action.payload,
      };
    case SET_DATA_CONNECTOR:
      return {
        ...state,
        dataConnector: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;

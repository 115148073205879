import { useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const useStyles = ({theme, ...props}) => ({
  arrow: {
    color: theme.palette.background.$3,
  },
  tooltip: {
    padding: "12px",
    backgroundColor: props.background || theme.palette.background.$3,
    color: "#ffffff",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    maxWidth: props.maxwidth,
    marginRight: "0px !important"
  },
  tooltipPlacementTop: {
    margin: "5px 0px",
  },
});

export default function SimpleTooltip({
  text,
  children,
  ...rest
}) {
  const theme = useTheme()
  const styles = useStyles({
    theme,
    maxwidth: rest.maxwidth || 200,
    background: rest.background,
  });

  return (
    <Tooltip
      arrow
      title={rest.contentComp || text}
      placement="top"
      sx={{
        "& .MuiTooltip-root": styles.root,
        "& .MuiTooltip-arrow": styles.arrow,
        "& .MuiTooltip-tooltipPlacementTop": styles.tooltipPlacementTop
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

import React from "react";
import { roundToTwo, formatNumber } from "utils/format";
import { ReactComponent as UpvotesIcon } from "icons/social-media/Upvotes.svg";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { pieChartColorMappings } from "../Overview/Insights";
import { DEFAULT_HARM_OPTIONS } from "pages/DashboardPage/Shared/RichTable/filterConfig";
import {
  ANALYZE_ENTITY_LIMITS,
  DEFAULT_PROFILE,
  PLATFORMS,
  PLATFORM_CONFIG,
  QUERY_KEYS,
  lookupPlatformConfigItem,
} from "utils/constants";
import array from "lodash/array";
import HarmDot from "components/UI/HarmDot";
import { METRIC_COLORS } from "colors";
import withFallbackValue from "utils/withFallbackValue";
import ViewMoreCell from "./RichTable/ViewMoreCell";
import { Box } from "@mui/material";
import { isPlatformAccepted } from "utils/platform";

const styles = {
  justFlex: {
    display: "flex",
    alignItems: "center",
  },
  statBubble: {
    background: "#302E4F",
    padding: "6px 8px",
    color: "#E8E9F3",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "12px",
    borderRadius: "16px",
    whiteSpace: "nowrap",
  },
  sent: { marginRight: "10px", height: "18px" },
};

const Circle = ({ color, onClick }) => {
  return (
    <Box
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }
          : null
      }
      sx={{
        backgroundColor: color,
        border: null,
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        margin: "0 auto",
      }}
    ></Box>
  );
};

export const ReasonCodesColumns = (showText, platform) => {
  const commonCols = [
    {
      text: "Anomalous",
      field: "is_manipulated",
      sort_field: "bbmi",
      togglable: true,
      cellComponent: ({ value, datum }) => {
        if (showText) {
          return roundToTwo(datum["bbmi"]);
        }

        if (value) {
          return <Circle color={METRIC_COLORS.anomalous} />;
        }

        return null;
      },
    },
    {
      text: "Toxic",
      field: "is_toxic",
      sort_field: "toxicity",
      togglable: true,
      cellComponent: ({ value, datum }) => {
        if (showText) {
          return roundToTwo(datum["toxicity"]);
        }

        if (value) {
          return <Circle color={METRIC_COLORS.toxic} />;
        }

        return null;
      },
    },
    // {
    // 	text: "Influential",
    // 	field: "is_influential",
    // 	sort_field: "pagerank",
    // 	togglable: true,
    // 	cellComponent: ({ value, datum }) => {
    // 		if (showText) {
    // 			return roundToTwo(datum["pagerank"]);
    // 		}

    // 		if (value) {
    // 			return <Circle color={METRIC_COLORS.influencial} />;
    // 		}

    // 		return null;
    // 	},
    // },
  ];

  const twitterCols = [
    {
      text: (
        <Box key="Bot-like" sx={{ whiteSpace: "nowrap" }}>
          Bot-like
        </Box>
      ),
      field: "is_bot",
      sort_field: "bot_score",
      togglable: true,
      cellComponent: ({ value, datum }) => {
        if (showText) {
          return roundToTwo(datum["bot_score"]);
        }

        if (value) {
          return <Circle color={METRIC_COLORS.bot_like} />;
        }

        return null;
      },
    },
  ];

  return [
    ...(isPlatformAccepted(platform, PLATFORMS.twitter) ? twitterCols : []),
    ...commonCols,
  ];
};

export const columnMappings = withFallbackValue(
  {
    [QUERY_KEYS.clusters]: (datum) => "Cluster ID: " + datum.id,
    [QUERY_KEYS.postsInCluster]: (datum) => "Post Id: " + datum.id,
    [QUERY_KEYS.users]: (datum) => datum.screen_name,
    [QUERY_KEYS.hashtags]: (datum) => "#" + datum.id,
    [QUERY_KEYS.urls]: (datum) => datum.id,
    [QUERY_KEYS.subreddits]: (datum) => datum.id,
    [QUERY_KEYS.domains]: (datum) => datum.id,
  },
  () => () => ""
);

export const useCohortColumns = ({
  filterKey,
  limits = ANALYZE_ENTITY_LIMITS,
  platform,
}) => {
  const cohorts = {
    minWidth: 180,
    text: "Cohorts",
    field: "user_cohorts",
    id: "user_cohorts",
    tooltip: `Cohorts are tagged based on a users affiliation with a group or ideology on ${platform}`,
    not_sortable: true,
    not_export: true,
    togglable: true,
    cellComponent: ({ value: cohorts, datum }) => {
      if (!Array.isArray(cohorts)) return null;
      return (
        <ViewMoreCell
          value={cohorts}
          datum={datum}
          type="userCohorts"
          title={"COHORTS | " + columnMappings[filterKey](datum)}
          secondaryTitle="Total cohorts"
          limit={limits.userCohorts}
          entityType={filterKey}
        />
      );
    },
  };

  return [
    ...(lookupPlatformConfigItem(platform, "has_user_cohorts")
      ? [cohorts]
      : []),
  ];
};

const getEmoSent = (value, showText, isSentiment) => {
  const {
    is_happiness,
    is_anger,
    is_disgust,
    is_sadness,
    is_surprise,
    is_fear,
    is_neutral,
  } = value;

  const labels = [
    ["Happy", is_happiness, pieChartColorMappings.happiness, "Positive"],
    ["Surprised", is_surprise, pieChartColorMappings.surprise, "Neutral"],
    ["Neutral", is_neutral, pieChartColorMappings.neutral, "Neutral"],
    ["Angry", is_anger, pieChartColorMappings.anger, "Negative"],
    ["Disgust", is_disgust, pieChartColorMappings.disgust, "Negative"],
    ["Sad", is_sadness, pieChartColorMappings.sadness, "Negative"],
    ["Fear", is_fear, pieChartColorMappings.fear, "Negative"],
  ];

  const emotions = labels.filter((d) => d[1]);
  const sorted = emotions.slice().sort((a, b) => b[1] - a[1]);

  const sentiment = sorted.length
    ? showText
      ? roundToTwo(sorted[0][1])
      : sorted[0][3]
    : "";
  const style = {
    height: "100%",
    display: "flex",
    alignItems: "center",
  };
  if (isSentiment) {
    return (
      <Box data-testid="analyze-table-sentiment" style={style}>
        {sentiment}
      </Box>
    );
  }

  return (
    <Box style={style} data-testid="analyze-table-emotion">
      {emotions.map((d) => d[0]).join(", ")}
    </Box>
  );
};

export const EmotionSentiment = (showText) => {
  return [
    {
      text: "Sentiment",
      field: "*",
      id: "sentiment_col",
      label: "Sentiment",
      sort_field: "sentiment",
      only_desc: true,
      not_export: true,
      togglable: true,
      tooltip: "Sentiment",
      cellComponent: ({ value }) => getEmoSent(value, showText, true),
    },
    {
      text: "Emotion",
      field: "*",
      id: "emotion_col",
      not_sortable: true, // as per PREDASH-804
      sort_field: "emotion",
      not_export: true,
      togglable: true,
      cellComponent: ({ value }) => getEmoSent(value, showText, false),
    },
  ];
};

export const VersionColumns = [
  {
    text: "Models",
    field: "label",
    width: "99%",
  },
  {
    text: "Version",
    align: "right",
    field: "version",
  },
];

export const getSinglePostStats = (platform, harmProfile) => {
  const platformConfig = PLATFORM_CONFIG[platform];

  const baseStats = [
    {
      label: "Risk",
      field: "*",
      render: ({ data }) => {
        const score = data.harm;
        let icon = null;
        let txt = "";

        if (harmProfile.id !== DEFAULT_PROFILE.id) {
          txt = <HarmDot />;
        } else if (score < DEFAULT_HARM_OPTIONS[0].num_threshold) {
          icon = (
            <Box
              component="i"
              className="icon-checkmarkcirclefilled"
              sx={{ color: "#1EA075" }}
            />
          );
          txt = "Low";
        } else if (score < DEFAULT_HARM_OPTIONS[1].num_threshold) {
          txt = "Medium";
        } else if (score < DEFAULT_HARM_OPTIONS[2].num_threshold) {
          icon = <ReportProblemIcon color="error" />;
          txt = "High";
        } else {
          icon = <ReportProblemIcon color="error" />;
          txt = "Very High";
        }

        return (
          <Box sx={styles.justFlex}>
            {icon}{" "}
            <Box component="span" sx={{ ml: 1 }}>
              {txt}
            </Box>
          </Box>
        );
      },
    },
    ...(platformConfig.has_engagements
      ? [
          {
            field: "engagement",
            label: "Engagements",
            format: (val) => formatNumber(val),
          },
        ]
      : []),
    {
      field: "*",
      label: "Emotion",
      render: ({ data }) => {
        const sent = getEmoSent(data, false, true);
        const emo = getEmoSent(data, false, false);

        return (
          <Box sx={styles.justFlex}>
            <Box sx={styles.sent}>{sent}</Box>
            {emo}
          </Box>
        );
      },
    },
    {
      field: "*",
      label: "Logos",
      render: ({ data }) => {
        const allLogos = [];
        const logoStrings = data?.sample_posts?.[0]?.images?.[0]?.logos;

        if (logoStrings) {
          for (const logoString of logoStrings) {
            for (const logo of logoString.split(",")) {
              if (logo !== "") {
                allLogos.push(logo);
              }
            }
          }
        }

        const uniqueLogos = array.uniq(allLogos);

        if (uniqueLogos.length > 0) {
          return (
            <Box sx={styles.justFlex}>
              {uniqueLogos.map((logo, i) => (
                <Box key={i} sx={styles.statBubble}>
                  {logo}
                </Box>
              ))}
            </Box>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const twitterStats = [
    {
      label: "Partisanship",
      render: ({ data }) => {
        const score = data.partisanship;

        let txt = "Not Partisan";

        if (score <= -0.5) {
          txt = "Left";
        } else if (score >= 0.5) {
          txt = "Right";
        }

        return txt;
      },
    },
  ];

  const redditStats = [
    {
      field: "comment_ct",
      label: "Comments",
      format: (val) => (isNaN(val) ? "-" : formatNumber(val)),
    },
    {
      field: "up_votes",
      label: "Upvotes",
      format: (val) => (isNaN(val) ? "-" : formatNumber(val)),
      render: ({ data, stat }) => {
        const up_votes = stat.format(data.up_votes);
        return (
          <Box component={"span"}>
            <UpvotesIcon /> {up_votes}
          </Box>
        );
      },
    },
    // {
    //   field: "down_votes",
    //   label: "Downvotes",
    //   format: (val) => (isNaN(val) ? "-" : formatNumber(val)),
    //   render: ({ data, stat }) => {
    //     const down_votes = stat.format(data.down_votes);
    //     return <Box component={"span"}>
    //       <DownvotesIcon />{" "}{down_votes}
    //     </Box>
    //   }
    // },
  ];

  return [
    ...baseStats,
    ...(platform === PLATFORMS.twitter ? twitterStats : []),
    ...(platform === PLATFORMS.reddit ? redditStats : []),
  ];
};

import { Box, Container } from "@mui/material";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import Projects from "./Projects";
import { withUploaderProvider } from "./context/Context";
import CreateProject from "pages/DataUploader/CreateProject/CreateProject";
import NavigationDrawer from "pages/DashboardPage/NavigationDrawer";

const DataUploader = () => {
  const { path } = useRouteMatch();

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <NavigationDrawer />

      <Box
        component={"main"}
        sx={{ width: "100%", flexGrow: 1 }}
        id="main_content"
      >
        <Container sx={{ padding: "0px !important" }}>
          <Switch>
            <Route path={`${path}/projects/create`}>
              <CreateProject />
            </Route>

            <Route path={`${path}/projects`}>
              <Projects />
            </Route>

            <Route
              exact
              path={`${path}/`}
              render={() => {
                return <Redirect to={`${path}/projects`} />;
              }}
            />
          </Switch>
        </Container>
      </Box>
    </Box>
  );
};

export default withUploaderProvider(DataUploader);

import { Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";
import useEventTracker from "api/hooks/useEventTracker";
import {
  addExportDataConnector,
  deleteExportDataConnector,
  queueDataConnectorJob,
  updateExportDataConnector,
  updateProjectJobSchedule,
} from "api/endpoints/UploadApi";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useUploaderContext } from "../context/Context";
import FileUploadFlow from "../CreateProject/FileUploadFlow";
import SendToQueue from "../CreateProject/SendToQueue";
import ImportData from "../CreateProject/ImportData";
import {
  JOB_STATUSES,
  PROJECT_ACTIONS,
  SET_DATA_CONNECTOR,
  SET_DATA_IMPORT_CONFIG,
} from "../utils/constants";
import AutoUpdates from "./AutoUpdates";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  convertToLocale,
  convertToUTC,
  getISOString,
  offsetDate,
} from "utils/format";
import {
  getNextJobRun,
  sanitizeScheduledJob,
  validateScheduleConfig,
} from "../utils/utils";
import useMessage from "hooks/useMessage";
import {
  CONNECTORS,
  getDataConnectorJobPayload,
} from "../utils/dataConnectors";
import ConnectorPage from "../CreateProject/ConnectorPage";
import { useAppContext } from "context/Context";

const WIDTH = 420;

const useStyles = ({ theme }) => ({
  root: {},
  btn: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  drawerPaper: {
    width: WIDTH,
    overflow: "hidden",
    "& .editor_root": {
      backgroundColor: theme.palette.background.$0 + " !important",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      borderRadius: "4px",
      background: theme.palette.background.$0 + " !important",
    },
    "& .DateRangePickerInput": {
      background: theme.palette.background.$0 + " !important",
    },
    "& .MuiInputBase-input": {
      borderRadius: "4px",
      background: theme.palette.background.$0 + " !important",
    },
    "& .DateInput_input": {
      background: theme.palette.background.$0 + " !important",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "24px 20px",
    borderBottom: "1px solid #302E4F",
  },
  headerTitle: {
    fontSize: "15px",
    fontWeight: "700",
    color: "white",
  },
  closeBtn: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "8px",
  },
  body: {
    padding: "24px",
    height: "calc(100% - 83px)",
    overflowY: "auto",
  },
  drawerContent: {
    height: "100%",
    position: "relative",
  },
});

function JobCreationDrawer({ open, handleClose, data, addJob, scheduleJob }) {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const showMessage = useMessage();

  const [step, setStep] = useState(0);
  const [showQueue, setShowQueue] = useState(false);
  const tracker = useEventTracker();
  const [scheduleConfig, setScheduleConfig] = useState({});
  const [scheduleError, setScheduleError] = useState({});
  const [loading, setLoading] = useState(false);

  const connectorConfig = data?.connectorConfig;
  const exportDataConnectors = data?.project?.exportDataConnectors || [];
  const s3ExportAlreadyEnabled =
    !!exportDataConnectors.length && exportDataConnectors[0]?.id;

  const {
    state: { timezoneOffset },
  } = useAppContext();

  const {
    state: { dataImportConfig, dataConnector, selectedFilesForJob },
    dispatch,
  } = useUploaderContext();

  useEffect(() => {
    const config = data?.project?.jobSchedule?.schedule
      ? convertToLocale(
          data?.project?.jobSchedule?.schedule,
          data?.project?.nextJobRun
        )
      : {
          frequency: "daily",
          time: "23:59",
        };

    setScheduleConfig(config);

    if (data?.type === PROJECT_ACTIONS.configure_auto_updates) {
      setStep(data?.project?.jobSchedule ? 1 : 0);
    } else {
      setStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.project?.jobSchedule]);

  const handleContinue = () => {
    setShowQueue(true);
  };

  const handleS3Export = async (dc, s3ExportOn) => {
    try {
      setLoading(true);

      if (s3ExportOn) {
        const newPayload = {
          data: {
            exportLocation: {
              name: "",
              path: dc.s3Path,
            },
            project: data?.project?.id,
            dataConnector: dc.id,
          },
        };

        if (s3ExportAlreadyEnabled) {
          await updateExportDataConnector(
            exportDataConnectors[0]?.id,
            newPayload
          );
        } else {
          await addExportDataConnector(newPayload);
        }
      } else if (exportDataConnectors[0]?.id) {
        await deleteExportDataConnector(exportDataConnectors[0]?.id);
      }

      handleClose();
      showMessage("S3 destination updated", "success");
    } catch (e) {
      showMessage(e.message, "error");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose();

    setTimeout(() => {
      dispatch({
        type: SET_DATA_IMPORT_CONFIG,
        payload: {},
      });
      dispatch({
        type: SET_DATA_CONNECTOR,
        payload: {},
      });
      setShowQueue(false);
      setStep(0);
      setScheduleConfig({});
    }, 0);
  };

  const handleBack = () => {
    setStep(showQueue ? 1 : 0);
    setShowQueue(false);
  };

  const handleSendQueue = async () => {
    const defaultDate = getISOString(new Date());

    const drawerData = { ...data };

    const populateImportJobProps = () => {
      if (drawerData.type !== PROJECT_ACTIONS.import) return;

      const isFileUpload = connectorConfig?.key === CONNECTORS.fileupload;

      // Convert startDate to UTC
      const startDateTime = offsetDate(
        dataImportConfig.startDate || defaultDate,
        timezoneOffset
      );

      // Convert endDate to UTC
      const endDateTime = offsetDate(
        dataImportConfig.endDate || defaultDate,
        timezoneOffset
      );

      const getJobData = () => {
        if (!isFileUpload) {
          const payload = sanitizeScheduledJob({
            ...drawerData.job,
            dataConnector: dataConnector?.id,
            dataConnectorSecretKey: dataConnector?.secretKey,
            dataProviderName: connectorConfig?.id,
            ...dataImportConfig,
            startDate: startDateTime,
            endDate: endDateTime,
          });

          if (connectorConfig?.key === CONNECTORS.s3) {
            payload.importLocation = {
              path: dataConnector?.s3Path,
            };
          }

          return payload;
        }

        return {
          ...drawerData.job,
          name: selectedFilesForJob.map((d) => d.filename).join(", "),
          dataProviderName: CONNECTORS.fileupload,
          fileNames: selectedFilesForJob.map((d) => d.filename),
          projectFiles: selectedFilesForJob.map((d) => d.filename),
          selectedPlatform: ["twitter", "reddit"],
        };
      };

      drawerData.jobData = getJobData();

      const getTrackerPayload = () => {
        if (isFileUpload) {
          return {
            message: "Sent files job to queue",
            value: selectedFilesForJob.map((file) => file.filename).join(", "),
          };
        }
        return {
          message: "Sent job to queue",
          value: {
            "Data source": connectorConfig?.id,
            "Job name": drawerData.jobData?.name,
          },
        };
      };

      const trackerPayload = getTrackerPayload();
      tracker.track(
        trackerPayload.message,
        "send",
        trackerPayload.value,
        "Data Connectors / Send To Queue"
      );
    };

    populateImportJobProps();

    const getJobSchedule = async () => {
      const { scheduled, scheduleConfig } = dataImportConfig;
      if (!scheduled || !scheduleConfig) return undefined;

      try {
        const updatePayload = {
          jobSchedule: {
            job: drawerData.jobData,
            enabled: true,
            schedule: convertToUTC(dataImportConfig.scheduleConfig),
          },
          nextJobRun: getNextJobRun(dataImportConfig.scheduleConfig),
        };

        await updateProjectJobSchedule(drawerData.project.id, updatePayload);
        return updatePayload;
      } catch (e) {
        showMessage("Error updating schedule", "error");
        console.error(e);
      }
      return undefined;
    };

    const jobScheduleResp = await getJobSchedule();

    if (jobScheduleResp) {
      drawerData.project.jobSchedule = jobScheduleResp.jobSchedule;
      drawerData.project.nextJobRun = jobScheduleResp.nextJobRun;
    }

    try {
      const jobPayload = getDataConnectorJobPayload(drawerData.jobData);
      const queuedJobPayload = await queueDataConnectorJob(jobPayload);

      if (queuedJobPayload) {
        drawerData.jobData.jobStatus = { state: JOB_STATUSES.in_queue };
        drawerData.jobData.id = queuedJobPayload.id;
        drawerData.jobData.createdAt = queuedJobPayload.createdAt;
      }

      addJob(drawerData);

      setTimeout(() => {
        dispatch({
          type: SET_DATA_IMPORT_CONFIG,
          payload: {},
        });
        dispatch({
          type: SET_DATA_CONNECTOR,
          payload: {},
        });
        setScheduleConfig({});
        setShowQueue(false);
        setStep(0);
      }, 0);
    } catch (e) {
      console.error(e);
      showMessage("Error queueing job", "error");
    }
  };

  const handleSchedule = async (importConfig) => {
    const error = validateScheduleConfig(scheduleConfig);

    if (error) {
      return setScheduleError(error);
    }

    const drawerData = { ...data };

    const jobData = sanitizeScheduledJob({
      ...drawerData.job,
      ...importConfig,
    });

    try {
      setLoading(true);

      const resp = await updateProjectJobSchedule(drawerData.project.id, {
        jobSchedule: {
          job: jobData,
          enabled: true,
          schedule: convertToUTC(scheduleConfig),
        },
        nextJobRun: getNextJobRun(scheduleConfig),
      });

      setLoading(false);

      drawerData.project.nextJobRun = resp.data?.attributes?.nextJobRun;
      drawerData.project.jobSchedule = resp.data?.attributes?.jobSchedule;

      // Update data in parent component
      scheduleJob(drawerData);

      handleCancel();
    } catch (e) {
      setLoading(false);
      console.error(e);
      showMessage("Error queueing job", "error");
    }
  };

  const flows = data && (
    <Box sx={styles.body}>
      {data.type === PROJECT_ACTIONS.import &&
        connectorConfig?.key === CONNECTORS.fileupload && (
          <FileUploadFlow
            fromDrawer={true}
            onContinue={handleContinue}
            onCancel={handleCancel}
          />
        )}

      {data.type === PROJECT_ACTIONS.import &&
        connectorConfig &&
        connectorConfig?.key !== CONNECTORS.fileupload &&
        step === 0 && (
          <ConnectorPage
            connectorConfig={connectorConfig}
            fromDrawer={true}
            selectedDataConnector={{
              dataConnectorId: dataConnector?.id || "",
              s3Path: dataConnector?.s3Path || "",
            }}
            onContinue={(dc) => {
              dispatch({
                type: SET_DATA_CONNECTOR,
                payload: dc,
              });

              if (connectorConfig?.key === CONNECTORS.s3) {
                return handleContinue();
              }

              return setStep(1);
            }}
            onCancel={handleCancel}
          />
        )}

      {data.type === PROJECT_ACTIONS.import &&
        connectorConfig &&
        connectorConfig?.key !== CONNECTORS.fileupload &&
        connectorConfig?.key !== CONNECTORS.s3 &&
        step === 1 && (
          <ImportData
            fromDrawer={true}
            connectorConfig={connectorConfig}
            onContinue={handleContinue}
            onCancel={handleCancel}
          />
        )}

      {data.type === PROJECT_ACTIONS.export &&
        connectorConfig?.key === CONNECTORS.s3 && (
          <ConnectorPage
            loading={loading}
            fromDrawer={true}
            selectedDataConnector={{
              dataConnectorId: s3ExportAlreadyEnabled
                ? exportDataConnectors[0]?.dataConnector?.id || ""
                : "",
              s3Path: s3ExportAlreadyEnabled
                ? exportDataConnectors[0]?.exportLocation?.path || ""
                : "",
            }}
            connectorConfig={connectorConfig}
            operationMode={PROJECT_ACTIONS.export}
            s3ExportEnabled={s3ExportAlreadyEnabled}
            onContinue={handleS3Export}
            onCancel={handleCancel}
          />
        )}

      {data.type === PROJECT_ACTIONS.configure_auto_updates && (
        <>
          {step === 0 && connectorConfig && (
            <ConnectorPage
              fromDrawer={true}
              connectorConfig={connectorConfig}
              selectedDataConnector={{
                dataConnectorId: dataConnector?.id || "",
                s3Path: "",
              }}
              onContinue={(dc) => {
                dispatch({
                  type: SET_DATA_CONNECTOR,
                  payload: dc,
                });
                setStep(1);
              }}
              onCancel={handleCancel}
            />
          )}

          {step === 1 && connectorConfig && (
            <>
              <AutoUpdates
                nextJobRun={data?.project?.nextJobRun}
                config={scheduleConfig}
                setConfig={setScheduleConfig}
                error={scheduleError}
              />

              {connectorConfig?.key !== CONNECTORS.s3 && (
                <ImportData
                  configuringAutoUpdates={true}
                  fromDrawer={true}
                  hideAutoUpdate={true}
                  hideDateRange={true}
                  connectorConfig={connectorConfig}
                  onContinue={handleSchedule}
                  onCancel={handleCancel}
                  loading={loading}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );

  return (
    <Box sx={styles.root}>
      <Drawer
        PaperProps={{
          tabIndex: "",
        }}
        sx={{
          ...styles.drawer,
          "& .MuiDrawer-paper": styles.drawerPaper,
        }}
        anchor={"right"}
        open={open}
        onClose={handleCancel}
        transitionDuration={300}
      >
        <Box sx={styles.drawerContent}>
          <Box sx={styles.header}>
            {step === 0 && (
              <IconButton sx={styles.closeBtn} onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            )}
            {step === 1 && (
              <IconButton sx={styles.closeBtn} onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography sx={styles.headerTitle}>{data?.title || ""}</Typography>
          </Box>

          {showQueue ? (
            <SendToQueue onCancel={handleCancel} onContinue={handleSendQueue} />
          ) : (
            flows
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

export default JobCreationDrawer;

import { Box, Divider, Typography } from "@mui/material";
import BreakdownRow from "./BreakdownRow";

export default function BreakdownGroup({ config }) {
  const isCluster = !!config.items;
  const rows = isCluster ? config.items : [config];

  return (
    <Box>
      {isCluster && config.title && (
        <Typography
          sx={{
            color: "white.main",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "24px",
            textTransform: "capitalize",
            py: 0.5,
          }}
        >
          {config.title}
        </Typography>
      )}

      <Box sx={{ mb: isCluster ? 2 : 0 }}>
        {rows.map((d, i) => (
          <Box key={i}>
            {d.style?.divider && <Divider sx={{ my: 0.5 }} />}
            <BreakdownRow {...d} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

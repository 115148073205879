import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./DashboardPage";
import DataUploader from "./DataUploader";
import ProjectsNotFound from "./ProjectsNotFound";
import { useEffect } from "react";
import useAppLogin from "hooks/useAppLogin";
import { getUser } from "api/endpoints/userApi";
import { Toaster, useToasterStore } from "react-hot-toast";
import { useAppContext } from "context/Context";
import { Alert, Backdrop, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMessage from "hooks/useMessage";

export default function RootPage() {
  const { set: setLoginData } = useAppLogin();
  const showMessage = useMessage();

  useEffect(() => {
    const loadUser = async () => {
      const user = await getUser();
      setLoginData(user);
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toasters
  const { toasts } = useToasterStore();
  const errorIsActive =
    toasts && toasts.filter((toast) => toast.visible).length > 0;

  // context
  const {
    state: { snackbarMessage },
  } = useAppContext();

  // summary stats
  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showMessage(null);
  };

  return (
    <div>
      <Switch>
        <Route
          path={[
            "/dashboard/:projectName/platform/:platformId/narrative/:narrativeId",
            "/dashboard/:projectName/platform/:platformId",
            "/dashboard/:projectName",
            "/dashboard",
          ]}
        >
          <DashboardPage />
        </Route>

        <Route path={"/manage"}>
          <DataUploader />
        </Route>

        <Route exact path={"/projects-not-found"}>
          <ProjectsNotFound />
        </Route>

        <Route
          render={() => {
            return <Redirect to={"/dashboard"} />;
          }}
        ></Route>
      </Switch>

      <Toaster />

      {errorIsActive && (
        <Backdrop
          sx={{
            color: "rgba(0, 0, 0, 0.75)",
            zIndex: 1251,
          }}
          open={true}
        />
      )}

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={4000}
        onClose={closeSnackBar}
        message={snackbarMessage?.message}
        color="primary"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {snackbarMessage && (
          <Alert
            onClose={closeSnackBar}
            variant="filled"
            severity={snackbarMessage.type}
          >
            {snackbarMessage.message}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}

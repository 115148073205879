import { useQueries, useQuery } from "react-query";

const parseRequestBody = (apiData) => {
  if (apiData) {
    if (apiData.data) {
      if (apiData.data.body) {
        return apiData.data.body
      }

      if (apiData.data.data) {
        return apiData.data.data?.body || apiData.data.data
      }

      return apiData.data;
    } else {
      return apiData
    }
  }

  return null;
};

// multiple queries
export function useApis(queries) {
  const results = useQueries(queries.map(d => {
    return {
      queryFn: () => d.apiFn(d.payload || {}),
      queryKey: [d.apiKey, d.payload || {}],
      enabled: d.enabled
    }
  }))

  let data = null

  if (results.every(d => d.isSuccess)) {
    data = results.map(parseRequestBody)
  }

  const hasError = results.some(d => d.error)

  return {
    data,
    isLoading: results.some(d => d.isLoading),
    error: hasError ? results.map(d => d.error) : null
  }
}

// single request
export default function useApi({
  apiKey,
  apiFn,
  payload = {},
  enabled = true,
}) {
  const {
    isLoading,
    data: apiData,
    error,
  } = useQuery({
    queryKey: [apiKey, payload],
    queryFn: () => apiFn(payload),
    enabled: enabled,
  });

  let data = null;

  if (apiData) {
    data = parseRequestBody(apiData);
  }

  return {
    isLoading,
    data,
    error,
  };
}

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppContext } from "context/Context";
import { getTimezoneString } from "utils/format";
import Hint from "./Hint";
import { TIMEZONE_TOOLTIP } from "utils/constants";

export default function TimezoneInfo() {
  const {
    state: { timezoneOffset },
  } = useAppContext();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        data-testid="timezone-info"
        variant="body2"
        color="text.muted"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flexGrow: 1,
        }}
      >
        {getTimezoneString(timezoneOffset)}
      </Typography>
      <Hint tooltipText={TIMEZONE_TOOLTIP} dataTestId="timezone-tooltip" />
    </Box>
  );
}

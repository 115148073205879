import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Preloader from "components/UI/Preloader";
import Box from "@mui/material/Box";
import { useEffect, useCallback, useMemo } from "react";
import Hint from "components/UI/Hint";
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme, ...props }) => ({
  borderBottom: "none",
  padding: 6,
  boxSizing: "border-box",
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.background.$1,
    color: theme.palette.secondary.light,
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "18px",
    padding: "15px 12px",
    width: props.width,
  },
  "&.MuiTableCell-body": {
    fontFamily: "Mulish",
    fontWeight: "normal",
    padding: "10px 12px",
    fontSize: "14px",
    lineHeight: "18px",
    backgroundColor: theme.palette.background.$0,
    width: props.width,
  },
}));

const getValue = (col, d) => {
  if (col.field === "*") {
    return d;
  }

  let value = d[col.field];

  if (value === null || value === undefined) {
    return "";
  }

  return typeof col.format === "function" ? col.format(value) : value;
};

function Row({ row, columns, open, setOpen }) {
  const getRowCells = useCallback(
    ({ showExpandBtn = true, isChild = false, data }) => {
      return columns.map((col, i) => {
        const Comp = col.cellComponent;
        const value = getValue(col, data);

        let content = null;

        if (Comp) {
          content = <Comp value={value} datum={data} isChild={isChild} />;
        } else {
          content = value;
        }

        return (
          <StyledTableCell
            key={i}
            width={col.width}
            align={col.align}
            sx={{
              borderTop: isChild ? null : "1px solid #282C38",
              paddingLeft: isChild && i === 0 ? "38px" : "10px",
              color: isChild ? "#AFAFAF" : i === 0 ? "#FFFFFF" : "#AFAFAF",
            }}
          >
            {i === 0 && showExpandBtn ? (
              <Button
                data-testid="accordion-button"
                aria-label="expand row"
                size="small"
                variant="text"
                color="white"
                onClick={() => setOpen(!open)}
                sx={{
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontWeight: "normal",
                  textAlign: "left",
                  px: 0.5
                }}
                disableRipple
                startIcon={
                  <ExpandMoreIcon
                    fontSize="small"
                    sx={{
                      transform: open ? "rotate(-180deg)" : null,
                      color: "#6F7494",
                    }}
                  />
                }
              >
                {content}
              </Button>
            ) : (
              content
            )}
          </StyledTableCell>
        );
      });
    },
    [columns, open, setOpen]
  );

  const parentRowCells = useMemo(() => {
    return getRowCells({
      showExpandBtn: row.children.length ? true : false,
      data: row,
    });
  }, [row, getRowCells]);

  return (
    <>
      <TableRow>{parentRowCells}</TableRow>
      {open && (
        <>
          {(row.children || []).map((childRow, i) => (
            <TableRow key={i}>
              {getRowCells({
                showExpandBtn: false,
                data: childRow,
                isChild: true,
                lastRow: i === row.children.length - 1,
              })}
            </TableRow>
          ))}
        </>
      )}
    </>
  );
}

export default function AccordionTable({
  columns,
  rows,
  loading,
  openRows,
  setOpenRows,
}) {
  const handleOpen = (row, open) => {
    const opened = { ...openRows };
    opened[row.id] = open;
    setOpenRows(opened);
  };

  useEffect(() => {
    const opened = {};

    rows.forEach((d) => {
      if (d.open) {
        opened[d.id] = true;
      }
    });

    setOpenRows((value) => {
      return {
        ...value,
        ...opened,
      };
    });
  }, [rows, setOpenRows]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "#131121",
        borderColor: "border.main",
        borderWidth: 1,
        borderStyle: "solid",
        height: 560,
        overflowY: "auto",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Preloader />
        </Box>
      ) : (
        <Table size="small" aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <StyledTableCell
                  key={i}
                  sx={{ width: column.width }}
                  align={column.align}
                >
                  {column.text}&nbsp;
                  {column.tooltip ? (
                    <Box
                      sx={{
                        display: "inline-flex",
                        verticalAlign: "middle",
                        marginBottom: "2px",
                      }}
                      component="span"
                    >
                      <Hint
                        width={16}
                        height={16}
                        tooltipText={column.tooltip}
                        dataTestId={column.text}
                      />
                    </Box>
                  ) : null}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <Row
                key={i}
                row={row}
                columns={columns}
                open={openRows[row.id]}
                setOpen={(open) => handleOpen(row, open)}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

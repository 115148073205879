import { prod_env } from "utils/constants";

const BlackbirdTextLogo = ({ navOpen, login }) => {
  const width = login ? 205 : navOpen ? 205 : 31;
  const height = login ? 19 : 35;
  const showText = navOpen || login;
  const labsTextY = navOpen || login ? 10 : 32;
  const labsTextX = navOpen || login ? width - 20 : width / 2;

  const showLabs = process.env.REACT_APP_ENV !== prod_env;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="bb-logo"
    >
      <g clipPath="url(#clip0_4606_10190)">
        <path
          d="M13.1546 2.41986L15.5036 0H20.2017L17.3829 2.41986L24.8998 1.45192L31.0073 2.90384L20.6716 13.2632L18.7923 9.47369L16.913 12.3158L20.6716 16.455C18.7923 17.7456 14.0943 19.1654 10.3357 16.455L14.0943 12.3158L12.215 9.47369L10.3357 13.2632L0 2.90384L5.63769 1.45192L13.1546 2.41986Z"
          fill="white"
        />
        {showText && (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.518 10.185C50.1125 9.64498 49.54 9.26701 48.8006 9.05101C49.4208 8.82301 49.9038 8.45701 50.2497 7.95301C50.6074 7.44901 50.7863 6.86101 50.7863 6.18901C50.7863 5.15701 50.3987 4.34701 49.6235 3.75901C48.8603 3.15901 47.8168 2.85901 46.4929 2.85901H40.6255V15.675H46.6718C48.0434 15.675 49.1286 15.363 49.9276 14.739C50.7267 14.103 51.1261 13.245 51.1261 12.165C51.1261 11.385 50.9234 10.725 50.518 10.185ZM46.0279 8.07901H43.3445V5.01901H46.0279C46.7435 5.01901 47.2741 5.14501 47.6199 5.39701C47.9777 5.64901 48.1566 6.03901 48.1566 6.56701C48.1566 7.08301 47.9777 7.46701 47.6199 7.71901C47.2741 7.95901 46.7435 8.07901 46.0279 8.07901ZM47.9777 13.137C47.6438 13.389 47.1012 13.515 46.3499 13.515H43.3445V10.239H46.3499C47.1012 10.239 47.6438 10.371 47.9777 10.635C48.3236 10.899 48.4965 11.319 48.4965 11.895C48.4965 12.459 48.3236 12.873 47.9777 13.137Z"
              fill="white"
            />
            <path
              d="M55.8721 2.85901H53.0814V15.675H61.5607V13.389H55.8721V2.85901Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.8067 12.831H71.7637L72.9801 15.675H75.735L69.8854 2.85901H67.6314L61.7817 15.675H64.5902L65.8067 12.831ZM68.7941 5.88301L70.8514 10.689H66.737L68.7941 5.88301Z"
              fill="white"
            />
            <path
              d="M79.0668 15.027C80.0328 15.567 81.1598 15.837 82.4478 15.837C83.2945 15.837 84.0995 15.711 84.8628 15.459C85.638 15.1951 86.27 14.829 86.759 14.361L85.8824 12.345C85.31 12.777 84.7494 13.095 84.2009 13.299C83.6642 13.491 83.0978 13.587 82.5014 13.587C81.3566 13.587 80.48 13.221 79.8718 12.489C79.2636 11.745 78.9595 10.665 78.9595 9.24902C78.9595 7.84502 79.2636 6.77703 79.8718 6.04502C80.48 5.31303 81.3566 4.94702 82.5014 4.94702C83.0978 4.94702 83.6642 5.04903 84.2009 5.25302C84.7494 5.44503 85.31 5.75702 85.8824 6.18902L86.759 4.17302C86.27 3.70502 85.638 3.34502 84.8628 3.09302C84.0995 2.82903 83.2945 2.69702 82.4478 2.69702C81.1598 2.69702 80.0328 2.96702 79.0668 3.50702C78.1128 4.04702 77.3793 4.81503 76.8664 5.81102C76.3536 6.79503 76.0972 7.94103 76.0972 9.24902C76.0972 10.5691 76.3536 11.727 76.8664 12.723C77.3793 13.707 78.1128 14.4751 79.0668 15.027Z"
              fill="white"
            />
            <path
              d="M91.5098 9.75298L97.0549 15.675H100.419L94.2647 8.97901L100.186 2.85901H96.8586L91.5098 8.49301V2.85901H88.7192V15.675H91.5098V9.75298Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M110.858 10.185C110.453 9.64498 109.88 9.26701 109.141 9.05101C109.761 8.82301 110.244 8.45701 110.589 7.95301C110.948 7.44901 111.126 6.86101 111.126 6.18901C111.126 5.15701 110.739 4.34701 109.963 3.75901C109.2 3.15901 108.157 2.85901 106.833 2.85901H100.965V15.675H107.012C108.383 15.675 109.469 15.363 110.268 14.739C111.066 14.103 111.466 13.245 111.466 12.165C111.466 11.385 111.264 10.725 110.858 10.185ZM106.368 8.07901H103.685V5.01901H106.368C107.083 5.01901 107.614 5.14501 107.96 5.39701C108.317 5.64901 108.497 6.03901 108.497 6.56701C108.497 7.08301 108.317 7.46701 107.96 7.71901C107.614 7.95901 107.083 8.07901 106.368 8.07901ZM108.317 13.137C107.983 13.389 107.441 13.515 106.69 13.515H103.685V10.239H106.69C107.441 10.239 107.983 10.371 108.317 10.635C108.664 10.899 108.836 11.319 108.836 11.895C108.836 12.459 108.664 12.873 108.317 13.137Z"
              fill="white"
            />
            <path
              d="M116.211 2.85901H113.421V15.675H116.211V2.85901Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M126.566 15.675H129.518L127.979 11.697C127.646 10.809 127.103 10.287 126.352 10.131C127.186 9.86704 127.825 9.42901 128.266 8.81701C128.719 8.20501 128.946 7.47301 128.946 6.62101C128.946 5.40901 128.564 4.47901 127.801 3.83101C127.038 3.18301 125.916 2.85901 124.437 2.85901H118.767V15.675H121.522V10.509H123.329C123.734 10.509 124.068 10.575 124.33 10.707C124.604 10.839 124.807 11.073 124.938 11.409L126.566 15.675ZM125.78 7.97101C125.41 8.23501 124.825 8.36701 124.026 8.36701H121.486V5.01901H124.026C124.825 5.01901 125.41 5.15701 125.78 5.43301C126.149 5.69701 126.334 6.11701 126.334 6.69301C126.334 7.26901 126.149 7.69501 125.78 7.97101Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M136.351 2.85901H131.432V15.675H136.351C137.759 15.675 138.964 15.423 139.966 14.919C140.967 14.403 141.73 13.665 142.255 12.705C142.78 11.745 143.042 10.593 143.042 9.24901C143.042 7.91701 142.773 6.77101 142.237 5.81101C141.713 4.85101 140.949 4.11901 139.948 3.61501C138.946 3.11101 137.747 2.85901 136.351 2.85901ZM140.251 9.24901C140.251 12.021 138.892 13.407 136.173 13.407H134.223V5.12701H136.173C138.892 5.12701 140.251 6.50101 140.251 9.24901Z"
              fill="white"
            />
            <path
              d="M147.126 15.675V12.777H144.21V15.675H147.126Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M152.193 12.831H158.151L159.367 15.675H162.122L156.272 2.85901H154.019L148.169 15.675H150.977L152.193 12.831ZM155.181 5.88301L157.238 10.689H153.124L155.181 5.88301Z"
              fill="white"
            />
            <path d="M166 2.85901H163.209V15.675H166V2.85901Z" fill="white" />
          </>
        )}
        {showLabs && (
          <text
            fill="#00e2ed"
            textAnchor="middle"
            x={labsTextX}
            y={labsTextY}
            style={{ fontSize: 10, fontWeight: 700 }}
          >
            LABS
          </text>
        )}
      </g>
      <defs>
        <clipPath id="clip0_4606_10190">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlackbirdTextLogo;

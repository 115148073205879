import { Box, Button, Typography } from "@mui/material";
import {
  cancelDataConnectorJob,
  getProjects,
  runDataConnectorJob,
  updateProjectJobSchedule,
} from "api/endpoints/UploadApi";
import { useUploaderContext } from "../context/Context";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { setNewDocuments, stageDocuments } from "../context/action-creators";
import TableActions from "../TableActions";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getCancelToken } from "api/endpoints/utils";
import AccordionTable from "../components/AccordionTable";
import JobCreationDrawer from "./JobCreationDrawer";
import {
  JOB_STATUSES,
  PROJECT_ACTIONS,
  SET_DATA_CONNECTOR,
  SET_DATA_IMPORT_CONFIG,
} from "../utils/constants";
import { CONNECTORS, DATA_CONNECTORS_LOOKUP } from "../utils/dataConnectors";
import {
  clearProjectFiles,
  convertTimeToLocale,
  getRandomId,
} from "utils/format";
import SearchInput from "components/UI/SearchInput.js";
import useEventTracker from "api/hooks/useEventTracker";
import { getProjectColumns } from "./ProjectColumns";
import useQueryParams from "api/hooks/useQueryParams";
import TablePaginationActions from "pages/DashboardPage/Shared/RichTable/TablePaginationActions";
import { Helmet } from "react-helmet";
import useTitle from "hooks/useTitle";
import JobDetailsDrawer from "./JobDetails/JobDetailsDrawer";
import {
  getNextJobRun,
  getProjectStatus,
  isDateOutsideFreshnessWindow,
} from "../utils/utils";
import { useDebouncedCallback } from "use-debounce";
import { useFlags } from "launchdarkly-react-client-sdk";
import CustomTooltip from "components/UI/Tooltip";
import useMessage from "hooks/useMessage";
import { showError } from "api/errors/errorDisplay";
import { useAppContext } from "context/Context";
import { useQuery } from "react-query";
import { getPageUrl } from "utils/navigate";
import { ROUTE_PATHES } from "utils/constants";
import { hasNarrativeFeed } from "pages/DashboardPage/NarrativeFeed/utils";

const POLL_DELAY = 20 * 1000; // Polls every 20 seconds.

const getJobTitle = (job) => {
  if (!job) {
    return "";
  }

  if (job.dataProviderName === CONNECTORS.fileupload) {
    const cleanedProjectFiles = clearProjectFiles(job.projectFiles || []);

    if (cleanedProjectFiles.length >= 2) {
      const listOfFiles = cleanedProjectFiles.join(", ");
      return (
        <CustomTooltip maxWidth={400} placement="bottom" text={listOfFiles}>
          <Box
            sx={{
              maxWidth: 190,
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {listOfFiles.slice(0, 50)}...
          </Box>
        </CustomTooltip>
      );
    }

    return cleanedProjectFiles[0] || "";
  }

  if (job.dataProviderName === CONNECTORS.twitter) {
    const searchQuery = job?.searchQuery || "";
    return (
      <CustomTooltip maxWidth={400} placement="bottom" text={searchQuery}>
        <Box
          sx={{
            maxWidth: 190,
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {searchQuery}
        </Box>
      </CustomTooltip>
    );
  }

  return job.dataProviderTopicName;
};

/**
 * Creates job object from project.jobSchedule object
 * @param {Object} project
 * @returns
 */
const getScheduledJob = (project) => {
  if (!project?.jobSchedule || !project?.jobSchedule?.job) {
    return null;
  }
  const job = project.jobSchedule.job;
  return {
    ...job,
    id: project.id + "-schedule",
    status: JOB_STATUSES.scheduled,
    jobStatus: { state: JOB_STATUSES.scheduled },
    name: getJobTitle(job) || job?.constellationProjectName,
    jobSchedule: project.jobSchedule,
    source: [job.dataProviderName],
    projectName: project.name,
    projectId: project.id,
    projectFiles: [],
    nextJobRun: project.nextJobRun,
  };
};

const getJob = (jobData, project) => {
  let status = jobData.jobStatus?.state;

  // Swap Succeeded with Completed
  if (status === JOB_STATUSES.succeeded) {
    status = JOB_STATUSES.completed;
  }

  return {
    ...jobData,
    name: getJobTitle(jobData) || jobData.constellationProjectName,
    status: status,
    source: [jobData.dataProviderName?.toLowerCase()],
    projectName: project.name,
    projectId: project.id,
    nextJobRun: project.nextJobRun,
    autoUpdatesDisabled: project.autoUpdatesDisabled,
  };
};

const sortProjectJobs = (a, b) => {
  if (a.status === JOB_STATUSES.scheduled) return -1;
  if (b.status === JOB_STATUSES.scheduled) return 1;
  return new Date(b.createdAt) - new Date(a.createdAt);
};

const sortProjects = (a, b) => {
  const childA =
    a.children[0]?.status === JOB_STATUSES.scheduled
      ? a.children[1]
      : a.children[0];

  const childB =
    b.children[0]?.status === JOB_STATUSES.scheduled
      ? b.children[1]
      : b.children[0];

  const aTime = childA?.createdAt || a.createdAt;
  const bTime = childB?.createdAt || b.createdAt;

  return new Date(bTime) - new Date(aTime);
};

const getJobTrigger = (job) => {
  if (job.isAutoUpdate || job.status === JOB_STATUSES.scheduled)
    return "Auto update";

  if (job.dataProviderName === CONNECTORS.fileupload) return "CSV upload";
  return "Manual";
};

export default function Projects() {
  const title = useTitle();
  const flags = useFlags();
  const showMessage = useMessage();

  const {
    state: { user, timezoneOffset },
  } = useAppContext();

  const { dispatch } = useUploaderContext();

  const history = useHistory();
  const { url } = useRouteMatch();
  const { activeProject, action, target } = useQueryParams();
  const tracker = useEventTracker();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState(null);

  const [jobInfoData, setJobInfoData] = useState(null);

  const [openRows, setOpenRows] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [searchInputText, setSearchInputText] = useState("");
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [currentActionDatum, setCurrentActionDatum] = useState(null);

  const actionHandled = useRef();
  const openedProject = useRef();
  const cancelToken = useRef();

  const getJobsCancelToken = () => {
    cancelToken.current?.cancel();
    cancelToken.current = getCancelToken();
    return cancelToken.current.token;
  };

  const mapProject = (project) => {
    const dataConnectorJobs = [...project.dataConnectorJobs];

    if (!dataConnectorJobs.length) {
      dataConnectorJobs.push({
        id: getRandomId(),
        displayName: "file unavailable",
        jobStatus: { state: JOB_STATUSES.completed },
        dataProviderName: CONNECTORS.fileupload,
        source: [CONNECTORS.fileupload],
        projectName: project.name,
        projectId: project.id,
        nextJobRun: project.nextJobRun,
        projectFiles: [],
        autoUpdatesDisabled: true,
      });
    }

    // Jobs that are not failed
    const notFailedOrCanceledJobs = project?.dataConnectorJobs?.filter(
      (job) => {
        return (
          job?.jobStatus?.state &&
          job?.jobStatus?.state !== JOB_STATUSES.scheduled &&
          job?.jobStatus?.state !== JOB_STATUSES.failed &&
          job?.jobStatus?.state !== JOB_STATUSES.canceled
        );
      }
    );

    // Find most recent query end date by sorting jobs by endDateTime descending and take first job
    const lastJob = notFailedOrCanceledJobs.sort((a, b) => {
      return new Date(b.endDateTime) - new Date(a.endDateTime);
    })?.[0];

    // If last job's endDateTime is older than 1 week
    const olderEndDate = lastJob
      ? isDateOutsideFreshnessWindow(lastJob.endDateTime)
      : null;

    // Supports auto update
    const supportsAutoUpdate = dataConnectorJobs.some((x) => {
      const connectorConfig = DATA_CONNECTORS_LOOKUP.get(
        x.dataProviderName?.toLowerCase()
      );
      return connectorConfig?.supportsAutoUpdate;
    });

    // Set auto-updates status.
    project.autoUpdatesDisabled =
      !flags?.autoUpdates || // If autoUpdates feature flag is false
      !supportsAutoUpdate || // If none of the data connectors support autoUpdates
      olderEndDate || // If older than one week
      !notFailedOrCanceledJobs.length; // If all jobs failed

    // If jobSchedule is already enabled, show the auto updates if data connector supports auto updates
    if (
      project?.jobSchedule?.enabled &&
      flags?.autoUpdates &&
      supportsAutoUpdate
    ) {
      project.autoUpdatesDisabled = false;
    }

    // Control auto update row visibility by jobSchedule.enabled
    if (!project.autoUpdatesDisabled && project?.jobSchedule?.enabled) {
      const scheduledJob = getScheduledJob(project);

      if (scheduledJob) {
        dataConnectorJobs.unshift(scheduledJob);
      }
    }

    // Project source
    const source = dataConnectorJobs.length
      ? Array.from(
          new Set(
            dataConnectorJobs
              .map((x) => x.dataProviderName?.toLowerCase())
              .filter((x) => x)
          )
        )
      : [CONNECTORS.fileupload];

    // Project jobs
    const children = (dataConnectorJobs || [])
      .map((child) => getJob(child, project))
      .sort(sortProjectJobs);

    // find latest job successfully created
    const latestCompleteJob = children
      .filter((x) => x.status === JOB_STATUSES.completed)
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];

    return {
      ...project,
      open:
        openedProject.current === project.id ||
        parseInt(activeProject) === project.id,
      projectName: project.name,
      name: project.displayName,
      status: getProjectStatus(children),
      updatedAt: latestCompleteJob?.updatedAt,
      source,
      children,
      documentCount: project.post_count,
    };
  };

  const getFetchDataPayload = () => {
    const projectsPayload = {
      page: page + 1,
      pageSize,
      config: {
        cancelToken: getJobsCancelToken(),
        clearCacheEntry: true,
      },
    };

    if (activeProject) {
      projectsPayload.projectIds = [activeProject];
    }

    const searchPhrase = searchText.trim();
    if (searchPhrase) {
      projectsPayload.searchText = searchPhrase;
    }

    return projectsPayload;
  };

  const fetchData = async () => {
    // If user is not loaded yet, return empty response
    if (!user || !Object.keys(user).length) {
      return {
        loadedProjects: [],
        pageCount: 0,
      };
    }

    const payload = getFetchDataPayload();
    const response = await getProjects(payload);
    const projects = response.projects.map(mapProject).sort(sortProjects);

    return {
      loadedProjects: projects,
      pageCount: response.pageCount,
    };
  };

  const { isLoading, data, refetch } = useQuery({
    queryKey: [
      "projectManagement",
      page,
      pageSize,
      searchText,
      activeProject,
      flags,
      user,
    ],
    enabled: true,
    queryFn: fetchData,
    refetchInterval: () => POLL_DELAY,
    refetchIntervalInBackground: true,
    onError: () =>
      showError(
        "Error Retrieving Projects",
        "Please refresh the page and try again."
      ),
    onSuccess: (data) => {
      setPageCount(data.pageCount);
    },
  });
  const { loadedProjects } = data ?? {
    loadedProjects: [],
  };

  const debouncedPhraseSearch = useDebouncedCallback((searchPhrase) => {
    setPage(0);
    setSearchText(searchPhrase);
  }, 1000);

  const onPhraseSearch = (searchPhrase) => {
    setSearchInputText(searchPhrase);
    debouncedPhraseSearch(searchPhrase);
  };

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const setDocuments = useCallback(
    (docs) => {
      dispatch(setNewDocuments(docs));
    },
    [dispatch]
  );

  const handleCreateProjectClick = () => {
    history.push(`${url}/create`);
    tracker.track(
      "Clicked Create a Project button",
      "click",
      "",
      "Data Connectors / Projects"
    );
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setTimeout(() => {
      setDrawerData(null);
      setStagedDocuments([]);
      setDocuments([]);
    }, 0);
  };

  const handlePageChange = useCallback((selectedPage) => {
    setOpenRows({});
    setPage(selectedPage);
  }, []);

  const handlePageSizeChange = useCallback((selectedPageSize) => {
    setOpenRows({});
    setPage(0);
    setPageCount(1);
    setPageSize(selectedPageSize);
  }, []);

  const handleAction = useCallback(
    (type, connectorConfig, project) => {
      const job = {
        project: project.id,
        constellationProjectName: project.displayName,
        constellationTeamMembersWithAccess: (project.usersWithAccess || []).map(
          (u) => u.id
        ),
        owner: JSON.parse(localStorage.getItem("user"))?.id,
        organization: { id: JSON.parse(localStorage.getItem("user"))?.orgId },
        topic: project.topics?.[0]?.id || "",
      };

      switch (type) {
        case PROJECT_ACTIONS.import: {
          setDrawerData({
            title: `Import Data: ${project?.name}`,
            type,
            connectorConfig,
            project,
            job,
          });

          // WRB-488: Prepopulate selected s3 configuration
          if (connectorConfig.key === CONNECTORS.s3) {
            const lastJob = project?.dataConnectorJobs?.slice(-1)[0];

            dispatch({
              type: SET_DATA_CONNECTOR,
              payload: {
                secretKey: lastJob?.dataConnector?.secretKey,
                id: lastJob?.dataConnector?.id,
                s3Path: lastJob?.importLocation?.path || "",
              },
            });
          }

          setDrawerOpen(true);

          tracker.track(
            "Clicked " + connectorConfig?.displayName,
            "click",
            "",
            "Data Connectors / TableActions"
          );
          break;
        }

        case PROJECT_ACTIONS.export: {
          setDrawerData({
            title: `Export Data: ${project?.name}`,
            type,
            connectorConfig,
            project,
            job,
          });

          setDrawerOpen(true);

          tracker.track(
            "Clicked export data" + connectorConfig?.displayName,
            "click",
            "",
            "Data Connectors / TableActions"
          );
          break;
        }

        case PROJECT_ACTIONS.configure_auto_updates: {
          if (project.jobSchedule) {
            let secretKey = project.jobSchedule?.job?.dataConnectorSecretKey;
            let dataConnector = project.jobSchedule?.job?.dataConnector;

            // just for backwards compatibility
            if (typeof dataConnector === "object") {
              secretKey = dataConnector.secretKey;
              dataConnector = dataConnector.id;
            }

            dispatch({
              type: SET_DATA_CONNECTOR,
              payload: {
                secretKey: secretKey,
                id: dataConnector,
              },
            });

            dispatch({
              type: SET_DATA_IMPORT_CONFIG,
              payload: project.jobSchedule?.job,
            });
          }

          const autoUpdateTarget = project.source.find((d) => {
            return DATA_CONNECTORS_LOOKUP.get(d)?.supportsAutoUpdate;
          });

          const connectorConfig = DATA_CONNECTORS_LOOKUP.get(autoUpdateTarget);

          if (connectorConfig) {
            setDrawerData({
              title: `Configure ${connectorConfig?.displayName} Auto Updates`,
              type,
              connectorConfig,
              project,
              job,
            });

            setDrawerOpen(true);

            tracker.track(
              `Clicked Configure ${connectorConfig?.displayName} Auto Updates`,
              "click",
              "",
              "Data Connectors / TableActions"
            );
          }

          break;
        }

        case PROJECT_ACTIONS.view_project: {
          return history.push(
            getPageUrl(
              project?.projectName,
              undefined,
              undefined,
              hasNarrativeFeed() ? ROUTE_PATHES.narrativeFeed : ROUTE_PATHES.overview
            )
          );
        }

        default:
          break;
      }
    },
    [dispatch, history, tracker]
  );

  const handleAddJob = async ({ project, jobData }) => {
    openedProject.current = project.id;
    if (project.children) {
      let projectScheduledJob = project.children.find(
        (job) => job.status === JOB_STATUSES.scheduled
      );

      if (projectScheduledJob) {
        projectScheduledJob.nextJobRun = project.nextJobRun;
        projectScheduledJob.jobSchedule = project.jobSchedule;
      } else if (project?.jobSchedule?.enabled) {
        projectScheduledJob = getScheduledJob(project);
      }

      const nonScheduledJobs = project.children.filter(
        (job) => job.status !== JOB_STATUSES.scheduled
      );

      const newJob = getJob(jobData, project);
      project.children = [newJob, ...nonScheduledJobs];

      if (projectScheduledJob) {
        project.children.unshift(projectScheduledJob);
      }
    }

    handleDrawerClose();
    await refetch();
  };

  const handleScheduleJob = async ({ project }) => {
    openedProject.current = project.id;
    await refetch();
    handleDrawerClose();
  };

  const handleCancelJob = async (job) => {
    try {
      await cancelDataConnectorJob(job.id);

      // Temporarily update the object while waiting for the refetch to update the current page.
      job.status = "Canceled";

      await refetch();
      showMessage("Job cancelled", "success");
    } catch (err) {
      const message = "Error occurred while cancelling the job.";
      console.error(message, err);
      showMessage(message, "error");
    }
  };

  const handleScheduledJobCancel = useCallback(
    async (job) => {
      const project = loadedProjects.find((x) => x.id === job.projectId);
      if (!project?.jobSchedule) return;

      try {
        const response = await updateProjectJobSchedule(project.id, {
          jobSchedule: {
            job: project.jobSchedule.job,
            enabled: false,
            schedule: project.jobSchedule.schedule,
          },
          nextJobRun: null,
        });

        // Temporarily update the object while waiting for the refetch to update the current page.
        const attributes = response.data?.attributes;
        project.jobSchedule = attributes?.jobSchedule;
        project.nextJobRun = attributes?.nextJobRun;
        project.children = project.children?.filter(
          (job) => job.status !== JOB_STATUSES.scheduled
        );

        await refetch();
      } catch (err) {
        const errorMessage = "Error occurred while updating the job schedule.";
        console.error(errorMessage, err);
        showMessage(errorMessage, "error");
      }
    },
    [refetch, loadedProjects]
  );

  const handleAutoUpdateToggle = useCallback(
    async (project, enabled) => {
      if (!project.jobSchedule) return;

      const payload = {
        jobSchedule: {
          job: project.jobSchedule.job,
          enabled: enabled,
          schedule: project.jobSchedule.schedule,
        },
      };

      const nextJobRun = enabled
        ? getNextJobRun({
            ...project.jobSchedule.schedule,
            time: convertTimeToLocale(payload.jobSchedule.schedule.time),
          })
        : null;
      payload.nextJobRun = nextJobRun;

      try {
        const response = await updateProjectJobSchedule(project.id, payload);

        // Temporarily update the object while waiting for the refetch to update the current page.
        project.jobSchedule = response?.data?.attributes?.jobSchedule;
        project.nextJobRun = response?.data?.attributes?.nextJobRun;

        if (project.children) {
          if (enabled) {
            const scheduledJob = project.children.find(
              (job) => job.status === JOB_STATUSES.scheduled
            );

            if (scheduledJob) {
              scheduledJob.nextJobRun = project.nextJobRun;
              scheduledJob.jobSchedule = project.jobSchedule;
            } else {
              const scheduledJob = getScheduledJob(project);
              scheduledJob && project.children.unshift(scheduledJob);
            }
          } else {
            project.children = project.children.filter(
              (job) => job.status !== JOB_STATUSES.scheduled
            );
          }
        }
        await refetch();

        tracker.track(
          "Toggled auto update",
          "click",
          {
            value: enabled ? "On" : "Off",
            project: project.name,
          },
          "Data Connectors / Auto updates switch"
        );
      } catch (err) {
        const errorMessage = "Error updating job schedule";
        console.error(errorMessage, err);
        showMessage(errorMessage, "error");
      }
    },
    [refetch, tracker]
  );

  const handleCountdownFinish = useCallback(
    async (job, timedOut) => {
      try {
        // Only send run request when user clicks the button.
        if (!timedOut) {
          await runDataConnectorJob(job.id);
          await refetch();
        }
      } catch (err) {
        const errorMessage = "Error running the job";
        console.error(errorMessage, err);
        showMessage(errorMessage, "error");
      }
    },
    [refetch]
  );

  const handleActionsOpen = useCallback((e, datum) => {
    setActionsAnchorEl(e.target.parentElement);
    setTimeout(() => setCurrentActionDatum(datum), 0);
  }, []);

  const handleInfoDrawerOpen = useCallback(
    (job) => {
      setJobInfoData(job);

      tracker.track(
        "Clicked job details",
        "click",
        {
          "Job status": job.status,
          "Job trigger": getJobTrigger(job),
          "Data source": job.source?.join(","),
          jobId: job.id,
          project: job.constellationProjectName,
        },
        `Data Connectors / Manage Projects`
      );
    },
    [tracker]
  );

  const handleInfoDrawerClose = useCallback(() => {
    tracker.track(
      "Closed job details drawer",
      "click",
      { Context: "Job details drawer" },
      `Data Connectors / Manage Projects / Job details drawer`
    );
    setJobInfoData(null);
  }, [tracker]);

  const columns = useMemo(
    () =>
      getProjectColumns({
        onCancel: handleCancelJob,
        onCountdownFinish: handleCountdownFinish,
        openActions: handleActionsOpen,
        onAutoUpdateToggle: handleAutoUpdateToggle,
        onScheduleCancel: handleScheduledJobCancel,
        onConfigureAutoUpdates: (project) =>
          handleAction(PROJECT_ACTIONS.configure_auto_updates, null, project),
        openInfoDrawer: handleInfoDrawerOpen,
        flags,
        timezoneOffset,
      }),
    [
      flags,
      handleScheduledJobCancel,
      handleCancelJob,
      handleCountdownFinish,
      handleActionsOpen,
      handleAutoUpdateToggle,
      handleAction,
      handleInfoDrawerOpen,
    ]
  );

  // Effect to handle redirected from dashboard / add data.
  useEffect(() => {
    const redirected =
      activeProject &&
      action in PROJECT_ACTIONS &&
      target &&
      actionHandled.current === false;

    if (!redirected) return;

    const project = loadedProjects.find(
      (x) => x.id === parseInt(activeProject)
    );
    const connectorConfig = DATA_CONNECTORS_LOOKUP.get(target);

    if (project && connectorConfig) {
      actionHandled.current = true;
      handleAction(action, connectorConfig, project);
    }
  }, [activeProject, action, target, loadedProjects, handleAction]);

  return (
    <Box sx={{ px: 3 }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Box
          sx={{
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "30px",
              display: "flex",
              alignItems: "center",
              color: "#FFFFFF",
            }}
          >
            Manage Projects
          </Typography>

          <Box
            sx={{
              py: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mr: 1,
                width: "220px",
              }}
            >
              <SearchInput
                value={searchInputText}
                onChange={(e) => onPhraseSearch(e.target.value)}
                onKeyDown={(e) => {
                  const searchPhrase = e.target.value;
                  if (e.key === "Enter" && searchPhrase !== searchText) {
                    onPhraseSearch(searchPhrase);
                  }
                }}
                onClear={() => onPhraseSearch("")}
                size="medium"
              />
            </Box>

            <Button
              data-testid="projects-create-project-button"
              id="CreateProjectButton"
              variant="outlined"
              color="primary"
              size="medium"
              onClick={handleCreateProjectClick}
            >
              Create a project
            </Button>
          </Box>
        </Box>

        <Box id="ProjectsTable">
          <AccordionTable
            openRows={openRows}
            setOpenRows={setOpenRows}
            columns={columns}
            rows={loadedProjects}
            loading={isLoading && !loadedProjects?.length}
          />

          <TablePaginationActions
            page={page}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            pageCount={pageCount}
            loading={isLoading}
          />
        </Box>
      </Box>

      <TableActions
        data={currentActionDatum}
        anchorEl={actionsAnchorEl}
        setAnchorEl={setActionsAnchorEl}
        onAction={(type, connectorConfig) =>
          handleAction(type, connectorConfig, currentActionDatum)
        }
      />

      <JobCreationDrawer
        open={drawerOpen}
        data={drawerData}
        handleClose={handleDrawerClose}
        addJob={handleAddJob}
        scheduleJob={handleScheduleJob}
      />

      <JobDetailsDrawer
        open={!!jobInfoData}
        handleClose={handleInfoDrawerClose}
        job={jobInfoData}
      />
    </Box>
  );
}

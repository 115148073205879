import { Alert, Box, TextField, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import { bbFilterApis } from "../utils/constants";
import useApi from "api/hooks/useApi";
import { CONNECTOR_FORM_TYPES } from "../utils/dataConnectors";
import Hint from "components/UI/Hint";

const labelStyles = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "15px",
  color: "#AFAFAF",
  marginBottom: "10px",
  display: "flex",
  gap: 0.5,
  alignItems: "center",
};

const requiredText = "This field is required";
const noData = [{ label: "No data", value: "no", disabled: true }];

const Select = ({
  id,
  placeholder,
  multiple,
  error,
  value,
  onChange,
  disabled,
  infoAlert,
  options,
  isLoading = false,
}) => {
  return (
    <Box>
      <CustomDropdown
        multiple={multiple}
        id={id}
        placeholder={placeholder}
        value={value || (multiple ? [] : "")}
        onChange={(e) => onChange(e.target.value, options, true)}
        options={options || noData}
        searchEnabled={(options || noData)?.length > 10}
        loading={isLoading}
        error={!!error}
        helperText={requiredText}
        disabled={disabled || !options?.length}
      />

      {infoAlert && (
        <Alert sx={{ mt: 1.5 }} icon={false} variant="filled" severity="info">
          {infoAlert}
        </Alert>
      )}
    </Box>
  );
};

const BoolInput = ({
  error,
  value,
  onChange,
  learnMore,
  disabled,
  maxChars,
  onBlur,
}) => {
  const exceeds = value?.length > maxChars;
  return (
    <Box>
      {learnMore && (
        <Typography
          sx={{
            marginBottom: "10px",
            color: "darkGrey.main",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "20px",
          }}
        >
          {learnMore.text}{" "}
          <Box
            sx={{ color: "primary.main", textDecoration: "none" }}
            component="a"
            target="_blank"
            href={learnMore.link}
          >
            here
          </Box>
        </Typography>
      )}
      <TextField
        className="Elevation-0"
        multiline
        minRows={9}
        fullWidth
        placeholder="Enter your query using boolean operators where needed"
        sx={{ "& .MuiInputBase-root": { height: "auto !important" } }}
        inputProps={{ style: { resize: "vertical" } }}
        error={!!error || exceeds}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value, [], true)}
        onBlur={onBlur}
      />

      <Typography
        sx={{
          textAlign: "right",
          fontWeight: "normal",
          fontSize: "13px",
          mt: 1,
          color: "darkGrey.main",
        }}
      >
        <Box component={"span"} sx={{ color: exceeds ? "error.main" : null }}>
          {value.length}
        </Box>{" "}
        / {maxChars}
      </Typography>

      {error && (
        <Typography
          variant="caption"
          color="error"
          component="div"
          sx={{
            fontWeight: "normal",
            fontSize: "13px",
            py: 0.5,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default function FormItem({
  id,
  field,
  type,
  mode,
  loading,
  actionName,
  placeholder,
  tooltip,
  source,
  state,
  disabled,
  onChange,
  learnMore,
  maxChars = 1024,
  infoAlert,
  onBlur,
  options,
  isSecret,
}) {
  const error = state.error ? state.error[field] : null;
  const value = state[field] || "";

  // Works only for blackbird filters.
  const isBBFilter = source.type === "bb-filters";
  const { data: bbOptions, isLoading: bbOptionsLoading } = useApi({
    apiKey: field,
    apiFn: (payload) => {
      if (bbFilterApis[source.propName]) {
        return bbFilterApis[source.propName](state.connectorName, payload);
      }
      return null;
    },
    payload:
      source.propName === "projects"
        ? {
            secret_key: state.dataConnectorSecretKey,
          }
        : {
            secret_key: state.dataConnectorSecretKey,
            project_id: state.dataProviderProjectId,
          },
    enabled: isBBFilter,
  });

  return (
    <Box sx={{ py: 2 }}>
      <Typography sx={labelStyles}>
        {actionName}
        {tooltip && <Hint tooltipText={tooltip} />}
      </Typography>

      {type === CONNECTOR_FORM_TYPES.select && (
        <Select
          data-testid={id.replace(/\s/g, "-").toLowerCase()}
          state={state}
          source={source}
          id={id}
          placeholder={placeholder}
          tooltip={tooltip}
          multiple={mode === "multi-select"}
          error={error}
          value={value}
          onChange={onChange}
          disabled={disabled}
          infoAlert={infoAlert}
          isLoading={loading || bbOptionsLoading}
          options={isBBFilter ? bbOptions : options}
        />
      )}

      {type === CONNECTOR_FORM_TYPES.boolInput && (
        <BoolInput
          id={id}
          error={error}
          value={value}
          onChange={onChange}
          learnMore={learnMore}
          maxChars={maxChars}
          disabled={false}
          onBlur={onBlur}
        />
      )}

      {type === CONNECTOR_FORM_TYPES.textInput && (
        <TextField
          className="Elevation-0"
          id={id}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          error={!!error}
          helperText={error}
          size="medium"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          data-testid={id}
          // As per https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
          autoComplete="nope"
          type={isSecret ? "password" : "text"}
        />
      )}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import { formatNumber } from "utils/format";

export default function BreakdownRow({ label, value, sign, style }) {
  return (
    <Box
      sx={{
        pt: style.gap === "top" ? 2 : 0.5,
        pb: style.gap === "bottom" ? 2 : 0.5,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontWeight: "normal", // TODO: create typography theme variants
          fontSize: "13px",
          lineHeight: "20px",
          color: style.labelColor || "text.light",
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontWeight: "normal",
          fontSize: "13px",
          lineHeight: "20px",
          color: style.color || "text.light",
        }}
      >
        {style.show_sign && value !== 0
          ? sign === "positive"
            ? "+"
            : "-"
          : ""}
        {formatNumber(value)}
      </Typography>
    </Box>
  );
}

import {
  Typography,
  Drawer,
  IconButton,
  Divider,
  Alert,
  Box,
  useTheme,
} from "@mui/material";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import CloseIcon from "@mui/icons-material/Close";
import { formatNumber } from "utils/format";
import { useMemo, useState } from "react";
import SimpleTable from "components/UI/SimpleTable";
import SearchInput from "components/UI/SearchInput";
import withFallbackValue from "utils/withFallbackValue";
import { QUERY_KEYS } from "utils/constants";

const WIDTH = "480px";

const useStyles = ({ theme }) => ({
  root: {},
  btn: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  drawerPaper: {
    width: WIDTH,
    background: theme.palette.background.$1,
    border: "1px solid " + theme.palette.border.main,
  },
  header: {
    fontSize: "13px",
    fontWeight: "600",
    color: "white",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 20px 20px 15px",
  },
  backButton: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "16px",
  },
  content: {
    padding: theme.spacing(2, 4),
  },
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
  divider: {
    borderColor: "rgba(99, 96, 123, 0.2)",
  },
});

const COLUMNS = withFallbackValue(
  {
    topics: (entityType) => {
      const authors = {
        field: "value",
        sort_field: "value",
        text: "Posts",
        align: "right",
        format: (value) => formatNumber(value),
      };
      const percent = {
        field: "percent",
        sort_field: "percent",
        align: "right",
        text: (
          <Box>
            Percentage <br></br> of Authors
          </Box>
        ),
        format: (value) => (value !== null ? value + "%" : "-"),
      };

      return [
        {
          field: "label",
          sort_field: "label",
          text: "Topic",
          width: "99%",
          cellComponent: (props) => <Box sx={{ p: 0.5 }}>{props.value}</Box>,
        },
        ...(entityType === QUERY_KEYS.users ? [] : [authors]),
        ...(entityType === QUERY_KEYS.users ? [] : [percent]),
      ];
    },
    userCohorts: (entityType) => {
      const authors = {
        field: "value",
        sort_field: "value",
        text: "Authors",
        align: "right",
        format: (value) => formatNumber(value),
      };
      const percent = {
        field: "percent",
        sort_field: "percent",
        text: (
          <Box>
            Percentage <br></br> of Posts
          </Box>
        ),
        align: "right",
        format: (value) => (value !== null ? value + "%" : "-"),
      };
      return [
        {
          field: "label",
          sort_field: "label",
          text: "Cohort",
          width: "99%",
          cellComponent: (props) => <Box sx={{ p: 0.5 }}>{props.value}</Box>,
        },
        ...(entityType === QUERY_KEYS.users ? [] : [authors]),
        ...(entityType === QUERY_KEYS.users ? [] : [percent]),
      ];
    },
    userGroups: (entityType) => {
      return [
        {
          field: "label",
          sort_field: "label",
          text: "Group name",
          width: "99%",
          cellComponent: (props) => <Box sx={{ p: 0.5 }}>{props.value}</Box>,
        },
        ...(entityType === "stats"
          ? [
              {
                field: "value",
                sort_field: "value",
                text: "User count",
                align: "right",
                format: (value) => formatNumber(value),
              },
            ]
          : []),
      ];
    },
    emotions: () => {
      return [
        {
          field: "label",
          sort_field: "label",
          text: "Emotion",
          width: "99%",
          cellComponent: (props) => <Box sx={{ p: 0.5 }}>{props.value}</Box>,
        },
        {
          field: "value",
          sort_field: "value",
          text: "Posts",
          align: "right",
          format: (value) => formatNumber(value),
        },
        {
          field: "percent",
          sort_field: "percent",
          text: (
            <Box>
              Percentage <br></br> of total Posts
            </Box>
          ),
          align: "right",
          format: (value) => (value !== null ? value + "%" : "-"),
        },
      ];
    },
  },
  () => () => []
);

export default function StatsDrawer() {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const {
    dispatch,
    state: { statsDrawerData },
  } = useAppContext();

  const columns = COLUMNS[statsDrawerData.viewMoreId](
    statsDrawerData.entityType
  );

  const [searchTerm, setSearchTerm] = useState("");

  const tableData = useMemo(
    () =>
      statsDrawerData.items.filter((d) => {
        const term = searchTerm.trim().toLowerCase();
        if (term.length) {
          return d.label.toLowerCase().includes(term);
        }
        return true;
      }),
    [statsDrawerData.items, searchTerm]
  );

  const handleClose = () => {
    dispatch({
      type: ACTIONS.SET_STATS_DRAWER_OPEN,
      payload: null,
    });
  };

  const open = !!statsDrawerData;

  return (
    <Box sx={styles.root}>
      <Drawer
        sx={{ ...styles.drawer, "& .MuiDrawer-paper": styles.drawerPaper }}
        hideBackdrop={Boolean(statsDrawerData?.hideBackdrop)}
        anchor={"right"}
        open={open}
        onClose={handleClose}
      >
        <Box sx={styles.headerTitle}>
          <IconButton sx={styles.backButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography sx={styles.header}>
            {statsDrawerData?.title || ""}
          </Typography>
        </Box>

        <Divider sx={styles.divider} />

        <Box sx={styles.content}>
          {statsDrawerData.info && (
            <Box sx={{ mb: 4, mt: 4 }}>
              <Alert severity="warning">{statsDrawerData.info}</Alert>
            </Box>
          )}

          <Box
            sx={{
              mb: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ whiteSpace: "nowrap" }}>
              {statsDrawerData?.secondaryTitle} ({statsDrawerData?.items.length}
              )
            </Typography>

            <Box sx={{ width: "200px" }}>
              <SearchInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
          </Box>

          <Box>
            <SimpleTable
              height="66vh"
              stickyHeader
              defaultSort="value"
              columns={columns}
              data={tableData}
            ></SimpleTable>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

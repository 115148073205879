import { Box, CircularProgress } from "@mui/material";

const useStyles = (props) => ({
  parent: {
    textAlign: "center",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: props.size + 8,
  },
  circle: {
    rx: 10,
    ry: 10
  },
});

const Preloader = ({ size = 48, thickness = 8, color }) => {
  const styles = useStyles({ size });

  return (
    <Box className="progress_circle" sx={styles.parent}>
      <CircularProgress
        color={color || "primary"}
        size={size}
        thickness={thickness}
        sx={{
          "& .MuiCircularProgress-circle": styles.circle,
        }}
      />
    </Box>
  );
};

export default Preloader;

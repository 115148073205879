import { PLATFORM_CONFIG } from "./constants";

// Take the request object and return a new object with the platform added unless it's "All"
export const preparePlatformForApi = (req) => {
  if(!req?.platform) return req;
  const { platform } = req;
  const reqCopy = { ...req };
  delete reqCopy.platform;
  return {
    ...reqCopy,
    ...(platform !== PLATFORM_CONFIG.all.value && { platform }),
  }
};

// Parse the platformId from the url to an array of platformIds
export const parsePlatformUrlID = (platformId) => {
  if(!platformId) return PLATFORM_CONFIG.all.value;
  if(platformId.includes(',')) return platformId.split(',');
  return platformId === PLATFORM_CONFIG.all.value ? PLATFORM_CONFIG.all.value : [platformId];
};

// Check if its an all platform or if it contains one of the platforms
export const isPlatformAccepted = (platforms, acceptedPlatforms) => {
  if(!platforms) return false;
  if(platforms.length === 0) return false;
  // If the platform is not an array, convert it to an array
  if(!Array.isArray(platforms)) platforms = [platforms];
  return platforms[0] === PLATFORM_CONFIG.all.value || platforms.includes(acceptedPlatforms);
};

export const extractDomain = (url) => {
  try {
    const domain = new URL(url).hostname;
    return domain?.replace("www.", "");
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}
import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "api/endpoints/AnalyticsApi";
import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";

function PasswordResetEmailPage() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onSubmit = async (event) => {
    event.preventDefault();
    setMessage(null);
    setLoading(true);
    try {
      await forgotPassword(email);
      setMessage({
        type: "success",
        message: "Check your email for a password reset link",
      });
      setEmail("");
      setLoading(false);
    } catch (error) {
      setMessage({
        type: "error",
        message:
          "Error sending reset password link: make sure an account exists",
      });
      setLoading(false);
      console.error(error);
    }
  };

  const backToLogin = () => {
    history.push("/login");
  };

  return (
    <LoginBackground>
      <Typography variant="h4">Forgot your password?</Typography>

      <Typography variant="subtitle2" color={"text.muted"} sx={{ my: 2 }}>
        Don’t worry! Type your email to reset your password, we will send you
        further instructions
      </Typography>

      {message && <Message {...message} />}

      <form autoComplete="true" onSubmit={onSubmit}>
        <Box sx={{ my: 2 }}>
          <Typography
            variant="label1"
            component="div"
            sx={{ mb: 1, color: "text.light" }}
            data-testid="forgot-password-email-label"
          >
            Email Address
          </Typography>
          <InputValidate
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fieldProps={{
              disabled: loading,
              id: "ForgotPasswordEmail",
              "data-testid": "forgot-password-email-input"
            }}
          />
        </Box>
        <Button
          type={"submit"}
          size={"large"}
          variant={"contained"}
          color={"primary"}
          fullWidth
          disabled={loading || !email}
          id={"ForgotPasswordSubmit"}
          data-testid="forgot-password-submit-button"
        >
          {loading ? (
            <CircularProgress color="secondary" size={24} thickness={3} />
          ) : (
            "Send"
          )}
        </Button>
      </form>

      <Button
        sx={{ px: 1, mt: 0.5, transform: "translate(0px, 12px)" }}
        variant="text"
        size="medium"
        onClick={backToLogin}
        id={"BackButton"}
        data-testid="back-to-login-button"
      >
        Back to Login
      </Button>
    </LoginBackground>
  );
}

export default PasswordResetEmailPage;

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import COLORS from "./colors";
import { ReactComponent as CheckboxIcon } from "icons/UI/Checkbox.svg";
import { ReactComponent as CheckboxCheckedIcon } from "icons/UI/CheckboxChecked.svg";

const HTML_FONT_SIZE = 16;
const getFontSize = (fontSize) => fontSize / HTML_FONT_SIZE + "rem";
const getLineHeight = (lineHeight, fontSize) => lineHeight / fontSize;

let globalMuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 990,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "dark",
    action: {
      disabled: "#5A5F89",
    },
    text: {
      muted: "#6F7494",
      light: "#AFAFAF",
      grey: "#757B8C",
      harmExpl: "#8F94B3",
      anomalousExpl: "#8F94B3",
      toxicExpl: "#9179BD",
      light_2: "#E8E9F3",
      negative: "#FD9087",
      positive: "#8FE14A",
    },
    border: {
      main: "#282C38",
      popup: "#3A364D",
      harmExpl: "#292D46",
      anomalousExpl: "#292D46",
      toxicExpl: "#2E2442",
      input: "#3C3949",
      lineNew: "#2D3240",
      widget: "1px solid #282C38",
    },
    primary: {
      main: "#2BE7A0",
      contrastText: "#ffffff",
    },
    report: {
      main: "#B30741",
      contrastText: "#ffffff",
    },
    white: {
      main: "#ffffff",
    },
    grey: {
      main: "rgba(255,255,255,0.6)",
    },
    darkGrey: {
      main: "#6F7494",
    },
    secondary: {
      main: "#5A5F89",
    },
    warning: {
      main: "#DFA304",
    },
    error: {
      main: "#FD5447",
    },
    slider: {
      main: "#7165E0",
    },
    switchBtn: {
      main: "#AFAFAF",
    },
    background: {
      main: "#131121",
      default: "#131121",
      paper: "#1C192B",
      post: "#1E1B2E",
      stack: "#2A283B",
      dropdownActive: "#302c3c",
      dropdownInactive: "#232134",
      createNarrativeBG: "#1D1B2D",
      clusterToggler: "#28243A",
      popup: "#272438",
      breadcrumbProject: "#1A1729",
      breadcrumbNarrative: "#212334",
      harmExpl: "rgba(53, 131, 247, 0.08)",
      anomalousExpl: "rgba(34, 32, 50, 0.99)",
      toxicExpl: "rgba(148, 53, 247, 0.08)",
      input: "#171622",
      $0: "#131121",
      $1: "#1C192B",
      $2: "#272438",
      $3: "#302E4F",
    },
    widget: {
      main: "#1C192B",
      contrastText: "#ffffff",
      shadow:
        "0px 5px 5px -3px rgba(0, 0, 0, 0.2), inset 0px 0px 1px rgba(255, 255, 255, 0.15)",
    },
    tile: {
      main: "#242234",
      contrastText: "#ffffff",
    },
    tileActive: {
      main: "#2A283C",
    },
    ...COLORS,
  },

  typography: {
    htmlFontSize: HTML_FONT_SIZE,
    fontFamily: "Mulish, 'Courier New', Courier, monospace, sans-serif",
    letterSpacing: "normal",
    fontStyle: "normal",
    h1: {
      fontSize: getFontSize(36),
      lineHeight: getLineHeight(48, 36),
      fontWeight: 600,
    },
    h2: {
      fontSize: getFontSize(32),
      lineHeight: getLineHeight(40, 32),
      fontWeight: 400,
    },
    h3: {
      fontSize: getFontSize(24),
      lineHeight: getLineHeight(32, 24),
      fontWeight: 400,
    },

    h4: {
      fontSize: getFontSize(20),
      lineHeight: getLineHeight(28, 20),
      fontWeight: 500,
    },
    h5: {
      fontSize: getFontSize(18),
      lineHeight: getLineHeight(24, 18),
      fontWeight: 500,
    },
    h6: {
      fontSize: getFontSize(18),
      lineHeight: getLineHeight(24, 18),
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: getFontSize(16),
      lineHeight: getLineHeight(24, 16),
      fontWeight: "bold",
    },
    subtitle2: {
      fontSize: getFontSize(14),
      lineHeight: getLineHeight(24, 14),
      fontWeight: 600,
    },
    label4: {
      display: "block",
      fontSize: getFontSize(14),
      lineHeight: getLineHeight(16, 10),
      fontWeight: 400,
    },
    label3: {
      display: "block",
      fontSize: getFontSize(14),
      lineHeight: getLineHeight(16, 10),
      letterSpacing: "1px",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    label2: {
      display: "block",
      fontSize: getFontSize(12),
      lineHeight: getLineHeight(16, 10),
      letterSpacing: "1px",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    label1: {
      display: "block",
      fontSize: getFontSize(10),
      lineHeight: getLineHeight(16, 10),
      letterSpacing: "1px",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    body1: {
      fontSize: getFontSize(16),
      lineHeight: getLineHeight(24, 16),
      fontWeight: 400,
    },
    body2: {
      fontSize: getFontSize(14),
      lineHeight: getLineHeight(20, 14),
      fontWeight: 400,
    },
    button1: {
      fontSize: getFontSize(14),
      lineHeight: getLineHeight(24, 14),
      fontWeight: 600,
    },
    button2: {
      fontSize: getFontSize(12),
      lineHeight: getLineHeight(16, 12),
      fontWeight: 400,
    },
    caption: {
      fontSize: getFontSize(12),
      lineHeight: getLineHeight(16, 12),
      fontWeight: 400,
    },
    overline: {
      fontSize: getFontSize(10),
      lineHeight: getLineHeight(16, 10),
      fontWeight: 400,
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    button: {
      textTransform: "none",
    },
  },

  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          backgroundColor: "#131121",
        },
        root: {
          ":root ::-webkit-slider-thumb": {
            color: "#282C38",
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-sizeSmall": {
            fontSize: "12px",
            height: "36px",
            lineHeight: "16px",
            padding: "9px 12px",
          },
          "&.MuiChip-sizeMedium": {
            fontSize: "13px",
            height: "40px",
            lineHeight: "18px",
            padding: "10px 16px",
          },
          "&.MuiChip-sizeLarge": {
            fontSize: "14px",
            height: "46px",
            lineHeight: "20px",
            padding: "12px 18px",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "12px",

          borderRadius: "4px",
          textTransform: "none",
          transition: "none",

          // Not all buttons need focus..
          // "&:focus": {
          //   boxShadow: "0px 0px 0px 4px rgba(146, 129, 233, 0.20)",
          // },

          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
        sizeSmall: {
          fontSize: "12px",
          lineHeight: "16px",
          padding: "10px 16px",
        },
        sizeMedium: {
          fontSize: "13px",
          lineHeight: "20px",
          padding: "10px 20px",
        },
        sizeLarge: {
          fontSize: "14px",
          lineHeight: "22px",
          padding: "12px 24px",
        },
        containedPrimary: {
          fontWeight: 700,
          color: "#1C192B",
          background: "#2BE7A0",
          "&:hover": {
            background: "#CDF3DA",
          },
          "&.Mui-disabled": {
            color: "#1C192B",
            fontWeight: 700,
            background: "#2BE7A0",
          },
        },
        outlinedPrimary: {
          color: "#2BE7A0",
          border: "1px solid #302E4F",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            color: "#2BE7A0",
            border: "1px solid #2BE7A0",
          },
          "&.Mui-disabled": {
            color: "#2BE7A0",
            border: "1px solid #302E4F",
          },
        },
        outlinedError: {
          color: "#FD5447",
          border: "1px solid #FD5447",
          "&:hover": {
            background: "#1C192B",
            border: "1px solid #FD5447",
          },
          "&:focus": {
            color: "white",
            background: "#1C192B",
          },
          "&.Mui-disabled": {
            color: "#FD5447",
            border: "1px solid #FD5447",
          },
        },
        textPrimary: {
          color: "#2BE7A0",
          background: "transparent",
          "&:hover": {
            color: "#2BE7A0",
            background: "#1C192B",
          },
          "&:focus": {
            color: "#2BE7A0",
            background: "transparent",
          },
          "&.Mui-disabled": {
            color: "#2BE7A0",
            background: "transparent",
          },
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
      },
      styleOverrides: {
        root: {
          color: "#6F7494",
          borderRadius: 3,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "400",
          padding: "12px 16px",
          height: "40px",

          "& input": {
            padding: 0,
            background: "transparent !important"
          },

          "& input[type=number]": {
            MozAppearance: "textfield",
          },

          "& input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },

          "& input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },

          "& input::-webkit-input-placeholder": {
            color: "#6F7494",
          },

          "&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#302E4F",
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#302E4F",
          },

          "&.Mui-focused": {
            boxShadow: "0px 0px 0px 4px rgba(146, 129, 233, 0.20)",

            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
            },

            "&:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
              borderColor: "#584DA8",
              borderWidth: 1,
            },
          },

          "&.Mui-disabled": {
            opacity: 0.5,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#302E4F",
            },
          },

          "& .MuiInputAdornment-root": {
            color: "#6F7494",
          },

          "&.Mui-error .MuiInputAdornment-root": {
            color: "#FD5447",
          },
        },

        sizeSmall: {
          height: "36px",
          fontSize: "12px",
          padding: "9px 12px",
          lineHeight: "18px",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .Mui-error": {
            marginLeft: "0px !important",
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "gray",
          },
          color: "white", // if you also want to change the color of the input, this is the prop you'd use
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "&.Elevation-0 .MuiOutlinedInput-root": {
            background: "#1C192B",
          },

          "&.Elevation-1 .MuiOutlinedInput-root": {
            background: "#131121",
          },
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "4px 10px",
          backgroundColor: "#000000",
          color: "#FFFFFF",
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "13px !important",
          wordBreak: "normal",
        },
        tooltipPlacementTop: {
          margin: "5px 0px",
        },
        arrow: {
          color: "#000000",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          color: "#EAEAEA",
          fontSize: 13,
          lineHeight: "20px",
          fontWeight: "normal",
          alignItems: "center",
        },

        icon: {
          padding: 0,
        },

        message: {
          padding: 0,
        },

        filledSuccess: {
          backgroundColor: "#0D341B",
          border: "1px solid #196736",
        },
        filledError: {
          backgroundColor: "#3B0501",
          border: "1px solid #880C02",
        },
        filledInfo: {
          backgroundColor: "#061D24",
          border: "1px solid #1C809C",
        },
        filledWarning: {
          backgroundColor: "#231900",
          border: "1px solid #9A7104",
        },

        standardSuccess: {
          backgroundColor: "#0D341B",
          border: "1px solid #196736",
        },
        standardError: {
          backgroundColor: "#3B0501",
          border: "1px solid #880C02",
        },
        standardInfo: {
          backgroundColor: "#061D24",
          border: "1px solid #1C809C",
        },
        standardWarning: {
          backgroundColor: "#231900",
          border: "1px solid #9A7104",
        },

        outlinedSuccess: {
          border: "1px solid #196736",
        },
        outlinedError: {
          border: "1px solid #880C02",
        },
        outlinedInfo: {
          border: "1px solid #1C809C",
        },
        outlinedWarning: {
          backgroundColor: "#231900",
          border: "1px solid #9A7104",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          color: "#AFAFAF",
          fontSize: "14px",
          lineHeight: "22px",
          padding: "5px 20px",
          "&:hover": {
            background: "transparent",
            color: "#ffffff",
          },
        },
      },
    },

    MuiMenu: {
      defaultProps: {},
      styleOverrides: {
        paper: {
          background: "#1C192B",
          border: "1px solid #302E4F",
          borderRadius: "6px",
          boxShadow: "0px 3px 8px -3px #00000080",
        },
      },
    },
    MuiList: {
      defaultProps: {},
    },
    MuiModal: {
      defaultProps: {},
    },
    MuiPaper: {
      defaultProps: {},
      styleOverrides: {
        root: {
          background: "#1C192B",
          border: "1px solid #302E4F",
          borderRadius: "4px",
          boxShadow: "0px 3px 8px -3px rgba(0, 0, 0, 0.50)",
        },
      },
    },
    MuiDrawer: {
      defaultProps: {},

      styleOverrides: {
        paper: {
          background: "#1C192B",
          border: "1px solid #282C38",
        },
      },
    },

    MuiPopover: {
      defaultProps: {},

      styleOverrides: {
        paper: {
          background: "#1C192B",
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          transition: "none",
          backgroundImage: "none",
          boxShadow: "none",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#282C38",
        },
      },
    },

    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: 99999999,
        },
      },
    },
  },
});

globalMuiTheme = responsiveFontSizes(globalMuiTheme);

export default globalMuiTheme;

import Countdown from "./Countdown";
import JobStatus from "./JobStatus";
import { formatDateTime, formatNumber, getTimezoneDescriptionByOffset } from "utils/format";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelJob from "./CancelJob";
import { Box, Button, Divider, IconButton, Switch, Tooltip } from "@mui/material";
import { JOB_STATUSES } from "../utils/constants";
import { DATA_CONNECTORS_LOOKUP } from "../utils/dataConnectors";
import HistoryIcon from "@mui/icons-material/History";
import ScheduleCountdown from "./SchduleCountdown";

export const getProjectColumns = ({
  onCountdownFinish,
  openActions,
  onConfigureAutoUpdates,
  onAutoUpdateToggle,
  openInfoDrawer,
  onScheduleCancel,
  onCancel,
  flags,
  timezoneOffset,
}) => {
  const jobSchedule = flags.autoUpdates
    ? [
        {
          field: "jobSchedule",
          text: "Auto Updates",
          align: "center",
          width: "15%",
          cellComponent: ({ datum, value, isChild }) => {
            if (
              datum.autoUpdatesDisabled ||
              datum.project?.autoUpdatesDisabled
            ) {
              return null;
            }

            if (value) {
              if (isChild && value.enabled) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <HistoryIcon fontSize="small" sx={{ mr: 1 }} />
                    {value.schedule.frequency}
                  </Box>
                );
              }

              if (!isChild) {
                return (
                  <Switch
                    size="small"
                    checked={value.enabled}
                    onChange={(e) => {
                      onAutoUpdateToggle(datum, e.target.checked);
                    }}
                    color="indigo"
                  />
                );
              }
            }

            return (
              !isChild &&
              !datum.jobSchedule && (
                <Button
                  data-testid="configure-auto-updates-button"
                  type="text"
                  color="primary"
                  size="small"
                  sx={{ fontWeight: "normal" }}
                  onClick={() => onConfigureAutoUpdates(datum)}
                >
                  Configure
                </Button>
              )
            );
          },
        },
      ]
    : [];
  return [
    {
      field: "name",
      text: "Project",
      width: "300px",
      cellComponent: ({ value, isChild }) => {
        let wordBreak = "normal";

        if (typeof value === "string" && !value?.includes(" ")) {
          wordBreak = "break-all";
        }

        return (
          <Box sx={{ paddingLeft: isChild ? "29px" : 0, wordBreak }}>
            {value}
          </Box>
        );
      },
    },
    {
      field: "source",
      text: "Source",
      align: "center",
      cellComponent: ({ value }) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {value.map((source, i) => {
              const Icon = DATA_CONNECTORS_LOOKUP.get(source)?.icon;
              return (
                <Box
                  component={"span"}
                  sx={{ mx: 0.2, display: "inline-flex", alignItems: "center" }}
                  key={i}
                >
                  {Icon ? <Icon /> : null}
                </Box>
              );
            })}
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      format: (v) => (v ? formatDateTime(v, timezoneOffset, false) : ""),
      text: "Date Created",
      align: "left",
      cellComponent: ({ datum, value, isChild }) => {
        if (
          isChild &&
          (datum.status === JOB_STATUSES.in_queue ||
            datum.status === JOB_STATUSES.scheduled)
        ) {
          return "";
        }
        return <Tooltip title={getTimezoneDescriptionByOffset(timezoneOffset)} placement="top" enterDelay={500}>{value}</Tooltip>;
      },
    },
    {
      field: "updatedAt",
      format: (v) => (v ? formatDateTime(v, timezoneOffset, false) : ""),
      text: "Last Updated",
      align: "left",
      cellComponent: ({ datum, value, isChild }) => {
        if (
          isChild &&
          (datum.status === JOB_STATUSES.in_queue ||
            datum.status === JOB_STATUSES.scheduled ||
            datum.status === JOB_STATUSES.processing)
        ) {
          return "";
        }
        return <Tooltip title={getTimezoneDescriptionByOffset(timezoneOffset)} placement="top" enterDelay={500} >{value}</Tooltip>;
      },
    },
    ...jobSchedule,
    {
      field: "documentCount",
      text: "Documents",
      align: "center",
      width: "135px",
      tooltip:
        "Totals are rounded using decimal notation to the nearest significant digit.",
      format: (v) => (!isNaN(v) ? formatNumber(+v) : v),
      cellComponent: ({ value }) => {
        return value === "" ? "-" : value;
      },
    },
    {
      field: "status",
      text: "Status",
      align: "left",
      width: "110px",
      cellComponent: ({ value, datum, isChild }) => {
        if (
          isChild &&
          value === JOB_STATUSES.scheduled &&
          datum.jobSchedule &&
          datum.jobSchedule.enabled &&
          datum.nextJobRun
        ) {

          return (
            <ScheduleCountdown
              nextJobRun={datum.nextJobRun}
              config={datum.jobSchedule.schedule}
            />
          );
        }

        if (isChild && value === JOB_STATUSES.in_queue) {
          return (
            <Countdown
              status={value}
              onFinish={(timedout) => onCountdownFinish(datum, timedout)}
              timestamp={new Date(datum.createdAt).getTime()}
            />
          );
        }

        return (
          <JobStatus
            isChild={isChild}
            value={value}
            onClick={isChild ? () => openInfoDrawer(datum) : null}
          />
        );
      },
    },
    {
      field: "id",
      text: "Actions",
      sortable: false,
      filterable: false,
      hideable: false,
      align: "center",
      width: "86px",
      cellComponent: ({ datum, isChild }) => {
        if (isChild) {
          let cancelButton = null;

          if (isChild && datum.status === JOB_STATUSES.in_queue) {
            cancelButton = <CancelJob onConfirm={() => onCancel(datum)} />;
          }

          if (isChild && datum.status === JOB_STATUSES.scheduled) {
            cancelButton = (
              <CancelJob onConfirm={() => onScheduleCancel(datum)} />
            );
          }

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {cancelButton}

              {cancelButton && <Divider orientation="vertical" flexItem />}

              <IconButton
                data-testid={`info-button-${datum.id}`}
                size="small"
                onClick={() => openInfoDrawer(datum)}
                disableRipple
                sx={{
                  color: "#6F7494",
                  "&:hover": {
                    color: "#fff",
                  },
                }}
              >
                <Box
                  component="i"
                  className="icon-info"
                  sx={{ width: "20px", height: "20px" }}
                />
              </IconButton>
            </Box>
          );
        }

        return (
          !isChild && (
            <IconButton
              data-testid={`actions-menu-button-${datum.id}`}
              size="small"
              onClick={(e) => openActions(e, datum)}
              sx={{
                color: "#6F7494",
                "&:hover": {
                  color: "#fff",
                },
              }}
            >
              <MoreVertIcon sx={{ pointerEvents: "none" }} fontSize="small" />
            </IconButton>
          )
        );
      },
    },
  ];
};

import { JOB_STATUSES, JOB_STATUS_LABELS } from "../utils/constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { ReactComponent as ReadyIcon } from "icons/Uploader/ready.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@mui/icons-material/Cancel";
import Preloader from "components/UI/Preloader";
import Box from "@mui/material/Box";

const config = {
  [JOB_STATUSES.processing]: (
    <Preloader size={14} thickness={4} color="inherit" />
  ),
  [JOB_STATUSES.running]: <Preloader size={14} thickness={4} color="inherit" />,
  [JOB_STATUSES.canceled]: <CancelIcon sx={{ color: "#6F7494" }} />,
  [JOB_STATUSES.completed]: (
    <CheckCircleIcon fontSize="small" sx={{ color: "#6F7494" }} />
  ),
  [JOB_STATUSES.in_queue]: <ScheduleIcon fontSize="small" />,
  [JOB_STATUSES.failed]: (
    <WarningAmberIcon fontSize="small" sx={{ color: "#FD5447" }} />
  ),
  [JOB_STATUSES.ready]: <ReadyIcon />,
  [JOB_STATUSES.scheduled]: (
    <ScheduleIcon fontSize="small" sx={{ color: "#F7B916" }} />
  ),
};

export default function JobStatus({
  value,
  isChild,
  iconSize = "16px",
  onClick,
}) {
  const color = "#AFAFAF";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: onClick ? "pointer" : null,
      }}
      onClick={onClick}
    >
      {config[value] ? (
        <Box
          sx={{
            color,
            display: "flex",
            alignItems: "center",
            width: iconSize,
            justifyContent: "center",
            "& .progress_circle": {
              display: "flex",
              alignItems: "center",
            },
            "& > svg": {
              width: isChild ? "14px" : iconSize,
              height: isChild ? "14px" : iconSize,
            },
          }}
        >
          {config[value]}
        </Box>
      ) : null}

      <Box
        component="span"
        sx={{
          color,
          fontSize: "13px",
          lineHeight: "16px",
          fontWeight: "normal",
          marginLeft: "8px",
        }}
      >
        {JOB_STATUS_LABELS[value] || value}
      </Box>
    </Box>
  );
}

import { useDropzone } from "react-dropzone";
import { ACCEPTED_FILES } from "utils/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert, Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";

const useStyles = ({ theme, ...props }) => ({
  root: {},
  dropBox: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: props.boxActive ? "#25ACF7" : "#504C83",
    borderSpacing: "3px",
    borderRadius: "4px",
    width: "100%",
    height: "180px",
  },
  dragInactiveParent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dropHere: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadText: {
    marginTop: theme.spacing(1),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
});

const WRONG_FILE_TYPE_MSG = "Please upload a .csv file";

const FileUploader = ({ onDrop }) => {
  const [error, setError] = useState(null);

  const onDropAccepted = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      setError(null);
      onDrop(acceptedFiles);
    }
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length) {
      setError(WRONG_FILE_TYPE_MSG);
      setTimeout(() => setError(null), 3000);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "text/csv": ACCEPTED_FILES,
    },
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  const theme = useTheme();
  const styles = useStyles({ theme, boxActive: isDragActive });

  return (
    <Box sx={styles.root}>
      {!!error && (
        <>
          <Alert variant="filled" severity={"error"}>
            {error}
          </Alert>
          <br></br>
          <br></br>
        </>
      )}

      <Box {...getRootProps()} sx={styles.dropBox}>
        <input {...getInputProps()} />
        <DropItHere isDragActive={isDragActive} />
      </Box>
    </Box>
  );
};

function DropItHere({ isDragActive }) {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return isDragActive ? (
    <Box sx={styles.dropHere}>
      <Typography variant="body1" sx={styles.uploadText}>
        Drop here
      </Typography>
    </Box>
  ) : (
    <Box sx={styles.dragInactiveParent}>
      <Box>
        <CloudUploadIcon />
      </Box>
      <Typography variant="subtitle2" sx={styles.uploadText}>
        Drag and drop .csv file here
      </Typography>
      <Box>
        <br></br>
        <Button data-testid="browse-button" variant="contained" color="primary">
          Or, browse files
        </Button>
      </Box>
    </Box>
  );
}

export default FileUploader;

import { Box, InputLabel } from "@mui/material";
import { useAppContext } from "context/Context";
import { setUserSettings } from "api/endpoints/userApi";
import { ACTIONS } from "context/reducer";
import CustomDropdown from "components/UI/CustomDropdown";
import useEventTracker from "api/hooks/useEventTracker";
import useMessage from "hooks/useMessage";
import { getTimeZoneObjectByOffset, timeZoneList } from "utils/format";

export default function TimeZoneTab() {
  const tracker = useEventTracker();
  const showMessage = useMessage();

  const {
    dispatch,
    state: { timezoneOffset },
  } = useAppContext();

  const handleChange = async (event) => {
    const selectedOffset = event.target.value;
    const timezone = getTimeZoneObjectByOffset(selectedOffset);

    try {
      await setUserSettings({ timezone });

      dispatch({
        type: ACTIONS.SET_TIMEZONE,
        payload: {
          Offset: event.target.value,
          Name: timezone.Name,
          Description: timezone.Description,
        },
      });

      tracker.track(
        "Saved API key",
        "change",
        "",
        "Top bar / account menu / timezone"
      );

      showMessage("Timezone updated", "success");
    } catch {
      showMessage("Error updating Timezone", "error");
    }
  };

  return (
    <Box sx={{ my: 1.5 }}>
      <InputLabel
        id="timezone-select-label"
        sx={{
          color: "#AFAFAF",
          fontSize: "15px",
          fontWeight: "400",
        }}
        shrink={true}
      >
        Timezone
      </InputLabel>

      <CustomDropdown
        id="timezone-select"
        placeholder={"- Select Timezone -"}
        searchEnabled
        value={timezoneOffset}
        onChange={handleChange}
        title="Select timezone"
        options={timeZoneList}
        dataTestId="timezone-select"
      />
    </Box>
  );
}

import { ServerError } from "@apollo/client";
import { handleErrorByCode } from "api/errors/errorDisplay";
import axios, { AxiosError } from "axios";

// This file contains utilities for sending errors.
// This is inspired by the Google REST API guidelines @ https://cloud.google.com/apis/design/errors

export enum ErrorCode {
  CANCELLED = "CANCELLED",
  UNKNOWN = "UNKNOWN",
  INVALID_ARGUMENT = "INVALID_ARGUMENT",
  DEADLINE_EXCEEDED = "DEADLINE_EXCEEDED",
  NOT_FOUND = "NOT_FOUND",
  ALREADY_EXISTS = "ALREADY_EXISTS",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  RESOURCE_EXHAUSTED = "RESOURCE_EXHAUSTED",
  FAILED_PRECONDITION = "FAILED_PRECONDITION",
  ABORTED = "ABORTED",
  OUT_OF_RANGE = "OUT_OF_RANGE",
  UNIMPLEMENTED = "UNIMPLEMENTED",
  INTERNAL = "INTERNAL",
  UNAVAILABLE = "UNAVAILABLE",
  DATA_LOSS = "DATA_LOSS",
}

const NETWORK_ERROR = "Network Error";

export const httpResponseCodeMap: Record<ErrorCode, number> = {
  [ErrorCode.CANCELLED]: 499,
  [ErrorCode.UNKNOWN]: 500,
  [ErrorCode.INVALID_ARGUMENT]: 400,
  [ErrorCode.DEADLINE_EXCEEDED]: 504,
  [ErrorCode.NOT_FOUND]: 404,
  [ErrorCode.ALREADY_EXISTS]: 409,
  [ErrorCode.PERMISSION_DENIED]: 403,
  [ErrorCode.UNAUTHENTICATED]: 401,
  [ErrorCode.RESOURCE_EXHAUSTED]: 429,
  [ErrorCode.FAILED_PRECONDITION]: 400,
  [ErrorCode.ABORTED]: 409,
  [ErrorCode.OUT_OF_RANGE]: 400,
  [ErrorCode.UNIMPLEMENTED]: 501,
  [ErrorCode.INTERNAL]: 500,
  [ErrorCode.UNAVAILABLE]: 503,
  [ErrorCode.DATA_LOSS]: 500,
};

export class PigeonError {
  code: ErrorCode;
  message: string;

  constructor(code: ErrorCode, message: string) {
    this.code = code;
    this.message = message;
  }
}

const getErrorCodeByStatus = (status: number) => {
  const code: ErrorCode | undefined = (
    Object.keys(httpResponseCodeMap) as Array<ErrorCode>
  ).find((key) => httpResponseCodeMap[key] === status);

  return code || ErrorCode.UNKNOWN;
};

function isAxiosError(err: any): err is AxiosError {
  return axios.isAxiosError(err);
}

function isServerError(err: any): err is ServerError {
  return err.result && err.response && err.statusCode;
}

export const handleError = (err: Error | AxiosError | ServerError) => {
  if (isAxiosError(err) || isServerError(err)) {
    let errorData;

    if (isAxiosError(err)) {
      errorData = err.response?.data;
    } else if (isServerError(err)) {
      errorData = err.result;
    }
    
    if (errorData && errorData.success !== undefined) {
      const pigeonError = errorData.error as PigeonError;
      handleErrorByCode(pigeonError.code, pigeonError.message);
    } else if (errorData && errorData.error) {
      const errorCode: ErrorCode = getErrorCodeByStatus(errorData.error.status);
      handleErrorByCode(errorCode, errorData.error.message);
    } else if (err.message === NETWORK_ERROR) {
      handleErrorByCode(ErrorCode.UNAVAILABLE, NETWORK_ERROR);
    }
  } else {
    // Handle Native Error
  }
};

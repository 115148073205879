import { Box, Alert, Divider } from "@mui/material";
import { JOB_ERRORS_BY_CODE } from "utils/constants";

export default function JobError({ job }) {
  const errorCode = job?.exportResults?.error_code;
  const message =
    job?.exportResults?.message || "Something unexpected happened";

  return (
    <Box>
      <Box
        sx={{
          py: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          icon={false}
          sx={{ flexGrow: 1, mr: "10px" }}
        >
          {errorCode
            ? `${errorCode} Error: ${JOB_ERRORS_BY_CODE[errorCode] || message}`
            : message}
        </Alert>
      </Box>
      <Divider />
    </Box>
  );
}

import { useEffect, useRef } from "react";
import { getZendeskToken } from "api/endpoints/AnalyticsApi";
import LoginBackground from "./LoginBackground";
import useQueryParams from "api/hooks/useQueryParams";

const ZENDESK_URL = process.env.REACT_APP_ZENDESK_URL;

function ZendeskRedirectPage() {
  const { target_url } = useQueryParams()
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const zendeskRedirect = async () => {
      try {
        // Gets the Zendesk token from the pigeon API
        const jwt = await getZendeskToken();

        // add the JWT to a hidden form on your login page
        inputRef.current.value = jwt

        // Submits form with token for redirect
        // https://github.com/zendesk/zendesk_jwt_sso_examples/blob/master/form_submission/react_jwt.jsx
        formRef.current.submit()

        if (target_url) {
          window.location.replace(`${target_url}`);
        }
      } catch (err) {
        alert("Your account does not have access to Zendesk support");
      }
    };
    zendeskRedirect();
  }, []);

  return (
  <LoginBackground title="Redirecting to Zendesk...">
    <form ref={formRef} method="post" action={`${ZENDESK_URL}/access/jwt`}>
      <input ref={inputRef} type="hidden" name="jwt"></input>
    </form>
  </LoginBackground>);
}

export default ZendeskRedirectPage;

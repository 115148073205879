import { uploadFilePartToS3 } from "api/endpoints/UploadApi";

// Finds the filename and extension of a file
export const getFileNameAndExtension = (file) => {
    const fileChunks = file?.name?.split(".");
    const name = fileChunks?.shift();
    const extension = fileChunks?.pop();
    return { name, extension };
}

export const handleUploadSignedUrlFileParts = async (file, index, onUploadProgress, documentsToUpload, originalFileNamesMap) => {
    
    file.parts = [];
            
    // UPLOAD EACH PART of the multipart upload for this file
    // Ensure that presignedUrls is an array and has at least one URL
    if (Array.isArray(file.presignedUrls) && file.presignedUrls.length > 0) {
        for (let partNumber = 1; partNumber <= file.presignedUrls.length; partNumber++) {
            // Find the original file data that matches the file metadata from the server
            var binary = documentsToUpload.find((element) => element.name === file.fileName);
            onUploadProgress({loaded: partNumber, total: file.presignedUrls.length}, file.fileName, originalFileNamesMap);

            // Upload the file to S3 in order
            const eTag = await uploadFilePartToS3(file, partNumber, binary, documentsToUpload[index].type);
            if (eTag) {
                file.parts.push({'partNumber': partNumber, 'etag': eTag });
            } else {
                throw new Error(`Error uploading a part of ${documentsToUpload[index].name}. Please try again.`);
            }
        }

        return file;
        
    }
    return null;

}
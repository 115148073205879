import { Box, Button, Typography } from "@mui/material";
import { history } from "index";

const logout = () => {
  localStorage.clear();
  history.push("/login");
};

export default function ProjectsNotFound() {
  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <Typography variant={"h6"} gutterBottom>
        Projects not found. Try a different account or contact administrator.
      </Typography>
      <Button data-testid="login-button" onClick={logout}>Login</Button>
    </Box>
  );
}

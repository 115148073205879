import {
  Button,
  Typography,
  Drawer,
  IconButton,
  Divider,
  Box,
  useTheme,
} from "@mui/material";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useEffect, useState } from "react";
import InputValidate from "components/UI/InputValidate";
import TextAreaValidate from "components/UI/TextAreaValidate";
import FileUploader from "components/UI/FileUploader";
import {
  createUserGroup,
  deleteUserGroup,
  updateUserGroup,
} from "api/endpoints/AnalyticsApi";
import { replaceLineBreaks } from "utils/format";
import {
  USER_COLUMN_LABEL,
  AUTHORS_PER_GROUP_LIMIT,
  AUTHOR_GROUPS_LIMIT,
} from "utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as EditIcon } from "icons/UI/Edit.svg";
import { ReactComponent as DeleteIcon } from "icons/UI/Delete.svg";
import Preloader from "components/UI/Preloader";
import SimpleTable from "components/UI/SimpleTable";
import useMessage from "hooks/useMessage";

const WIDTH = "560px";

const useStyles = ({ theme }) => ({
  root: {},
  btn: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  drawerPaper: {
    width: WIDTH,
  },
  header: {
    fontSize: "16px",
    fontWeight: "700",
    color: "white",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 20px 20px 15px",
  },
  backButton: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "16px",
  },
  content: {
    padding: theme.spacing(2, 4),
  },
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
  btns: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "&>button": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    borderColor: "rgba(99, 96, 123, 0.2)",
  },
  nameAndButtons: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(2, 4),
  },
  or: {
    textAlign: "center",
    position: "relative",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: "#8F8F8F",
    "&::before": {
      content: '""',
      width: "calc(50% - 24px)",
      position: "absolute",
      top: "50%",
      left: "0%",
      height: "1px",
      background: "#282C38",
    },
    "&::after": {
      content: '""',
      width: "calc(50% - 24px)",
      position: "absolute",
      top: "50%",
      right: "0%",
      height: "1px",
      background: "#282C38",
    },
  },
});

const getUserTableColumns = ({ handleEdit, handleDelete }) => [
  {
    field: "name",
    text: "Group name",
    width: "99%",
  },
  {
    field: "userIds",
    text: "User count",
    align: "right",
    cellComponent: ({ value }) => value.length,
  },
  // {
  //   field: "date_created",
  //   text: "Date created",
  //   cellComponent: ({ value }) => formatDateObj(new Date(value)),
  // },
  {
    field: "*",
    text: "Actions",
    align: "right",
    cellComponent: ({ value }) => {
      return (
        <Box sx={{ display: "flex" }}>
          <IconButton onClick={() => handleEdit(value)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={() => handleDelete(value)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    },
  },
];

const getCsvText = (userGroup) => {
  if (!userGroup || !Array.isArray(userGroup.userIds)) {
    return "";
  }
  return userGroup.userIds.join(",");
};

export default function UsersGroupDrawer() {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const showMessage = useMessage();

  const {
    dispatch,
    state: { userGroups, userGroupEdit, userGroupDrawerOpen, project },
  } = useAppContext();

  const [name, setName] = useState(userGroupEdit.name || "");
  const [csvText, setCsvText] = useState(getCsvText(userGroupEdit));
  const [groupExists, setGroupExists] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [csvTextError, setCsvTextError] = useState(null);
  const [saving, setSaving] = useState(false);

  const tableRows = userGroups.map((d) => {
    return {
      ...d,
      disabled: userGroupEdit.id ? userGroupEdit.id !== d.id : false,
    };
  });

  useEffect(() => {
    setName(userGroupEdit.name || "");
    setCsvText(getCsvText(userGroupEdit));
    setGroupExists(false);
    setIsEmpty(false);
    setCsvTextError(null);
  }, [userGroupEdit]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCsvTextChange = (e) => {
    setCsvText(e.target.value);
  };

  const handleDrop = (files) => {
    const reader = new FileReader();
    reader.onload = function () {
      const text = reader.result;
      setCsvText(replaceLineBreaks(text || ""));
    };

    reader.readAsText(files[0]);
  };

  const handleClose = () => {
    dispatch({
      type: ACTIONS.SET_USER_GROUPS_DRAWER_OPEN,
      payload: false,
    });
  };

  const handleSave = async () => {
    const nameEmpty = name.trim() === "";
    const csvEmpty = csvText.trim() === "";
    const formattedText = replaceLineBreaks(csvText.trim());

    setCsvText(formattedText);
    setIsEmpty(nameEmpty);
    setCsvTextError(csvEmpty ? "Users list is empty." : null);

    if (userGroups.length >= AUTHOR_GROUPS_LIMIT) {
      return setCsvTextError(
        `You have reached the maximum of ${AUTHOR_GROUPS_LIMIT} ${USER_COLUMN_LABEL} Groups`
      );
    }

    if (nameEmpty || csvEmpty) return;

    const users = formattedText
      .split(",")
      .map((d) => d.trim())
      .filter((d) => d);

    if (users.length > AUTHORS_PER_GROUP_LIMIT) {
      return setCsvTextError(
        `You have exceeded the maximum of ${AUTHORS_PER_GROUP_LIMIT} authors in the group`
      );
    }

    const hasWhiteSpace = users.some((d) => d.includes(" "));

    if (hasWhiteSpace) {
      return setCsvTextError("Username should not contain whitespace.");
    }

    if (userGroupEdit.id) {
      const newUserGroup = {
        id: userGroupEdit.id,
        userIds: users,
      };

      if (userGroupEdit.name !== name) {
        newUserGroup.name = name;
      }

      try {
        setSaving(true);
        const updateRes = await updateUserGroup(newUserGroup);
        setSaving(false);
        if (updateRes) {
          dispatch({
            type: ACTIONS.UPDATE_USER_GROUP,
            payload: {
              ...newUserGroup,
              name,
            },
          });
          showMessage(`${USER_COLUMN_LABEL} Group updated`, "success");
          handleClose();
        }
      } catch {
        setSaving(false);
        showMessage(`Error updating ${USER_COLUMN_LABEL} Group`, "error");
      }
    } else {
      const exists = userGroups.some((d) => d.name === name);
      setGroupExists(exists);

      if (!exists) {
        try {
          setSaving(true);
          const res = await createUserGroup({
            name,
            userIds: users,
            projectID: project.id,
          });
          setSaving(false);

          if (res) {
            dispatch({
              type: ACTIONS.ADD_USER_GROUP,
              payload: res,
            });
            showMessage(`${USER_COLUMN_LABEL} Group created`, "success");
            handleClose();
          }
        } catch {
          setSaving(false);
          showMessage(`Error creating ${USER_COLUMN_LABEL} Group`, "error");
        }
      }
    }
  };

  const handleDelete = async (d) => {
    try {
      const res = await deleteUserGroup(d.id);
      if (res) {
        dispatch({
          type: ACTIONS.REMOVE_USER_GROUP,
          payload: d,
        });
        handleClose();
        showMessage(`${USER_COLUMN_LABEL} Group deleted`, "success");
      }
    } catch {
      showMessage(`Error deleting ${USER_COLUMN_LABEL} Group`, "error");
    }
  };

  const handleEdit = (d) => {
    dispatch({
      type: ACTIONS.EDIT_USER_GROUP,
      payload: d,
    });
  };

  const columns = getUserTableColumns({
    handleEdit,
    handleDelete,
  });

  return (
    <Box sx={styles.root}>
      <Drawer
        sx={{ ...styles.drawer, "& .MuiDrawer-paper": styles.drawerPaper }}
        anchor={"right"}
        open={userGroupDrawerOpen}
        onClose={handleClose}
      >
        <Box sx={styles.headerTitle}>
          <IconButton sx={styles.backButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography sx={styles.header}>{USER_COLUMN_LABEL} Groups</Typography>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.content}>
          <Typography variant="subtitle2" sx={{ pb: 0.5 }}>
            Create an {USER_COLUMN_LABEL} Group
          </Typography>
          <Box>
            <TextAreaValidate
              minRows={12}
              placeholder={`Enter or paste a list of up to ${AUTHORS_PER_GROUP_LIMIT} authors separated by a comma`}
              errorMsg={csvText.length ? "" : csvTextError}
              value={csvText}
              onChange={handleCsvTextChange}
            />
          </Box>
          <Box sx={styles.or}>or</Box>
          <Box>
            <FileUploader onDrop={handleDrop} />
          </Box>
        </Box>
        <Box sx={styles.nameAndButtons}>
          <Box sx={{ flexGrow: 1 }}>
            <InputValidate
              value={name}
              showError={isEmpty}
              onChange={handleNameChange}
              exists={groupExists}
              emptyWarning={`Name your ${USER_COLUMN_LABEL} Group before saving`}
              existsWarning={`${name} already exists.`}
              placeholder={`Name your ${USER_COLUMN_LABEL} Group`}
            />
          </Box>
          <Box sx={styles.btns}>
            <Button
              data-testid="users-group-save-button"
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSave}
              disabled={saving}
            >
              {saving ? (
                <Preloader size={12} thickness={6} color={"inherit"} />
              ) : (
                `Save ${USER_COLUMN_LABEL} Group`
              )}
            </Button>
          </Box>
        </Box>
        <Box sx={styles.content}>
          <Typography variant="subtitle2" sx={{ pb: 0.5 }}>
            Manage {USER_COLUMN_LABEL} Groups
          </Typography>
          <Box>
            <SimpleTable columns={columns} data={tableRows} />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Typography, IconButton, Alert, Box, useTheme } from "@mui/material";
import { useState } from "react";
import CustomDropdown from "components/UI/CustomDropdown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const useStyles = ({ theme }) => ({
  root: {},
  titleWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #424242",
  },
  title: {
    color: "#AFAFAF",
    fontSize: "15px",
    lineHeight: "17px",
  },
  slider: {
    marginBottom: "10px",
  },
  dropdown: {
    minWidth: "40%",
    marginRight: "15px",
    borderRadius: "4px",
    background: "#302E4F",
  },
  queryRoot: {
    padding: theme.spacing(2, 0),
  },
  queryRow: {
    borderLeft: "1px solid " + theme.palette.slider.main,
    padding: theme.spacing(1, 0, 1, 2),
  },
  borderNot: {
    borderLeft: "1px solid red",
  },
  dropdownAndSlider: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
  },
  addBtn: {
    width: "36px",
    minWidth: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
  },
  switchBtn: {
    borderColor: "#302E4F",
    fontWeight: "normal",
    marginLeft: "0px !important",
    "&:hover": {
      border: "1px solid #AFAFAF !important",
    },
  },
  switchBtnActive: {
    backgroundColor: "#302E4F",
  },
  gap: {
    paddingTop: theme.spacing(3),
  },
  andOr: {
    paddingLeft: "24px",
    position: "relative",
    marginBottom: theme.spacing(2),
    "&::before": {
      content: '""',
      width: "20px",
      position: "absolute",
      top: "50%",
      left: "0%",
      height: "1px",
      background: "#282C38",
    },
    "&::after": {
      content: '""',
      width: "calc(100% - 162px)",
      position: "absolute",
      top: "50%",
      right: "0%",
      height: "1px",
      background: "#282C38",
    },
  },
});

export default function QueryBuilder({
  title,
  value,
  onChange,
  showError,
  metricOptions,
  disabled = false,
}) {
  const theme = useTheme()
  const styles = useStyles({ theme });
  const [localValue, setLocalValue] = useState(value);

  const handleUpdate = (index, key, value) => {
    const datum = { ...localValue[index] };
    datum[key] = value;
    const newVal = [...localValue];
    newVal[index] = datum;
    setLocalValue(newVal);
    onChange(
      newVal,
      key === "field" ? "select" : null,
      metricOptions.find((d) => d.value === value)?.label
    );
  };

  const handleRemove = (index) => {
    const newVal = [...localValue];
    const oldVal = localValue[index];
    newVal.splice(index, 1);
    setLocalValue(newVal);
    onChange(
      newVal,
      "remove",
      metricOptions.find((d) => d.value === oldVal.field)?.label
    );
  };

  const handleAddNew = () => {
    const newVal = [...localValue];
    newVal.push({
      field: "",
      operator: "and",
      value: true,
      min: 0,
      max: 100,
    });
    setLocalValue(newVal);
    onChange(newVal);
  };

  const handleInsert = (index) => {
    const newVal = [...localValue];
    newVal.splice(index + 1, 0, {
      field: "",
      operator: "and",
      value: true,
      min: 0,
      max: 100,
    });
    setLocalValue(newVal);
    onChange(newVal);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleWrap}>
        <Typography sx={styles.title}>{title}</Typography>
        <IconButton onClick={handleAddNew} disabled={disabled}>
          <AddCircleIcon sx={{ color: "#909090" }} />
        </IconButton>
      </Box>
      <Box sx={styles.queryRoot}>
        {showError && localValue.length === 0 && (
          <Alert severity="error" size="small">
            Fill the condition
          </Alert>
        )}
        {localValue.map((d, i) => (
          <>
            {i > 0 && (
              <Box
                key={i + "gap"}
                sx={
                  d.operator === "or" || d.operator === "not" ? styles.gap : null
                }
              ></Box>
            )}
            <Box
              key={i}
              sx={{
                ...styles.queryRow,
                ...(d.operator === "not" ? styles.borderNot : {})
              }}
            >
              {i > 0 && (
                <Box sx={styles.andOr}>
                  <ButtonGroup variant="outlined" color="switchBtn">
                    <Button
                      data-testid="and-button"
                      disabled={disabled}
                      sx={{
                        ...styles.switchBtn,
                        ...(d.operator === "and" ? styles.switchBtnActive : {})
                      }}
                      size="small"
                      onClick={() => handleUpdate(i, "operator", "and")}
                    >
                      AND
                    </Button>
                    <Button
                      data-testid="or-button"
                      disabled={disabled}
                      sx={{
                        ...styles.switchBtn,
                        ...(d.operator === "or" ? styles.switchBtnActive : {})
                      }}
                      size="small"
                      onClick={() => handleUpdate(i, "operator", "or")}
                    >
                      OR
                    </Button>
                    <Button
                      data-testid="not-button"
                      disabled={disabled}
                      sx={{
                        ...styles.switchBtn,
                        ...(d.operator === "not" ? styles.switchBtnActive : {})
                      }}
                      size="small"
                      onClick={() => handleUpdate(i, "operator", "not")}
                    >
                      NOT
                    </Button>
                  </ButtonGroup>
                </Box>
              )}
              <Box sx={styles.dropdownAndSlider}>
                <Box sx={styles.dropdown}>
                  <CustomDropdown
                    searchEnabled
                    transparent
                    disabled={disabled}
                    placeholder={"Select a metric"}
                    options={metricOptions}
                    value={d.field}
                    onChange={(e) => handleUpdate(i, "field", e.target.value)}
                  />
                </Box>
                <Box sx={styles.removeBtn}>
                  <IconButton
                    onClick={() => handleRemove(i)}
                    disabled={disabled}
                  >
                    <RemoveCircleIcon sx={{ color: "#909090" }} />
                  </IconButton>
                </Box>
                <Box sx={styles.addBtn}>
                  {(i === localValue.length - 1 ||
                    localValue[i + 1]?.operator === "or" ||
                    localValue[i + 1]?.operator === "not" ||
                    (d.operator === "or" &&
                      localValue[i + 1]?.operator !== "and")) && (
                    <IconButton
                      onClick={() => handleInsert(i)}
                      disabled={disabled}
                    >
                      <AddCircleIcon sx={{ color: "#909090" }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
}

import axios from "axios";

const escapeJSON = (str) => {
  return str
    .replace(/\\n/g, "\\n")
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, "\\&")
    .replace(/\\r/g, "\\r")
    .replace(/\\t/g, "\\t")
    .replace(/\\b/g, "\\b")
    .replace(/\\f/g, "\\f")
    .replace(/\bNaN\b/g, "null");
};

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const getCancelToken = () => axios.CancelToken.source();

export const escape = (resp) => {
  if (typeof resp === "string") {
    let arr = [];

    try {
      arr = JSON.parse(escapeJSON(resp));
    } catch (error) {
      console.error(resp, error.message);
    }

    return arr;
  }

  return resp;
};

export const parseStrapiRes = (res) => {
  if (!res) {
    return res;
  }

  if (res.data) {
    return parseStrapiRes(res.data);
  }

  if (Array.isArray(res)) {
    return res.map((d) => {
      return parseStrapiRes(d);
    });
  }

  if (typeof res === "object" && res !== null) {
    if (res.id && res.attributes) {
      const id = res.id;
      return { id, ...parseStrapiRes(res.attributes) };
    } else {
      for (const attrKey of Object.keys(res)) {
        res[attrKey] = parseStrapiRes(res[attrKey]);
      }
      return res;
    }
  }

  return res;
};

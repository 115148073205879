import {
  STAGE_DOCS,
  SET_DOCS,
  ADD_DOCS,
  SET_SELECTED_FILES,
} from "../utils/constants";

export const addNewDocuments = function (payload) {
  return {
    type: ADD_DOCS,
    payload,
  };
};

export const setNewDocuments = function (payload) {
  return {
    type: SET_DOCS,
    payload,
  };
};

export const stageDocuments = function (payload) {
  return {
    type: STAGE_DOCS,
    payload,
  };
};

export const setSelectedFiles = function (payload) {
  return {
    type: SET_SELECTED_FILES,
    payload,
  };
};

import React, { useEffect, useMemo, useState } from 'react'
import { FormHelperText, TextField, Box, useTheme } from '@mui/material'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import { ReactComponent as LineIcon } from "icons/UI/dash.svg";
import moment from 'moment'
import 'react-dates/initialize'

const useStyles = ({ theme }: { theme: any }) => ({
	timeInput: {
		ml: theme.spacing(1),
		width: '100px',
		'& .MuiInputBase-root': {
			background: theme.palette.background.$0,
			borderRadius: '0px !important',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '18px',
			border: 'none'
		},
		'& fieldset': {
			borderRadius: 0,
			borderColor: 'transparent !important'
		},
		'& input::-webkit-calendar-picker-indicator': {
			color: "#ffffff",
			background: 'none',
			display: 'none'
		}
	},
	timePickerRow: {
		width: "100%",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: '5px',
		marginBottom: '10px'
	},
	timePickerRoot: {
		display: 'flex',
		alignItems: 'center'
	},
	errorBorder: {
		border: `1px solid ${theme.palette.error.main}`
	}
})

export interface DateTimePickerProps {
	dateFrom: string | null
	dateTo: string | null
	minDate?: string | null
	maxDate?: string | null
	datePickerProps?: object
	disabled: boolean
	error?: string | boolean
	helperText: string

	onChange: (dateFrom: string | null, dateTo: string | null) => void
}

export const getDateInLocal = (date: string) => {
  return moment(date?.replace("Z", ""))
};

export const formatDate = (date: moment.Moment) => {
	return date.format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export default function DateTimePicker({
	dateFrom,
	dateTo,
	onChange,
	datePickerProps = {},
	disabled,
	error,
	helperText,
	minDate,
	maxDate
}: DateTimePickerProps) {
	const theme = useTheme()
	const styles = useStyles({ theme })

	const minDateMoment: moment.Moment | undefined = useMemo(
		() => (minDate ? getDateInLocal(minDate) : undefined),
		[minDate]
	)
	const maxDateMoment: moment.Moment | undefined = useMemo(
		() => (maxDate ? getDateInLocal(maxDate) : undefined),
		[maxDate]
	)

	const [startDate, setStartDate] = useState<moment.Moment | null>(null)
	const [endDate, setEndDate] = useState<moment.Moment | null>(null)
	const [startTime, setStartTime] = useState<string>('00:00')
	const [endTime, setEndTime] = useState<string>('23:59')
	const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
		null
	)
		
	const isBeforeMinDate = (date: moment.Moment) => {
		if (!minDateMoment) return false
		return date.endOf('day').isBefore(minDateMoment)
	}

	const isAfterMaxDate = (date: moment.Moment) => {
		if (!maxDateMoment) return false
		return date.startOf('day').isAfter(maxDateMoment)
	}

	useEffect(() => {
		const startDateMoment = dateFrom ? getDateInLocal(dateFrom) : null
		const endDateMoment = dateTo ? getDateInLocal(dateTo) : null

		setStartDate(startDateMoment)
		setEndDate(endDateMoment)

		const startTime = startDateMoment
			? startDateMoment.format('HH:mm')
			: '00:00'
		
		const endTime = endDateMoment
			? endDateMoment.format('HH:mm')
			: moment().format('HH:mm')

		setStartTime(startTime)
		setEndTime(endTime)
	}, [dateFrom, dateTo])

	const handleChange = (
		startDate: moment.Moment | null,
		endDate: moment.Moment | null,
		startTime: string,
		endTime: string
	) => {
		let start = null
		let end = null

		if (startDate && startTime) {
			const [hour, minute] = startTime.split(':').map(d => +d)
			const newStartDate = startDate.clone().set({
				hour: hour,
				minute: minute,
				second: 0,
				millisecond: 0
			})
				
			start = formatDate(newStartDate)
		}

		if (endDate && endTime) {
			const [hour, minute] = endTime.split(':').map(d => +d)
			const newEndDate = endDate.clone().set({
				hour: hour,
				minute: minute,
				second: 0,
				millisecond: 0
			})
			
			end = formatDate(newEndDate)
		}

		onChange(start, end)
	}

	const handleDatesChange = ({
		startDate,
		endDate
	}: {
		startDate: moment.Moment | null
		endDate: moment.Moment | null
	}) => {
		setStartDate(startDate)
		setEndDate(endDate)
		handleChange(startDate, endDate, startTime, endTime)
	}

	const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const time = e.target.value
		setStartTime(time)
		handleChange(startDate, endDate, time, endTime)
	}

	const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const time = e.target.value
		setEndTime(time)
		handleChange(startDate, endDate, startTime, time)
	}

	const invalid = error && (!startDate || !endDate)

	return (
		<Box data-testid="date_time_picker">
			<Box sx={{
				width: "100%"
			}}>
				<DateRangePicker
					{...datePickerProps}
					disabled={disabled}
					startDate={startDate}
					startDateId='_start_date_id'
					endDate={endDate}
					endDateId='_end_date_id'
					onDatesChange={handleDatesChange}
					focusedInput={focusedInput}
					onFocusChange={(arg: FocusedInputShape | null) =>
						setFocusedInput(arg)
					}
					noBorder={true}
					customArrowIcon={<LineIcon />}
					verticalSpacing={1}
					minimumNights={0} // Allow same day selection
					minDate={minDateMoment}
					maxDate={maxDateMoment}
					isOutsideRange={day => {
						return isBeforeMinDate(day) || isAfterMaxDate(day)
					}}
					startDatePlaceholderText='Start date'
					endDatePlaceholderText='End date'
				/>
			</Box>

			{invalid && (
				<FormHelperText variant='outlined' error={invalid}>
					{helperText}
				</FormHelperText>
			)}

			<Box sx={styles.timePickerRow}>
				<Box sx={styles.timePickerRoot}>
					<Box
						component='i'
						className='icon-arrowturnright'
						fontSize='1.25rem'
					/>
					<TextField
						sx={styles.timeInput}
						type={'time'}
            className='time_input'
						value={startTime}
						onChange={handleStartTimeChange}
						size='small'
						id="_start_time_id"
						data-testid="start_time"
						disabled={disabled}
					/>
				</Box>

				<Box sx={styles.timePickerRoot}>
					<Box
						component='i'
						className='icon-arrowturnright'
						fontSize='1.25rem'
					/>
					<TextField
						sx={styles.timeInput}
            className='time_input'
						type={'time'}
						value={endTime}
						onChange={handleEndTimeChange}
						size='small'
						id="_end_time_id"
						data-testid="end_time"
						disabled={disabled}
					/>
				</Box>
			</Box>
		</Box>
	)
}
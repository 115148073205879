import React, { useEffect, useMemo, useState } from "react";
import WidgetHeader from "components/WidgetHeader";
import Tabs from "components/UI/TabsNew";
import DataDisplay from "./DataDisplay";
import { PLATFORMS, QUERY_KEYS, ROUTE_PATHES } from "utils/constants";
import { useAppContext } from "context/Context";
import useEventTracker from "api/hooks/useEventTracker";
import { mergeQueries } from "pages/DashboardPage/EntityExplorer/utils/EntityExplorerUtils";
import { useParams } from "react-router-dom";
import {
  defaultReturnFields,
  emotions,
  sentiments,
} from "utils/summaryReturnFields";
import useApi from "api/hooks/useApi";
import { loadSummary } from "api/endpoints/AnalyticsApi";
import useTabs from "hooks/useTabs";
import WidgetBody from "components/WidgetBody";
import { Box, useTheme } from "@mui/material";
import { isNeutralEmotion } from "../Summary/summaryConfig";
import { getSummaryDataWithPlatform } from "utils/summary";
const useStyles = ({ theme }) => ({
  root: {
    backgroundColor: theme.palette.background.$0,
    border: "1px solid " + theme.palette.border.main,
  },
  header: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  content: {
    padding: "12px 12px 24px 12px",
  },
  loading: {
    padding: "10px 0px",
    fontSize: "14px",
    minHeight: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noResults: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tabs: {
    marginTop: "24px",
  },
});

const emotionSums = [
  "anger_ct",
  "disgust_ct",
  "fear_ct",
  "hapiness_ct",
  "neutral_emotion_ct",
  "sadness_ct",
  "surprise_ct",
];

const emotionSumMappings = {
  anger_ct: "anger",
  disgust_ct: "disgust",
  fear_ct: "fear",
  hapiness_ct: "happiness",
  neutral_emotion_ct: "neutral",
  sadness_ct: "sadness",
  surprise_ct: "surprise",
  positive_ct: "positive",
  negative_ct: "negative",
  neutral_ct: "neutral",
};

export const pieChartColorMappings = {
  anger: "#F33939",
  disgust: "#c87606",
  fear: "#9639F3",
  happiness: "#39F3C5",
  joy: "#39F3C5",
  neutral: "#C5D5FF",
  sadness: "#438EFF",
  surprise: "#EFF339",
  positive: "#50C878",
  negative: "#F33939",
};

export default function Insight({ onMoreClick }) {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const { projectName } = useParams();
  const { currentPath } = useTabs();
  const tracker = useEventTracker();

  const [tabs, setTabs] = useState([
    {
      name: "Emotion",
      active: true,
    },
    {
      name: "Sentiment",
      active: false,
    },
  ]);
  const [emotion, setEmotion] = useState([]);
  const [sentiment, setSentiment] = useState([]);
  const [isParsing, setIsParsing] = useState(true);
  const [shouldReanimate, setShouldReanimate] = useState(0);

  const {
    state: {
      platform,
      narrative,
      start_date,
      end_date,
      harmProfile,
      harmClassifier,
      analyzeSearch,
      dataLoading,
    },
  } = useAppContext();

  const summaryDataMemo = useMemo(() => {
    const analyzeFilters = narrative.analyze_filters?.serialized || {};

    const filters = mergeQueries(analyzeSearch, analyzeFilters);

    return {
      db: projectName,
      req: {
        start_date,
        end_date,
        platform,
        latestKeywords: narrative?.keywords,
        ...filters,
        topic: narrative?.name,
        harmProfile,
        harmClassifier,
        returnFields: [...defaultReturnFields, ...emotions, ...sentiments],
      },
    };
  }, [
    analyzeSearch,
    start_date,
    end_date,
    platform,
    narrative.name,
    projectName,
    narrative.keywords,
    harmProfile,
    harmClassifier,
    narrative.analyze_filters?.serialized,
  ]);

  const { data: summary, error, isLoading } = useApi({
    apiKey: QUERY_KEYS.summary,
    payload: summaryDataMemo,
    apiFn: loadSummary,
    enabled: Boolean(currentPath === ROUTE_PATHES.overview && !dataLoading),
  });

  const summaryData = useMemo(() => {
    return getSummaryDataWithPlatform(summary, platform);
  }, [summary, platform]);

  useEffect(() => {
    setIsParsing(true);
    if (!summaryData || summaryData.hapiness_ct === undefined) {
      setEmotion([]);
      setSentiment([]);
      setIsParsing(false);
      return;
    } else {
      const { post_ct } = summaryData;
      const emotions = Object.entries(summaryData);
      const emotionData = emotions
        .filter(([key]) => emotionSums.includes(key))
        .map(([key, value]) => ({
          color: pieChartColorMappings[emotionSumMappings[key]],
          name:
            emotionSumMappings[key].charAt(0).toUpperCase() +
            emotionSumMappings[key].slice(1),
          value: value / post_ct,
          sort_bot: isNeutralEmotion(key),
        }));
      setEmotion(emotionData);
      setSentiment([
        {
          color: pieChartColorMappings.positive,
          name: "Positive",
          value: summaryData.positive_ct / post_ct,
        },
        {
          color: pieChartColorMappings.negative,
          name: "Negative",
          value: summaryData.negative_ct / post_ct,
        },
        {
          color: pieChartColorMappings.neutral,
          name: "Neutral",
          value: summaryData.neutral_ct / post_ct,
          sort_bot: true,
        },
      ]);
      setShouldReanimate((prev) => prev + 1);
    }
    setIsParsing(false);
  }, [summaryData]);

  const tabIndex = tabs.findIndex((t) => t.active);

  const data = tabIndex === 0 ? emotion : sentiment;
  const setData = tabIndex === 0 ? setEmotion : setSentiment;

  const handleTabChange = (_, index) => {
    const newTabs = [...tabs].map((t) => ({ ...t, active: false }));
    newTabs[index].active = true;
    setTabs(newTabs);

    const tabName = newTabs[index]?.name;
    tracker.track(
      `Clicked tab in "Blackbird Insights"`,
      "click",
      tabName,
      "Overview / Insights"
    );
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <WidgetHeader
          noPadding
          title="Blackbird Insights"
          tooltipTitle="Aggregate emotion and sentiment analytics."
          onMoreClick={onMoreClick}
          noButton={platform === PLATFORMS.reddit}
        />
      </Box>

      <WidgetBody loading={isParsing || isLoading} error={error}>
        <Box sx={styles.tabs}>
          <Tabs white value={tabIndex} onChange={handleTabChange} tabs={tabs} />
        </Box>

        {data && data.length > 0 ? (
          <DataDisplay
            data={data}
            setData={setData}
            tab={tabs[tabIndex].name}
            shouldReanimate={shouldReanimate}
          />
        ) : null}
      </WidgetBody>
    </Box>
  );
}

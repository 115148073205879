import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = () => ({
  stepper: {
    "& .MuiStepConnector-root": {
      top: "21px !important",
    },
    "& .MuiStepConnector-line": {
      borderTopStyle: "dashed",
      borderTopColor: "#6F7494",
    },
  },
  stepButton: {
    "& .MuiStepLabel-iconContainer": {
      border: "5px solid #272438",
      borderRadius: "50%",
    },
    "& .MuiStepLabel-label": {
      color: "#6F7494",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: "#fff",
    },
    "& .MuiStepLabel-label.Mui-completed": {
      color: "#fff",
    },
    "& .MuiStepLabel-iconContainer.Mui-completed": {
      backgroundColor: "#1C192B",
      borderRadius: "50%",
      width: "32px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiSvgIcon-root": {
      color: "#1C192B",
      width: "32px",
      height: "32px",
    },
    "& .MuiSvgIcon-root.Mui-active": {
      color: "#6257C0",
    },
    "& .MuiSvgIcon-root.Mui-completed": {
      color: "#6257C0",
      "&.MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
      },
    },
  },
});

const steps = ["Data source", "Import data", "Configure project"];

const StepperNav = ({ activeStep, completed }) => {
  const styles = useStyles();

  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={styles.stepper}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton
            disabled={false}
            icon={
              completed[index] ? (
                <CheckIcon fontSize="small" className="Mui-completed" />
              ) : null
            }
            color="inherit"
            sx={styles.stepButton}
          >
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperNav;

import { Tabs, Tab, useTheme } from "@mui/material";

const useStyles = ({theme, ...props}) => ({
  root: {
    backgroundColor: props.tabColor || theme.palette.background.$0,
    borderBottom: "1px solid #282C38",
    padding: props.rootPadding || "0px 15px",
    minHeight: "38px",
    overflow: "initial !important",
  },
  indicator: {
    backgroundColor: "transparent",
    display: "none",
  },
  scroller: {
    overflow: "initial !important",
  },
  muiTab: {
    fontWeight: "400",
    color: "#6F7494 !important",
    padding: "10px",
    flexDirection: "row",
    backgroundColor: props.tabColor || theme.palette.background.$0,
    margin: 0,
    flex: "1 1 auto",
    transform: "translateY(1px)",
    border: "1px solid transparent",
    minHeight: "38px !important",
    fontSize: "14px",
    lineHeight: "18px",
    borderBottom: "1px solid #282C38",
    borderLeft: "none",
    borderRight: "none",
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row",
      flexFlow: "row",
      alignItems: "center",
    },
    "& .MuiTab-iconWrapper": {
      marginBottom: 0,
      marginRight: "10px",
    },
    "& .MuiTab-wrapper svg": {
      marginBottom: "0 !important",
    },
    "& .MuiSvgIcon-root": {
      marginBottom: 0,
      marginRight: "5px",
    },
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  selected: {
    color: "#ffffff !important",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#282C38 #282C38 transparent #282C38"
  },
  disabled: {
    color: "rgba(255, 255, 255, 0.2) !important",
  },
});

function a11yProps(index) {
  return {
    id: `keyEntity-tab-${index}`,
    "aria-controls": `keyEntity-tabpanel-${index}`,
  };
}

export default function CustomTabs(props) {
  const theme = useTheme()
  const styles = useStyles({
    theme,
    tabColor: props.tabColor,
    rootPadding: props.rootPadding,
  });

  return (
    <Tabs
      value={props.value}
      onChange={props.onChange}
      textColor={"primary"}
      variant={"fullWidth"}
      indicatorColor={"primary"}
      sx={{
        ...styles.root,
        "& .MuiTabs-indicator": styles.indicator,
        "& .MuiTabs-scroller": styles.scroller
      }}
    >
      {props.tabs.map((tab, index) => {
        const Icon = tab.icon;
        return (
          <Tab
            data-testid={`tab-${tab.name.replace(/\s/g, "-").toLowerCase()}`}
            icon={Icon ? <Icon /> : null}
            label={tab.name}
            key={tab.name}
            disabled={tab.disabled === true}
            sx={{
              ...styles.muiTab,
              "&.Mui-disabled": styles.disabled,
              "&.Mui-selected": styles.selected
            }}
            {...a11yProps(index)}
          />
        );
      })}
    </Tabs>
  );
}

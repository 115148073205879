import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Drawer,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
  Slider,
  Box,
  useTheme,
} from "@mui/material";
import InputValidate from "components/UI/InputValidate";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import CloseIcon from "@mui/icons-material/Close";
import CustomTabs from "components/UI/TabsNew";
import { getRandomId, refineDisplayName } from "utils/format";
import {
  createClassifierProfile,
  getChildKeys,
  setClassifierProfileAPI,
  updateClassifierProfile,
} from "api/endpoints/AnalyticsApi";
import { LABELS_MAP, QUERY_KEYS } from "utils/constants";
import useApi from "api/hooks/useApi";
import { useParams } from "react-router-dom";
import useMessage from "hooks/useMessage";

const WIDTH = "500px";
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const useStyles = ({theme}) => ({
  root: {},
  btn: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 999999,
    flexShrink: 0,
    width: WIDTH,
    height: "100vh",
  },
  header: {
    fontSize: "16px",
    fontWeight: "700",
    color: "white",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 20px 20px 15px",
  },
  backButton: {
    padding: 0,
    width: "34px",
    height: "34px",
    marginRight: "16px",
  },
  alert: {
    padding: theme.spacing(2, 4, 4, 2),
  },
  content: {
    padding: theme.spacing(2, 4),
    flexGrow: 1,
    overflowY: "auto",
  },
  queryBuilder: {},
  closeBtn: {
    backgroundColor: "#2C293A",
    color: "rgba(195, 194, 211, 0.8)",
    "&:hover": {
      backgroundColor: "#2C293A",
    },
  },
  btns: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: "17px",
    "&>button": {
      minWidth: "25%",
    },
  },
  divider: {
    borderColor: "rgba(99, 96, 123, 0.2)",
  },
  nameAndButtons: {
    padding: theme.spacing(2, 4),
  },
  previewSnack: {
    background: "#272438",
    borderWidth: "1px 1px 1px 6px",
    borderStyle: "solid",
    borderColor: "#3A364D #3A364D #3A364D #81DFA3",
    padding: "30px",
    borderRadius: "6px",
    minWidth: "550px",
  },
  preview: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formLabel: { fontSize: "14px", fontWeight: "normal" },
  sliderRoot: {
    padding: theme.spacing(1, 3),
  },
  slider: {
    "& .MuiSlider-markLabel": {
      color: "#AFAFAF",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "normal",
      paddingTop: theme.spacing(1),
    }
  },
  sliderWrap: {
    width: "100%",
    "& .MuiSlider-thumb": {
      height: "23px",
      width: "23px",
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "12px",
      fontWeight: "normal",
      top: "24px",
      backgroundColor: "unset",
      color: theme.palette.background.$3,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.background.$3,
      },
    },
    '& .MuiSlider-mark[data-index="0"]': {
      display: "none",
    },
    '& .MuiSlider-mark[data-index="2"]': {
      display: "none",
    },
    '& .MuiSlider-mark[data-index="1"]': {
      backgroundColor: theme.palette.violet.$5,
      height: "32px",
      width: "2px",
      opacity: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: theme.palette.violet.$5,
      },
    },
  },
  sliderLabel: {
    color: "#AFAFAF",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "normal",
    marginBottom: theme.spacing(1),
  },
});

const tabNameMap = {
  risk_signals: "Risk Signals",
  emotion: "Emotion",
  cohorts: "Topics",
  toxicitySubcategory: "Toxicity",
};

/**
 * Creates classifier profile tabs
 * @param {Array} cohorts list of cohorts in store. fetched from /cohorts/labels
 * @param {Object} chosenConfig current classifier profile. Equals to projectSettings, if creating new classifier profile
 * @param {Object} projectSettings this is always default projectSettings
 * @param {Arrai} toxicityEntries list of toxicity labels
 * @returns
 */
const createTabs = ({
  toxicityEntries,
  // cohorts,
  chosenConfig,
  projectSettings,
}) => {
  const tabNames = [
    tabNameMap.risk_signals,
    tabNameMap.emotion,
    // tabNameMap.cohorts,
    // tabNameMap.toxicitySubcategory,
  ];

  const tabs = tabNames.map((tab) => ({
    name: tab,
    sliders: [],
  }));

  const harmScoreRows = [];
  const { reasonCodeThresholds } = chosenConfig;
  const projectSettingReasonCodeThresholds =
    projectSettings.reasonCodeThresholds;

  if (reasonCodeThresholds) {
    for (const reasonCodeThresholdKey of Object.keys(reasonCodeThresholds)) {
      if (
        reasonCodeThresholdKey === "known_hoax" &&
        reasonCodeThresholdKey === "partisanship"
      ) {
        continue;
      }

      const profileReasonCode = reasonCodeThresholds[reasonCodeThresholdKey];
      let bbDefault =
        projectSettingReasonCodeThresholds[reasonCodeThresholdKey];

      let sliderValue = profileReasonCode;

      if (reasonCodeThresholdKey !== "pagerank") {
        bbDefault = Math.round(bbDefault * 100);
        sliderValue = Math.round(profileReasonCode * 100);
      }

      const row = {
        label:
          LABELS_MAP[reasonCodeThresholdKey] ||
          refineDisplayName(reasonCodeThresholdKey),
        field: reasonCodeThresholdKey,
        bbDefault,
        value: sliderValue,
        min: 0,
        max:
          reasonCodeThresholdKey === "pagerank"
            ? Math.max(100, bbDefault * 1.5)
            : 100,
      };
      harmScoreRows.push(row);
    }
    const harmScoreTab = tabs.find((x) => x.name === tabNameMap.risk_signals);
    harmScoreTab.sliders = harmScoreRows;
  }

  const emotionRows = [];
  const { emotionThresholds } = chosenConfig;
  const projectSettingEmotionThresholds = projectSettings.emotionThresholds;
  if (emotionThresholds) {
    for (const key of Object.keys(emotionThresholds)) {
      const row = {
        label: LABELS_MAP[key] || refineDisplayName(key),
        field: key,
        bbDefault: Math.round(projectSettingEmotionThresholds[key] * 100),
        value: Math.round(emotionThresholds[key] * 100),
        min: 0,
        max: 100,
      };
      emotionRows.push(row);
    }
    const emotionTab = tabs.find((x) => x.name === tabNameMap.emotion);
    emotionTab.sliders = emotionRows;
  }

  /*
  const toxicitySubcategoryRows = [];
  const { toxicitySubcategoryThresholds } = chosenConfig;
  const projectSettingToxicitySubcategoryThresholds = 
  projectSettings.toxicitySubcategoryThresholds;

  if (toxicitySubcategoryThresholds && toxicityEntries) {
    for (const key of toxicityEntries.map((d) => d.entry)) {
      // TO-DO: Revisit and centralize all 3 toxicity scores so "toxicity"
      // The three fields are: toxicity (runtime field), analysis.toxicity.toxic, analysis.toxicity.toxicity
      if (key === "toxic" || key === "toxicity") {
        continue;
      }

      const row = {
        label: LABELS_MAP[key] || refineDisplayName(key),
        field: key,
        bbDefault: Math.round(
          projectSettingToxicitySubcategoryThresholds[key] * 100
        ),
        value: Math.round(toxicitySubcategoryThresholds[key] * 100),
        min: 0,
        max: 100,
      };
      toxicitySubcategoryRows.push(row);
    }
    const toxicitySubcategoryTab = tabs.find(
      (x) => x.name === tabNameMap.toxicitySubcategory
    );
    toxicitySubcategoryTab.sliders = toxicitySubcategoryRows;
  }
  */

  // lookup DEFAULT cohort thresholds by fieldName
  // const defaultCohortThresholds = new Map(
  //   (projectSettings?.cohortThresholds?.data || []).map((cohort) => {
  //     return [
  //       cohort.attributes.cohort.data.attributes.fieldName,
  //       cohort.attributes.threshold * 100,
  //     ];
  //   })
  // );

  // lookup CURRENT cohort thresholds by fieldName
  // const currentCohortThresholds = chosenConfig.cohortThresholds?.data
  //   ? defaultCohortThresholds
  //   : new Map(
  //       Object.entries(chosenConfig?.cohortThresholds || []).map((d) => [
  //         d[0],
  //         d[1] * 100,
  //       ])
  //     );

  // const cohortThresholdRows = cohorts.map((d) => {
  //   return {
  //     label: d.displayName,
  //     field: d.shortName,
  //     bbDefault: defaultCohortThresholds.has(d.shortName)
  //       ? defaultCohortThresholds.get(d.shortName)
  //       : 50,
  //     value: currentCohortThresholds.has(d.shortName)
  //       ? currentCohortThresholds.get(d.shortName)
  //       : 50,
  //     min: 0,
  //     max: 100,
  //   };
  // });

  // const cohortTab = tabs.find((x) => x && x.name === tabNameMap.cohorts);
  // cohortTab.sliders = cohortThresholdRows;

  return tabs;
};

export default function HarmClassifierDrawer() {
  const { projectName } = useParams();
  const showMessage = useMessage();
  
  const theme = useTheme();
  const styles = useStyles({ theme });
  const {
    dispatch,
    state: {
      harmClassifierOpen,
      harmClassifierEdit,
      harmClassifiers,
      harmClassifier,
      projectSettings,
      cohortList,
      platform,
    },
  } = useAppContext();

  const [previewModeOn, setPreviewModeOn] = useState(false);
  const [apply, setApply] = useState(false);
  const [name, setName] = useState(harmClassifierEdit.name || "");
  const [profileExists, setProfileExists] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [tab, setTab] = useState(0);
  const [classifierTabs, setClassifierTabs] = useState([]);
  const sliders = classifierTabs[tab]?.sliders;
  const isDisabled = harmClassifierEdit.isGlobal;

  useEffect(() => {
    setName(harmClassifierEdit.name || "");
    setClassifierTabs(harmClassifierEdit.classifierTabs || []);
    setProfileExists(false);
    setIsEmpty(false);
    setTab(0);
  }, [harmClassifierEdit]);

  const { data: toxicityEntries } = useApi({
    apiKey: QUERY_KEYS.group_labels,
    apiFn: getChildKeys,
    payload: useMemo(() => {
      const classifierProfile = harmClassifierEdit || harmClassifier;
      return {
        db: projectName,
        parent: "analysis.toxicity",
        platform: platform,
        classifierProfile,
      };
    }, [projectName, platform, harmClassifier, harmClassifierEdit]),
    enabled: Boolean(platform && projectName),
  });

  async function setClassifierProfile(newProfile, callback) {
    const updateRes = await setClassifierProfileAPI(newProfile.id);
    if (updateRes) {
      if (callback) {
        callback();
      }
      dispatch({
        type: ACTIONS.SET_HARM_CLASSIFIER,
        payload: newProfile,
      });
    }
  }

  useEffect(() => {
    if (projectSettings && !harmClassifierEdit.id) {
      const tabs = createTabs({
        chosenConfig: projectSettings,
        projectSettings,
        cohorts: cohortList,
        toxicityEntries,
      });
      setClassifierTabs(tabs);
      setTab(0);
    } else {
      const tabs = createTabs({
        chosenConfig: harmClassifierEdit,
        projectSettings,
        cohorts: cohortList,
        toxicityEntries,
      });
      setClassifierTabs(tabs);
      setTab(0);
    }

    // populate/create tabs from saved classifierprofile
  }, [
    cohortList,
    toxicityEntries,
    projectSettings,
    harmClassifierEdit.id,
    harmClassifier,
    harmClassifierEdit,
  ]);

  const handleTabChange = (e, v) => {
    const newClassTabs = [...classifierTabs];
    newClassTabs[tab].sliders = sliders;
    setClassifierTabs(newClassTabs);
    setTab(v);
  };

  const handleSliderUpdate = (index, value) => {
    const classTabs = [...classifierTabs];
    const activeTab = classTabs[tab];
    const activeSliders = [...activeTab.sliders];
    const datum = { ...activeSliders[index] };
    datum.value = value;
    activeSliders[index] = datum;
    activeTab.sliders = activeSliders;
    classTabs[tab] = activeTab;
    setClassifierTabs(classTabs);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleApplyCheck = (e) => {
    setApply(e.target.checked);
  };

  const handleClose = () => {
    dispatch({
      type: ACTIONS.SET_HARM_CLASSIFIER_OPEN,
      payload: false,
    });
  };

  const closeSnackBar = async () => {
    await setClassifierProfile(harmClassifiers[0], () => {
      setPreviewModeOn(false);
    });
  };

  const handleEdit = () => {
    dispatch({
      type: ACTIONS.EDIT_HARM_CLASSIFIER,
      payload: { ...harmClassifier },
    });
    setPreviewModeOn(false);
  };

  const createProfileFieldsFromTabs = (tabs) => {
    const reasonCodeThresholds = Object.assign(
      {},
      ...tabs
        .find((x) => x.name === tabNameMap.risk_signals)
        .sliders.map((x) => {
          return { [x.field]: x.value / 100 };
        })
    );

    // Do not divide PageRank by 100
    const pageRankSliderValue = tabs
      .find((x) => x.name === tabNameMap.risk_signals)
      ?.sliders.find(({ field }) => field === "pagerank")?.value;
    reasonCodeThresholds.pagerank = pageRankSliderValue;

    return {
      reasonCodeThresholds,
      /*
      cohortThresholds: Object.assign(
        {},
        ...tabs
          .find((x) => x.name === tabNameMap.cohorts)
          .sliders.map((x) => {
            return { [x.field]: x.value / 100 };
          })
      ),
      */
      emotionThresholds: Object.assign(
        {},
        ...tabs
          .find((x) => x.name === tabNameMap.emotion)
          .sliders.map((x) => {
            return { [x.field]: x.value / 100 };
          })
      ),
      /*
      toxicitySubcategoryThresholds: Object.assign(
        {},
        ...tabs
          .find((x) => x.name === tabNameMap.toxicitySubcategory)
          .sliders.map((x) => {
            return { [x.field]: x.value / 100 };
          })
      ),
      */
      // sentimentThresholds: Object.assign({}, ...(tabs.find(x => x.name === "Sentiment").sliders.map(x => { return {[x.field]: x.value/100}}))),
    };
  };

  const handleSave = async () => {
    if (name.trim() === "") return setIsEmpty(true);

    if (harmClassifierEdit.id) {
      const newProfile = {
        id: harmClassifierEdit.id,
        name,
        isDefault: false,
        ...createProfileFieldsFromTabs(classifierTabs),
      };
      try {
        const updateRes = await updateClassifierProfile(newProfile);
        if (updateRes) {
          dispatch({
            type: ACTIONS.UPDATE_HARM_CLASSIFIER,
            payload: newProfile,
          });

          showMessage("Risk profile updated", "success");
          handleClose();
          setPreviewModeOn(false);
          if (apply || harmClassifierEdit.id === harmClassifier.id) {
            await setClassifierProfile(newProfile);
          }
        }
      } catch {
        showMessage("Error updating Risk profile", "error");
      }
    } else {
      const exists = harmClassifiers.some((d) => d.name === name);
      setProfileExists(exists);

      if (!exists) {
        const newProfile = {
          id: getRandomId(),
          name,
          ...createProfileFieldsFromTabs(classifierTabs),
          isDefault: false,
        };
        try {
          const createRes = await createClassifierProfile(newProfile);
          if (createRes) {
            newProfile.id = createRes.id;
            dispatch({
              type: ACTIONS.ADD_HARM_CLASSIFIER,
              payload: newProfile,
            });

            showMessage("Risk profile created", "success");
            handleClose();
            setPreviewModeOn(false);

            if (apply) {
              await setClassifierProfile(newProfile);
            }
          }
        } catch {
          showMessage("Error creating Risk profile", "error");
        }
      }
    }
  };

  const handleCloseGlobal = () => {
    if (apply && harmClassifierEdit.isGlobal) {
      setClassifierProfile(harmClassifierEdit);
    }

    handleClose();
  };

  return (
    <Box sx={styles.root}>
      <Drawer
        sx={styles.drawer}
        anchor={"right"}
        open={harmClassifierOpen}
        onClose={handleClose}
      >
        <Box sx={styles.headerTitle}>
          <IconButton sx={styles.backButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography sx={styles.header}>
            {harmClassifierEdit.name || "Create a classifier profile"}
          </Typography>
        </Box>

        <Divider sx={styles.divider} />
        <Box sx={styles.alert}>
          <Alert severity="warning" variant="filled">
            Fine tune any classifier to enhance signals within your dataset. The
            pink line represents the default value.
          </Alert>
        </Box>
        <Box sx={styles.tabs}>
          <CustomTabs
            value={tab}
            onChange={handleTabChange}
            tabs={classifierTabs}
            tabColor="#1C192B"
          />
        </Box>
        <Box sx={styles.content}>
          {(sliders || []).map((slider, i) => {
            return (
              <Box sx={styles.sliderRoot} key={slider.field}>
                <Typography variant="subtitle2" sx={styles.sliderLabel}>
                  {slider.label}
                </Typography>
                <Box sx={styles.sliderWrap}>
                  <Slider
                    size="medium"
                    color="slider"
                    sx={styles.slider}
                    value={slider.value}
                    min={slider.min}
                    max={slider.max}
                    step={1}
                    onChange={(e) => handleSliderUpdate(i, e.target.value)}
                    marks={[
                      { value: slider.min, label: slider.min },
                      { value: slider.bbDefault },
                      { value: slider.max, label: slider.max },
                    ]}
                    valueLabelDisplay="on"
                    disabled={isDisabled}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.nameAndButtons}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" sx={{ pb: 0.5 }}>
              Name your classifier profile
            </Typography>
            <InputValidate
              value={name}
              showError={isEmpty}
              onChange={handleNameChange}
              exists={profileExists}
              emptyWarning={"Name your configuration"}
              existsWarning={`${name} already exists.`}
              placeholder={"e.g. Toxic bots"}
              disabled={isDisabled}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="preview-profile-checkbox"
                checked={apply}
                onChange={handleApplyCheck}
                name="gilad"
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": styles.formLabel
            }}
            label="Preview this profile in current project"
          />
          <Box sx={styles.btns}>
            {/* <Button
              color="secondary"
              variant="contained"
              sx={styles.closeBtn}
              disableElevation
              onClick={handlePreview}
            >
              Preview
            </Button> */}
            {harmClassifierEdit.isGlobal ? (
              <Button
                data-testid="harm-classifier-close-button"
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCloseGlobal}
              >
                Close
              </Button>
            ) : (
              <Button
                data-testid="harm-classifier-save-button"
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSave}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Drawer>
      <Snackbar
        open={previewModeOn}
        autoHideDuration={null}
        message={""}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={styles.previewSnack}
      >
        <Box sx={styles.preview}>
          <Box>
            <Typography variant="subtitle1">
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackBar}
                sx={{ mr: 1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              PREVIEW MODE: {name}
            </Typography>
          </Box>
          <Box>
            <Button
              data-testid="harm-classifier-edit-button"
              size="small"
              color="primary"
              variant="text"
              onClick={handleEdit}
            >
              Edit
            </Button>
            <Button
              data-testid="harm-classifier-save-button"
              size="small"
              color="primary"
              variant="text"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Snackbar>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isEqual } from "lodash";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Typography,
  ListSubheader,
  Checkbox,
  Box,
  useTheme,
} from "@mui/material";

import { ReactComponent as ArrowIcon } from "icons/UI/arrowdown.svg";
import Preloader from "components/UI/Preloader";
import Hint from "components/UI/Hint";
import { PLATFORM_CONFIG } from "utils/constants";

// Custom hook to create styles based on props and theme
const useStyles = ({ theme, ...props }) => {
  return {
    formControl: {
      width: "100%",
      marginTop: 0,
      borderRadius: "4px",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      "& > .MuiInputBase-root": {
        borderRadius: "4px",
        background: () => {
          if (props.transparent) {
            return "transparent";
          }
          return theme.palette.background.$1;
        },
        border: () => {
          if (props.transparent) {
            return null;
          }
          if (props.invalid) {
            return `1px solid ${theme.palette.error.main} !important`;
          }
          if (props.open) {
            return `1px solid ${theme.palette.indigo.$7} !important`;
          }
          return theme.palette.border.widget;
        },
        boxShadow: () => {
          if (props.transparent) {
            return null;
          }
          if (props.open) {
            return "0px 0px 0px 4px rgba(146, 129, 233, 0.20)";
          }
          return null;
        },
      },
      "& .MuiSelect-select": {
        padding: props.compact ? `0px 12px 0px 0px` : `8px 24px 8px 10px`,
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      "& .progress_circle": {
        minHeight: "18px",
      },
    },
    paperRoot: {
      borderRadius: "4px",
      background: theme.palette.background.$1,
      border: "1px solid #302E4F",
      boxShadow: "0px 4px 8px -3px #000000",
      marginTop: "4px",
      "& .MuiList-root": {
        padding: 0,
      },
      maxHeight: "400px",
    },
    selectedText: {
      background: "transparent",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      borderBottom: props.underline ? "1px dashed #FFFFFF !important" : null,
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "24px",
      color: "#fff",
    },
    placeholderTxt: {
      color: "#AFAFAF !important",
    },
    selected: {
      color: "#fff !important",
      backgroundColor: "transparent !important",
      "& span:not(.MuiCheckbox-root)": {
        color: "#fff !important",
      },
    },
    defaultOption: {
      backgroundColor: "transparent !important",
    },
    iconBtn: {
      color: "rgba(231, 231, 239, 0.6)",
    },
    iconDelete: {
      color: "rgba(242, 133, 133, 0.6)",
    },
    root: {
      maxWidth: "600px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      height: "32px",
      padding: theme.spacing("5px", 1.5),
      color: "rgba(193, 191, 215, 0.99)",
      borderLeft: "3px solid transparent",
      paddingRight: "5px",
      "&:hover": {
        color: "#fff",
        "& span:not(.MuiCheckbox-root)": {
          color: "#fff !important",
        },
      },
      "& .MuiCheckbox-root": {
        paddingLeft: 0,
      },
      "& .MuiCheckbox-root:not(.Mui-checked)": {
        color: theme.palette.darkGrey.main,
      },
    },
    listItemText: {
      paddingRight: 1,
      "& .MuiTypography-root": {
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#AFAFAF",
        "&:hover": {
          color: "rgba(231, 231, 239, 1)",
        },
      },
    },
    subheader: {
      fontSize: "12px",
      lineHeight: "40px",
      fontWeight: 400,
      padding: theme.spacing(0, 1.5),
      borderLeft: "3px solid transparent",
      color: "#ffffff",
      textTransform: "uppercase",
      backgroundColor: theme.palette.background.$1,
    },
    footer: {
      borderTop: `1px solid ${theme.palette.background.dropdownActive}`,
      background: theme.palette.background.$1,
      padding: "4px",
      marginTop: "8px",
      position: "sticky",
      bottom: 0,
      zIndex: 2,
    },
    dropdownIcon: {
      width: "7px",
      height: "4px",
      position: "absolute",
      top: "45%",
      right: "10px",
      display: "inline-block",
      pointerEvents: "none",
    },
    inputItem: {
      position: "sticky",
      top: 0,
      zIndex: 2,
      width: "100%",
      lineHeight: "initial",
      padding: theme.spacing(1.5),
      backgroundColor: theme.palette.background.$1,
    },
    popupTitle: {
      color: "#6F7494",
      textTransform: "uppercase",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      lineHeight: "initial",
      padding: theme.spacing(1.5, 2, 1, 2),
      "& p": {
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: "normal",
      },
    },
  };
};

// Main component for platform selection
const PlatformSelect = ({
  options,
  value = [], // Ensure default value is an array
  onChange,
  underline,
  disabled,
  placeholder,
  itemIcon,
  title,
  hint,
  transparent,
  error,
  loading = false,
  loadingText = "Loading...",
  dataTestId = "selected-option",
  ...rest
}) => {
  // State to manage dropdown open/close status
  const [open, setOpen] = useState(false);
  // State to manage the internal value of selected options
  const [internalValue, setInternalValue] = useState(value);

  // Determine if the current selection is invalid
  const invalid =
    error && (!internalValue || (internalValue || []).length === 0);

  // Get theme and styles
  const theme = useTheme();
  const styles = useStyles({
    theme,
    transparent,
    underline,
    compact: !!rest.compact,
    invalid,
    open,
  });

  // Effect to update internal value when value prop changes
  useEffect(() => {
    setInternalValue([value ?? ""]);
  }, [value]);

  // Handle closing of the dropdown
  const handleClose = () => {
    setOpen(false);
  };

  // Handle "Select all" checkbox change
  const handleAllChange = (e) => {
    e.stopPropagation();
    setInternalValue(options.map(o => o.value));
  };

  // Handle individual option change
  const handleChange = (e) => {
    e.stopPropagation();

    // If the selected option is "Select all", then select all options
    if(e.target.value.includes(PLATFORM_CONFIG.all.value)) {
        setInternalValue(options.map(o => o.value));
    } else {
        // Otherwise, select the individual option
        setInternalValue(e.target.value);
    }
  };

  // Apply changes
  const handleApply = () => {
    handleClose();
    onChange({ target: { value: internalValue } });
  };

  // Reset selection
  const handleReset = () => {
    handleClose();
    setInternalValue(options.map(o => o.value));
    onChange({target: {value: options.map(o => o.value)}});
  };

  // Open dropdown and reset search term
  const handleOpen = () => {
    setOpen(true);
    setInternalValue(value);
  };

  // Render the selected value in the dropdown
  const renderSelected = () => {
    if (loading) {
      return (
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "normal",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          component="div"
        >
          <Box>
            <Preloader size={18} thickness={7} />
          </Box>
          {loadingText}
        </Typography>
      );
    }

    const selectedOptions = options
    .filter((d) => value.includes(d.value))
    .map((d) => d.label);

    let label = selectedOptions.length === options.length ? PLATFORM_CONFIG.all.name : selectedOptions.join(", ");
    let isPlaceholder = false;

    if (!value.length && placeholder) {
        label = placeholder;
        isPlaceholder = true;
    }

    return (
    <Box
        sx={{
        ...styles.selectedText,
        ...(isPlaceholder ? styles.placeholderTxt : {}),
        }}
        data-testid="platform-selected-option"
    >
        {label}
    </Box>
    );
  };

  // Check if the current value is the same as the internal value
  const isValueSame = isEqual(value, internalValue);

  // Check if all options are selected
  const isAllSelected = internalValue.length === options.length;

  const isNonSelected = internalValue.length === 0;

  return (
    <FormControl variant="standard" sx={styles.formControl} id={rest.id}>
      <Select
        displayEmpty={!!placeholder}
        MenuProps={{
          style: { zIndex: 999999 },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: {
            "& .MuiMenu-paper": styles.paperRoot,
            "& .MuiMenu-select": styles.select,
            "& .Mui-selected": styles.selected,
          },
          MenuListProps: {
            tabIndex: "",
          },
        }}
        data-testid={dataTestId}
        IconComponent={() => (
          <ArrowIcon fontSize="small" style={styles.dropdownIcon} />
        )}
        renderValue={renderSelected}
        value={internalValue}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        disableUnderline
        disabled={!!disabled}
        variant="standard"
        multiple
        className="platform-select"
      >
        {title && (
          <ListSubheader sx={styles.popupTitle}>
            <Typography>{title}</Typography>
            {hint && <Hint tooltipText={hint} dataTestId={title} />}
          </ListSubheader>
        )}

        {/* Select all */}
        <MenuItem
            value={PLATFORM_CONFIG.all.value}
            sx={{
                ...styles.root,
                display: null
            }}
            data-testid="select-all-option"
            disabled={isAllSelected}
        >
            <Checkbox
                data-testid={`${PLATFORM_CONFIG.all.value}-checkbox`}
                size="small"
                color="primary"
                disableRipple
                disabled={isAllSelected}
                checked={isAllSelected}
                onChange={handleAllChange}
            />
            <ListItemText
                data-testid={`${PLATFORM_CONFIG.all.value}_Select`}
                sx={styles.listItemText} >
                Select all
            </ListItemText>
        </MenuItem>

        {/* Options */}
        {options.flatMap((option, i) => {
          return [
            <MenuItem
              key={option.value + String(i)}
              value={option.value}
              sx={{
                ...styles.root,
                display: null,
                borderBottom: option.highlighted ? "1px solid #282C38" : null,
              }}
              disabled={!!option.disabled}
            >
              {itemIcon && <ListItemIcon>{itemIcon}</ListItemIcon>}
              <Checkbox
                data-testid={`${option.value}-checkbox`}
                size="small"
                color="primary"
                disableRipple
                checked={internalValue.includes(option.value)}
              />
              <ListItemText
                data-testid={`${option.value}_Select`}
                sx={styles.listItemText}
              >
                {option.label}
              </ListItemText>
            </MenuItem>,
          ];
        })}

        {/* Footer */}
        <Box sx={styles.footer}>
            <Button
                data-testid="platform-select-apply-button"
                color={isValueSame ? "darkGrey" : "primary"}
                sx={{ fontWeight: 600, fontSize: "12px" }}
                disabled={isValueSame || isNonSelected}
                onClick={handleApply}
            >
                Apply
            </Button>
            <Button
                data-testid="platform-select-reset-button"
                color={isValueSame ? "darkGrey" : "primary"}
                sx={{ fontWeight: 600, fontSize: "12px" }}
                disabled={isValueSame}
                onClick={handleReset}
            >
                Reset
            </Button>
        </Box>

      </Select>
    </FormControl>
  );
};

export default PlatformSelect;

import { useAppContext } from "context/Context";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  createDynamicFilter,
  FILTER_TYPES,
  getFilterGroup,
  FILTER_GROUPS,
} from "./filterConfig";
import {
  getChildKeys,
  getCustomAPI,
  getFieldEntries,
} from "api/endpoints/AnalyticsApi";
import { useParams } from "react-router-dom";
import { useApis } from "api/hooks/useApi";
import {
  AUTHOR_GROUPS_LIMIT,
  DEFAULT_PROFILE,
  PARENT_USERS,
  QUERY_KEYS,
  USER_COLUMN_LABEL,
} from "utils/constants";
import { formatElasticSearchLabel } from "utils/format";
import langcodes from "utils/langcodes.json";
// Categorical filters
const getCategoricalFilterFields = (fields) => {
  if (!fields) return [];

  return fields.filter((f) => {
    return (
      f.type === FILTER_TYPES.categorical && !f.isParent && !f.options?.length
    );
  });
};

// Filters with customEndpoint
const getCustomURLFilterFields = (fields) => {
  if (!fields) return [];

  return fields.filter((f) => {
    return f.type && f.customEndpoint && !f.isParent && !f.options?.length;
  });
};

// Parent filters
const getParentFilterFields = (fields) => {
  if (!fields) return [];

  return fields.filter((f) => {
    return f.isParent && !f.customEndpoint && !f.options?.length;
  });
};

const generateFilterRequestBodies = (
  dynamicFilters,
  projectName,
  platform,
  harmClassifier
) => {
  if (!dynamicFilters?.length) {
    return [];
  }

  const categorical = getCategoricalFilterFields(dynamicFilters);
  const customApi = getCustomURLFilterFields(dynamicFilters);
  const parents = getParentFilterFields(dynamicFilters);

  return [
    // Categorical requests
    ...categorical.map((cf) => {
      return {
        ...cf,
        apiKey: QUERY_KEYS.field_entries,
        api: getFieldEntries,
        db: projectName,
        field: cf.elasticSearchField,
        platform: platform,
      };
    }),
    // Custom api: Not used in any of the filters.
    // Candidate for deprecation
    ...customApi.map((cf) => {
      return {
        ...cf,
        apiKey: QUERY_KEYS.custom_endpoint,
        api: getCustomAPI,
        db: projectName,
        customEndpoint: cf.customEndpoint,
        platform: platform,
      };
    }),
    // Parent filters
    ...parents.map((cf) => {
      return {
        ...cf,
        apiKey: QUERY_KEYS.group_labels,
        api: getChildKeys,
        db: projectName,
        parent: cf.elasticSearchField,
        platform: platform,
        classifierProfile: harmClassifier,
      };
    }),
  ];
};

export default function useFilters() {
  const { projectName } = useParams();

  const {
    state: {
      platform,
      selectedPlatforms,
      harmProfile,
      harmClassifier,
      dynamic_filters: dynamicFilters,
      userGroups,
      dataLoading,
      unsupportedFeatures,
      modelLabels,
    },
  } = useAppContext();

  const flags = useFlags();

  const requests = generateFilterRequestBodies(
    dynamicFilters,
    projectName,
    platform,
    harmClassifier
  );

  const { data: filterOptionsResp, isLoading: filterOptionsLoading } = useApis(
    requests.map(({ apiKey, api, ...payload }) => ({
      apiKey: apiKey,
      apiFn: api,
      payload: payload,
      enabled: Boolean(
        !dataLoading && dynamicFilters?.length && platform && projectName
      ),
    }))
  );

  /**
   * Gets the display label for a filter option
   * @param {Object} filter - The filter configuration object
   * @param {Object} filterOption - The individual filter option
   * @returns {string} The formatted label to display
   */
  const getLabel = (filter, filterOption) => {
    // Check if we have a custom model label defined
    if (modelLabels?.[filterOption.elasticSearchField]) {
      return modelLabels[filterOption.elasticSearchField];
    }

    // Special handling for AI narratives
    if (filter.field === "ai_narratives") {
      return `narrative-${filterOption.entry}`; 
    }
    
    // Add language name if it's a language filter
    if (filter.elasticSearchField?.includes("language")) {
      return langcodes[filterOption.entry]?.name || filterOption.entry;
    }

    // Format the label if needed, otherwise return raw entry
    return filter.isFormatted
      ? formatElasticSearchLabel(filterOption.entry)
      : filterOption.entry;
  };

  const getOptions = (filter) => {
    if (filter.options?.length) {
      return filter.options;
    }

    if (!dynamicFilters?.length) {
      return [];
    }

    if (!filterOptionsResp || filterOptionsLoading) {
      return [];
    }

    const maxOptions = filter.maxOptions;

    if (filter.type === FILTER_TYPES.boolean) {
      return [
        {
          label: filter.title,
          value: filter.elasticSearchField,
          field: filter.elasticSearchField,
          boolean: true,
        },
      ];
    }

    const filterIndex = requests.findIndex((f) => {
      return f.title === filter.title;
    });

    if (filterIndex > -1) {
      let options = filterOptionsResp[filterIndex];

      if (filter.excludedValues) {
        options = options.filter(
          (option) => filter.excludedValues.indexOf(option.entry) < 0
        );
      }

      if (maxOptions) {
        options = options.slice(0, maxOptions);
      }

      return options.map((fo) => {
        const documentCount = filter.showDocumentCount
          ? fo.documentCount
          : undefined;

        return {
          label: getLabel(filter, fo),
          documentCount,
          value: fo.entry,
          field: fo.entry,
          boolean: true,
        };
      });
    }

    return [];
  };

  const getFilters = () => {
    const filtersObject = {};

    const strapiFilters = (dynamicFilters || []).filter((filter) => {
      // If platforms list is empty, it is general fitler and visible always
      if (!filter.platforms?.length) {
        return true;
      }

      const filterPlatforms = filter.platforms.map((d) => d.name);

      // Show this filter that could apply to any of the selected platforms
      return filterPlatforms.some((p) => {
        return selectedPlatforms.includes(p);
      });
    });

    strapiFilters.forEach((ff) => {
      const dynamicFilter = createDynamicFilter(ff, selectedPlatforms);
      const uiGroup = Object.values(FILTER_GROUPS).find(
        (d) => d.name === ff.uiGroup
      );

      filtersObject[ff.field] = {
        ...ff,
        ...dynamicFilter,
        group: uiGroup || getFilterGroup(ff),
      };
    });

    // Author groups
    if (flags.userGroups && filtersObject.user_group_names) {
      filtersObject.user_group_names.addBtnLimited = (options) => {
        return (options || []).length >= AUTHOR_GROUPS_LIMIT
          ? `You have reached the maximum of ${AUTHOR_GROUPS_LIMIT} ${USER_COLUMN_LABEL} Groups`
          : "";
      };

      filtersObject.user_group_names.addNew = `Create an ${USER_COLUMN_LABEL} Group`;
      filtersObject.user_group_names.usersFilter = true;
      filtersObject.user_group_names.options = userGroups.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
        field: d.name,
        editable: true,
        deletable: true,
        usersFilter: true,
        datum: d,
      }));
    } else {
      delete filtersObject.user_group_names;
    }

    // Parent author group
    if (flags.userGroups && filtersObject.parent_group_names) {
      filtersObject.parent_group_names.usersFilter = true;
      filtersObject.parent_group_names.addNew = `Create an ${USER_COLUMN_LABEL} Group`;

      filtersObject.parent_group_names.options = userGroups.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
        field: d.name,
        editable: true,
        deletable: true,

        datum: d,
      }));

      filtersObject.parent_group_names.disabled =
        !!unsupportedFeatures?.[PARENT_USERS];
    } else {
      delete filtersObject.parent_group_names;
    }

    if (harmProfile.id !== DEFAULT_PROFILE.id) {
      if (filtersObject.is_harmful) {
        filtersObject.is_harmful.disabled = false;
      }
      if (filtersObject.harm) {
        filtersObject.harm.disabled = true;
      }
    } else {
      if (filtersObject.is_harmful) {
        filtersObject.is_harmful.disabled = true;
      }
      if (filtersObject.harm) {
        filtersObject.harm.disabled = false;
      }
    }

    return Object.values(filtersObject)
      .sort((a, b) => {
        return a.order - b.order;
      })
      .map((filter) => {
        return {
          ...filter,
          options: getOptions(filter),
        };
      });
  };

  return getFilters();
}

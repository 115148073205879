import { Box, Alert, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { PROJECT_ACTIONS } from "../utils/constants";
import Preloader from "components/UI/Preloader";
import Connect, { views } from "./Connect";
import S3ExportToggle from "../Projects/S3ExportToggle";
import { CONNECTORS } from "../utils/dataConnectors";

export default function ConnectorPage({
  connectorConfig,
  onContinue,
  fromDrawer = false,
  onCancel = () => {},
  operationMode = PROJECT_ACTIONS.import,
  loading = false,
  s3ExportEnabled = false,
  selectedDataConnector = {},
}) {
  const showS3Toggle = fromDrawer && operationMode === PROJECT_ACTIONS.export;
  const [s3ExportOn, setS3ExportOn] = useState(s3ExportEnabled);
  const [internalError, setInternalError] = useState({});

  const [dataConnectorId, setDataConnectorId] = useState(
    selectedDataConnector?.dataConnectorId || ""
  );
  const [s3Path, setS3Path] = useState(selectedDataConnector?.s3Path || "");

  const handleContinue = async ({ dataConnectorsList = [] }) => {
    setInternalError({});

    const connector = dataConnectorsList.find(
      (d) => d.value === dataConnectorId
    );

    // S3 connector
    if (connectorConfig?.key === CONNECTORS.s3) {
      // If toggling off s3 export
      if (showS3Toggle && !s3ExportOn) {
        return onContinue(null, s3ExportOn);
      }

      // Handle empty path
      if (!s3Path.trim() || !connector) {
        return setInternalError((err) => {
          return {
            ...err,
            s3Path: true,
            dataConnectorId: !connector,
          };
        });
      }

      connector.s3Path = s3Path;
    } 
    
    if (connector) {
      return onContinue(connector);
    }

    return setInternalError((err) => {
      return {
        ...err,
        dataConnectorId: true,
      };
    });
  };

  const handleOnChange = useCallback(({ dataConnectorId, s3Path }) => {
    if (dataConnectorId !== undefined) {
      setDataConnectorId(dataConnectorId);
    }
    if (s3Path !== undefined) {
      setS3Path(s3Path);
    }
  }, []);

  const getHeader = ({ dataConnectorError, dataConnectorsLoading }) => {
    const title =
      operationMode === PROJECT_ACTIONS.import
        ? connectorConfig.dataSourceTitle
        : connectorConfig.dataExportTitle;

    const subtitle =
      operationMode === PROJECT_ACTIONS.import
        ? connectorConfig.dataSourceSubtitle
        : connectorConfig.dataExportSubtitle;

    const info =
      operationMode === PROJECT_ACTIONS.import
        ? connectorConfig.dataSourceInfo
        : "";

    return (
      <>
        <Box sx={{ mb: 5 }}>
          <Box sx={{ textAlign: "center", py: 2 }}>
            <Box
              component={"img"}
              src={connectorConfig?.logo}
              sx={{
                width: "auto",
                height: "90px",
              }}
            />
          </Box>

          {title && (
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "25px",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography
              marginTop={1}
              fontSize={"14px"}
              textAlign="center"
              color={"text.light"}
            >
              {subtitle}
            </Typography>
          )}

          {info && !dataConnectorError && !dataConnectorsLoading && (
            <Alert
              sx={{
                mt: 3,
                fontSize: "14px",
                borderColor: "#6257C0",
                backgroundColor: "#1C182A",
                "& a": {
                  color: "#2BE7A0",
                  textDecoration: "none",
                },
              }}
              severity="info"
              variant="outlined"
              icon={false}
            >
              {info?.map((d, i) => (
                <Box
                  sx={{ py: 0.5 }}
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: d,
                  }}
                ></Box>
              ))}
            </Alert>
          )}
        </Box>

        {showS3Toggle && (
          <S3ExportToggle checked={s3ExportOn} onChange={setS3ExportOn} />
        )}
      </>
    );
  };

  const getFooter = ({
    view,
    dataConnectorsList,
    dataConnectorsLoading,
    statusChecking,
    statusOk,
  }) => {
    const footerStyles = fromDrawer
      ? {
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
          display: "flex",
          padding: "24px",
          borderTop: "1px solid #302E4F",
          justifyContent: "space-between",
          background: "#272438",
        }
      : {};

    return (
      <>
        <Box sx={{ textAlign: "center", ...footerStyles }}>
          {!dataConnectorsLoading && view === views.select_connectors && (
            <Button
              variant="contained"
              sx={{ minWidth: 125 }}
              onClick={() => handleContinue({ dataConnectorsList })}
              id="ContinueButton"
              data-testid="connector-page-continue-button"
              disabled={loading || !statusOk || statusChecking}
            >
              {loading || statusChecking ? <Preloader color="inherit" size={14} /> : "Continue"}
            </Button>
          )}
          {fromDrawer && (
            <Button
              data-testid="connector-page-cancel-button"
              onClick={onCancel}
              variant="outlined"
              color="error"
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: "450px",
        margin: "24px auto",
        paddingBottom: fromDrawer ? "86px" : 0,
      }}
    >
      <Connect
        disabled={showS3Toggle ? !s3ExportOn : false}
        connectorConfig={connectorConfig}
        value={{
          dataConnectorId,
          s3Path,
        }}
        onChange={handleOnChange}
        header={getHeader}
        footer={getFooter}
        errorObject={internalError}
        operationMode={operationMode}
      />
    </Box>
  );
}

/* eslint-disable import/no-unused-modules */
import {
  getDataConnectorProjects,
  getDataConnectorTopics,
} from "api/endpoints/UploadApi";

export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const STAGED = "STAGED";
export const ADD_DOCS = "ADD_DOCS";
export const SET_DOCS = "SET_DOCS";
export const STAGE_DOCS = "STAGE_DOCS";

export const SET_SELECTED_FILES = "SET_SELECTED_FILES";

export const SET_DATA_IMPORT_CONFIG = "SET_DATA_IMPORT_CONFIG";
export const SET_DATA_CONNECTOR = "SET_DATA_CONNECTOR";

export const JOB_STATUSES = {
  in_queue: "In Queue",
  completed: "Completed",
  ready: "Ready",
  failed: "Failed",
  processing: "Processing",
  canceled: "Canceled",
  running: "Running",
  scheduled: "Scheduled",
  NotFound: "NotFound",
  Pending: "Pending",
  succeeded: "Succeeded"
};

export const JOB_STATUS_LABELS = {
  "In Queue": "In Queue",
  Completed: "Complete",
  Succeeded: "Complete",
  Ready: "Ready",
  Failed: "Failed",
  Processing: "Processing",
  Canceled: "Canceled",
  Running: "Processing",
  Scheduled: "Scheduled",
};

export const initialState = {
  documents: [],
  stagedDocuments: [],

  dataImportConfig: {},
  dataConnector: {},

  selectedFilesForJob: [],
};

// Accepted file formats
export const ACCEPTED_FILES = {
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
  // 'application/json': ['.json']
};

// Maximum file size in GB
const MAX_FILE_SIZE_GB = 5;

export const MAX_FILE_SIZE = MAX_FILE_SIZE_GB * 1073741824;

export const MAX_FILE_COUNT = 10;

export const ERROR_CODE_MAP = {
  "file-too-large": "exceeds 5 GB. Please separate this file into smaller files and re-upload.",
  // "file-invalid-type": "is not a CSV, JSON or XLSX file type. Check that the file has an extension and try again."
  "file-invalid-type": "is not a CSV file type. Check that the file has an extension and try again."
}

// Error if we don't know the issue
export const ERROR_GENERIC = "Unknown error. Please try again";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const PROJECT_ACTIONS = {
  import: "import",
  export: "export",
  view_project: "view_project",
  configure_auto_updates: "configure_auto_updates",
};

export const SCHEDULE_OPTIONS = [
  {
    label: "Daily",
    value: "daily",
    timePickers: ["time"],
    validate: ["time"],
  },
  {
    label: "Weekly",
    value: "weekly",
    timePickers: ["time"],
    validate: ["day", "time"],
    options: [
      { label: "Monday", value: "monday" },
      { label: "Tuesday", value: "tuesday" },
      { label: "Wednesday", value: "wednesday" },
      { label: "Thursday", value: "thursday" },
      { label: "Friday", value: "friday" },
      { label: "Saturday", value: "saturday" },
      { label: "Sunday", value: "sunday" },
    ],
  },
  // {
  //   label: "Twice daily",
  //   value: "twice-daily",
  //   timePickers: ["time1", "time2"],
  //   validate: ["time1", "time2"]
  // },
];

export const BB_DEFAULT_TWITTER_API = "Blackbird Twitter API key";
export const BB_DEFAULT_WEBZ_API = "Blackbird Webz.io API key";

export const bbFilterApis = {
  projects: getDataConnectorProjects,
  topics: getDataConnectorTopics,
};

export const getInitialImportState = ({
  dataConnector,
  dataImportConfig,
  connectorConfig,
  mockConnectors
}) => {
  const connectorName = connectorConfig.key;
  const defaultPlatforms = connectorConfig.fields.find(
    (d) => d.field === "selectedPlatform"
  );

  // Set initial value
  let selectedPlatform = defaultPlatforms?.mode === "multi-select" ? [] : "";

  // Already selected platforms take priority
  if (dataImportConfig?.selectedPlatform) {
    selectedPlatform = dataImportConfig?.selectedPlatform;
  } else if (defaultPlatforms?.defaultValue) {
    selectedPlatform = defaultPlatforms?.defaultValue;
  }

  // Selected platform can't be an empty string
  if(selectedPlatform === "") {
    selectedPlatform = [];
  }

  return {
    connectorConfig,
    dataConnectorId: dataConnector?.id,
    dataConnectorSecretKey: dataConnector?.secretKey,
    countNeedsUpdate: false,
    connectorName: connectorName,
    searchQuery: dataImportConfig?.searchQuery || "",
    // TODO: populate dynamically based on config
    dataProviderProjectId: dataImportConfig?.dataProviderProjectId || "",
    dataProviderProjectId_obj: dataImportConfig?.dataProviderProjectId_obj,
    selectedPlatform: selectedPlatform,
    dataProviderTopicId: dataImportConfig?.dataProviderTopicId || "",
    dataProviderTopicName: dataImportConfig?.dataProviderTopicName || "",

    startDate: dataImportConfig?.startDate || null,
    endDate: dataImportConfig?.endDate || null,
    tosChecked: dataImportConfig?.tosChecked || false,

    maxDocCount: +dataConnector?.maxJobExportCount || 0,
    docCount: dataImportConfig?.docCount || 0,
    docCountLoading: false,
    dcLoading: false,
    autoUpdatesOn: dataImportConfig?.scheduled || false,
    scheduleConfig: dataImportConfig?.scheduleConfig || {
      frequency: "daily",
      time: "23:59",
    },
    generalError: null,
    noDocsError: null,
    error: {},
    mockConnectors: mockConnectors
  };
};

export const importDataReducer = (state, action) => {
  return {
    ...state,
    ...(action || {}),
  };
};

import { Alert, Button, Box } from "@mui/material";
import { completeMultipartUpload, getFileUploadSignedUrls } from "api/endpoints/UploadApi";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import DataGridTable from "../components/DataGridTable";
import {
  addNewDocuments,
  setSelectedFiles,
} from "../context/action-creators";
import { useUploaderContext } from "../context/Context";
import Uploader from "./Uploader";
import { useBeforeunload } from "react-beforeunload";
import useEventTracker from "api/hooks/useEventTracker";
import Preloader from "components/UI/Preloader";
import { handleUploadSignedUrlFileParts } from "../utils/uploadUtils";

const columns = [
  {
    field: "visibleFileName",
    headerName: "Filename",
    description: "File name",
    flex: 1,
  },
];

const FileUploadFlow = ({ fromDrawer, onContinue, onCancel = () => {} }) => {
  const tracker = useEventTracker();

  const {
    dispatch,
    state: { documents, selectedFilesForJob },
  } = useUploaderContext();

  const [fileUploadErrors, setFileUploadErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [confirmingFileUpload, setConfirmingFileUpload] = useState(false);
  const [selected, setSelected] = useState(selectedFilesForJob);
  const [processing, setProcessing] = useState(false);
  const [fileSelectErrors, setFileSelectErrors] = useState([]);

  useEffect(() => {
    tracker.track(
      "Step 2 - Data source",
      "select",
      {
        "Data source": "File upload api",
      },
      "Data Connectors / File Upload"
    );
  });

  // todo: move to upload context and save chunks for the future
  const [uploadProgress, setUploadProgress] = useState({
    percent: 0,
    timeLeft: 100,
    filename: "",
  });

  const addDocuments = useCallback(
    (docs) => {
      dispatch(addNewDocuments(docs));
    },
    [dispatch]
  );

  const reset = () => {
    setUploading(false);

    setUploadProgress({
      percent: 0,
      timeLeft: 100,
      filename: "",
    });
    setProcessing(false);
  };

  const onUploadProgress = (progress, fileName, originalFileNamesMap) => {
    const { total, loaded } = progress || { total: 1, loaded: 0 };

    setUploadProgress({
      percent: ((loaded / total) * 100).toFixed(1),
      timeLeft: 100,
      filename: originalFileNamesMap[fileName],
    });
  };

  const uploadFile = async (file, index, documentsToUpload, originalFileNamesMap, filesUploaded, totalFiles) => {
    try {

      // Upload file parts
      setUploading(true);
      file = await handleUploadSignedUrlFileParts(file, index, onUploadProgress, documentsToUpload, originalFileNamesMap);
    
      // Check if all parts have been uploaded
      if(file.parts.length === file.presignedUrls.length) {
        setUploading(false);
        setConfirmingFileUpload(true);

        // Complete the multipart upload
        await completeMultipartUpload(file);
    
        setConfirmingFileUpload(false);

        // Add file to the list
        addDocuments([
          {
              ...file,
              visibleFileName: originalFileNamesMap[file.fileName], //This shows the user the original file name instead of the unique name we generated
              filename: file.fileName,
              id: file.fileName,
          },
        ]);
        onUploadProgress({loaded: 100, total: 100}, documentsToUpload[index].name, originalFileNamesMap);

        filesUploaded++;

        // Check if done
        if(filesUploaded === totalFiles) {
          // All files have been uploaded
          reset();
          
          tracker.track(
            "Added files",
            "add",
            documentsToUpload.map((d) => d.filename).join(","),
            "Data connectors / File Upload Flow"
          );
        }

      } else {
          setConfirmingFileUpload(false);
          throw new Error(`Error uploading a part of ${documentsToUpload[index].name}. Check your connection and try again.`);
      }

    } catch (e) {
      addFileUploadError(e.message);

      // Get ready for next file
      reset();
      return 
    }
  }

  const handleUploads = async (documentsToUpload, originalFileNamesMap) => {
    // Start processing
    setProcessing(true);

    const fileData = documentsToUpload.map((originalFile) => ({
      name: originalFile.name,
      size: originalFile.size,
      type: originalFile.type,
    }));

    // Gets the signed URLs for the files
    const responseData = await getFileUploadSignedUrls(fileData, onUploadProgress);

    // Stop processing and start uploading
    setProcessing(false);
    setUploading(true);

    let filesUploaded = 0;
    const totalFiles = responseData.files.length;

    for (let index = 0; index < responseData.files.length; index++) {
      let file = responseData.files[index];
      if(file.isValid) {
        await uploadFile(file, index, documentsToUpload, originalFileNamesMap, filesUploaded, totalFiles);
      } else {
        // Error uploading file
        addFileUploadError(`Error uploading ${documentsToUpload[index].name}. Please check the file and try again.`);
        return;
      }
    }
  };

  const onRowSelect = (s) => {
    const selectedFiles = documents.filter((d) => s.includes(d.id));
    setSelected(selectedFiles);
  };

  const onFilesAccepted = (newDocuments, incomingOriginalFileNamesMap) => {
    if (newDocuments && newDocuments.length) {
      handleUploads(newDocuments, incomingOriginalFileNamesMap);
    }
  };

  const onFilesRejected = (rejectedFiles) => {
    setFileSelectErrors([...fileSelectErrors,...rejectedFiles])
  };

  const handleContinue = () => {
    if (uploading) {
      addFileUploadError("Wait files to finish uploading");
    } else if (selected.length) {
      setUploading(false);
      dispatch(setSelectedFiles(selected));
      onContinue();
    } else {
      addFileUploadError("Please select files");
    }
  };

  const removeErrorByIndex = (index) => {
    const copy = [...fileSelectErrors];
    copy.splice(index, 1)
    setFileSelectErrors(copy);
  }

  const removeFileUploadError = (itemToRemove) => {
    const copy = [...fileUploadErrors.filter(fileUploadErrors => fileUploadErrors.id !== itemToRemove.id)];
    setFileUploadErrors(copy);
  }

  const addFileUploadError = (error) => {
    setFileUploadErrors([
      ...fileUploadErrors ?? [],
      {id: uuidv4(), message: error}
    ]);
  }

  useBeforeunload((event) => {
    if (uploading) {
      event.preventDefault();
    }
  });

  const footerStyles = fromDrawer
    ? {
        position: "absolute",
        bottom: "0px",
        left: "0px",
        width: "100%",
        borderTop: "1px solid #302E4F",
        background: "#272438",
      }
    : {};

  return (
    <Box
      sx={{
        maxWidth: "600px",
        margin: "0 auto",
        paddingBottom: fromDrawer ? "170px" : 0,
      }}
    >
      <Uploader
        onFilesAccepted={onFilesAccepted}
        onFilesRejected={onFilesRejected}
        uploading={uploading}
        uploadProgress={uploadProgress}
        processing={processing}
      />
      {confirmingFileUpload && (
        <Preloader />
      )}
      {documents.length > 0 && (
        <>
          <Box sx={{ mt: 4 }}>
            <DataGridTable
              checkboxDataTestId="file-upload-table-checkbox"
              columns={columns}
              rows={documents}
              selectedRows={selected}
              onRowSelect={onRowSelect}
              noDataText="No files"
            />
          </Box>

          <Box sx={{ textAlign: "center", ...footerStyles }}>
            <Box sx={{ padding: fromDrawer ? "24px" : "24px 0px" }}>
              <Alert severity="warning" variant="outlined">
                Please double check your file(s) before continuing
              </Alert>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: fromDrawer ? "space-between" : "center",
                borderTop: footerStyles.borderTop,
                padding: fromDrawer ? "24px" : "0",
              }}
            >
              <Button
                data-testid="file-upload-flow-continue-button"
                variant="contained"
                sx={{ minWidth: 125 }}
                onClick={handleContinue}
                disabled={uploading || processing || confirmingFileUpload || selected.length === 0}
              >
                Continue
              </Button>

              {fromDrawer && (
                <Button data-testid="file-upload-flow-cancel-button" onClick={onCancel} variant="outlined" color="error">
                  Cancel
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}

      {fileUploadErrors && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 5,
            gap: 2
          }}>
          {fileUploadErrors.map((item) => {
              return (
                <Alert key={item.id} severity="error" variant="filled" onClose={() => removeFileUploadError(item)}>{item.message}</Alert>
              )
          })}
        </Box>
      )}

      {fileSelectErrors && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 5,
            gap: 2
          }}>
          {fileSelectErrors.map((item, index) => {
            return (
              <Alert key={index} severity="error" variant="filled" onClose={() => removeErrorByIndex(index)}>{item.name} {item.errorMessage}</Alert>
            )
          })}
        </Box>
      )}
    </Box>
  );
};

export default FileUploadFlow;

import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef } from "react";

export default function SearchInput({ onClear, dataTestId = "search-input",  ...props }) {
  const inputRef = useRef();

  // Focus search input if autoFocus is true
  useEffect(() => {
    if (!props.autoFocus) return;
    
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, [props.autoFocus]);

  return (
    <TextField
      className="Elevation-1"
      placeholder="Search"
      variant="outlined"
      size="small"
      data-testid={dataTestId}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box
              component="i"
              className="icon-search"
              sx={{ color: "darkGrey.main" }}
              fontSize="0.8rem"
            />
          </InputAdornment>
        ),
        endAdornment: onClear && (
          <InputAdornment position="end">
            {props.value && (
              <IconButton sx={{ px: "12px" }} disableRipple onClick={onClear}>
                <Box
                  component="i"
                  className="icon-closecirclefilled"
                  sx={{ color: "darkGrey.main" }}
                  fontSize="0.8rem"
                />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      {...props}
      inputRef={inputRef}
    />
  );
}

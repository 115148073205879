import strapi from "api/axiosInstances/axiosStrapi";
import api from "../axiosInstances/axiosAnalytics";

export const getUser = async () => {
  try {
    const res = await api.get("/currentUser", {clearCacheEntry:true});
    return res.data.body;
  } catch {
    return null;
  }
};

export const setUserSettings = async (settings) => {
  const res = await strapi.put("/api/users-permissions/users/me", {
    settings,
  });
  return res.data.settings;
};

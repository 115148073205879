import { Box, Alert } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  checkDataConnectorStatusAndPath,
  getDataConnectorFilters,
  getDataConnectors,
} from "api/endpoints/UploadApi";
import {
  BB_DEFAULT_TWITTER_API,
  BB_DEFAULT_WEBZ_API,
  PROJECT_ACTIONS,
} from "../utils/constants";
import useApi from "api/hooks/useApi";
import { QUERY_KEYS } from "utils/constants";
import Preloader from "components/UI/Preloader";
import ExistingConnectorSelect from "./ExistingConnectorSelect";
import CreateNewConnector from "./CreateNewConnector";
import S3Path from "./S3Path";
import { CONNECTORS } from "../utils/dataConnectors";
import StatusResponse from "./StatusResponse";

export const views = {
  create_new_config: "create_new_config",
  select_connectors: "select_connectors",
  loading: "loading",
};

// Handles Data connector selection and creation
export default function Connect({
  value, // An object { dataConnectorID, s3Path }
  onChange,
  disabled = false,

  connectorConfig,
  operationMode = PROJECT_ACTIONS.import,
  errorObject = {},

  // Inject header and footer
  header,
  footer,
}) {
  const { s3Path, dataConnectorId } = value;

  const [statusChecking, setStatusChecking] = useState(false);
  const [statusResponse, setStatusResponse] = useState(null);
  const [statusOk, setStatusOk] = useState(false);

  const [view, setView] = useState(null);
  const [newDataConnector, setNewDataConnector] = useState(null);
  const error = errorObject || {};

  const {
    data: dataConnectors,
    isLoading: dataConnectorsLoading,
    error: dataConnectorError,
  } = useApi({
    apiKey: QUERY_KEYS.data_connectors,
    apiFn: getDataConnectors,
    payload: { connectorName: connectorConfig?.key },
    enabled: !!connectorConfig?.key,
  });

  const dataConnectorsList = useMemo(() => {
    const connectors = [...(dataConnectors || [])];

    if (
      newDataConnector &&
      !connectors.some((d) => d.id === newDataConnector.id)
    ) {
      connectors.push({
        ...newDataConnector,
        value: newDataConnector.id,
        label: newDataConnector.name,
      });
    }

    return connectors;
  }, [dataConnectors, newDataConnector]);

  const selectedDataConnector = dataConnectorsList.find(
    (d) => d.id === dataConnectorId
  );

  const { data: dataConnectorFilters, isLoading: filtersLoading } = useApi({
    apiKey: connectorConfig?.key,
    apiFn: (payload) => {
      return getDataConnectorFilters(connectorConfig?.key, payload, {
        clearCacheEntry: true,
      });
    },
    payload: {
      data: {
        dataConnector: dataConnectorId,
      },
    },
    enabled: Boolean(
      connectorConfig?.key === CONNECTORS.s3 && selectedDataConnector
    ),
  });

  const getS3Folders = () => {
    return (dataConnectorFilters?.platforms || []).map((d) => {
      return {
        value: d.id || "/",
        label: d.displayName || "/",
      };
    });
  };

  // Preselect default data connector id
  useEffect(() => {
    const bbDefaultDataConnector = dataConnectorsList.find(
      (d) => d.name === BB_DEFAULT_TWITTER_API || d.name === BB_DEFAULT_WEBZ_API
    );

    if (bbDefaultDataConnector && !dataConnectorId) {
      onChange({ dataConnectorId: bbDefaultDataConnector.id });

      // Check default connectors status
      checkStatus(bbDefaultDataConnector.id, s3Path);
    } else if (dataConnectorId) {
      // Auto-Check status for the selected dataConnectorId
      checkStatus(dataConnectorId, s3Path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConnectorsList, onChange]);

  // Default view selection
  useEffect(() => {
    if (dataConnectorsLoading) {
      setView(views.loading);
    } else if (dataConnectorsList.length) {
      setView((v) => {
        // If user already switched to api key view, keep same view
        if (v === views.create_new_config) {
          return v;
        }
        return views.select_connectors;
      });
    } else if (!dataConnectorError) {
      setView(views.create_new_config);
      onChange({ view: views.create_new_config });
    }
  }, [onChange, dataConnectorsList, dataConnectorsLoading, dataConnectorError]);

  const handleConnectorCreation = (connector) => {
    const dc = {
      ...connector,
      s3Path: s3Path,
    };

    setNewDataConnector(dc);

    // Automatically set status to true for non s3 connectors
    if (connectorConfig.key !== CONNECTORS.s3) {
      setStatusOk(true);
    }

    onChange({
      view: views.select_connectors,
      dataConnectorId: dc.id,
    });

    setTimeout(() => {
      setView(views.select_connectors);
    }, 1000);
  };

  const checkStatus = async (dataConnectorId, s3Path) => {
    if (!dataConnectorId) {
      return;
    }

    if (connectorConfig?.key === CONNECTORS.s3 && !s3Path) {
      return;
    }

    try {
      setStatusResponse(null);
      setStatusChecking(true);

      const resp = await checkDataConnectorStatusAndPath(
        dataConnectorId,
        s3Path,
        operationMode
      );

      setStatusOk(resp.body?.status_message === "OK");

      if (resp.body?.status_message !== "OK") {
        setStatusResponse(resp.body);
      }
    } catch (err) {
      const errorData = err?.response?.data;

      if (errorData?.dataConnectorStatus) {
        setStatusResponse(errorData?.dataConnectorStatus);
      } else {
        setStatusResponse({ error_msg: err.message });
      }

      setStatusOk(false);
    } finally {
      setStatusChecking(false);
    }
  };

  const handleConnectorSelect = (id) => {
    onChange({ dataConnectorId: id, s3Path: "" });

    setStatusOk(false);
    setStatusResponse(null);

    if (connectorConfig?.key !== CONNECTORS.s3) {
      checkStatus(id);
    }
  };

  const handlePathSelection = (path) => {
    onChange({ s3Path: path });
    setStatusOk(false);
    setStatusResponse(null);
    checkStatus(dataConnectorId, path);
  };

  const state = {
    view,
    dataConnectorError,
    dataConnectorsList,
    dataConnectorsLoading,
    statusChecking,
    statusOk,
  };

  return (
    <Box>
      {header && header(state)}

      {dataConnectorsLoading && (
        <Box sx={{ mt: 3 }}>
          <Preloader />
        </Box>
      )}

      {dataConnectorError && (
        <Box sx={{ mt: 3 }}>
          <Alert severity="error">
            An error occured while loading Saved connectors
          </Alert>
        </Box>
      )}

      {view === views.create_new_config && (
        <Box sx={{ mt: 2 }}>
          <CreateNewConnector
            showBackButton={!!dataConnectorsList.length}
            connectorConfig={connectorConfig}
            onChangeView={() => {
              setView(views.select_connectors);
              setStatusResponse(null);
              onChange({ view: views.select_connectors });
            }}
            onComplete={handleConnectorCreation}
            disabled={disabled}
          />
        </Box>
      )}

      {view === views.select_connectors && (
        <Box sx={{ py: 2 }}>
          <ExistingConnectorSelect
            options={dataConnectorsList}
            value={dataConnectorId}
            onChange={handleConnectorSelect}
            onChangeView={() => {
              setView(views.create_new_config);
              setStatusResponse(null);
              onChange({ view: views.create_new_config });
            }}
            label={connectorConfig.chooseConnectionLabel}
            buttonText={connectorConfig.createNewConnectionLabel}
            error={
              !dataConnectorId && error?.dataConnectorId
                ? "This field is required"
                : null
            }
            loading={dataConnectorsLoading || statusChecking}
            disabled={disabled || statusChecking}
            loadingText={statusChecking ? "Authenticating..." : "Loading..."}
          />
          {connectorConfig?.key === CONNECTORS.s3 && (
            <Box sx={{ py: 2 }}>
              <S3Path
                operationMode={operationMode}
                value={s3Path}
                onChange={handlePathSelection}
                error={
                  !s3Path && error?.s3Path ? "This field is required" : null
                }
                options={getS3Folders()}
                loading={filtersLoading || statusChecking}
                disabled={disabled || statusChecking}
                loadingText={
                  statusChecking ? "Authenticating..." : "Loading..."
                }
              />
            </Box>
          )}
        </Box>
      )}

      {statusResponse && (
        <Box sx={{ mb: 2 }}>
          <StatusResponse {...statusResponse} />
        </Box>
      )}

      {footer && footer(state)}
    </Box>
  );
}

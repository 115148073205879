import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SimpleTooltip from "components/UI/SimpleTooltip";
import useStateRef from "react-usestateref";

export default function Countdown({
  onFinish,
  timestamp,
}) {
  const elapsedTime = Math.floor((new Date().getTime() - timestamp) / 1000);

  const [timeLeft, setTimeLeft, timeLeftRef] = useStateRef(
    Math.max(60 - elapsedTime, 0)
  );

  useEffect(() => {
    let timeoutId = null;

    const tick = () => {
      const time = Math.max(timeLeftRef.current - 1, 0);

      if (time <= 0) {
        onFinish(true);
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      } else {
        setTimeLeft(time);
        timeoutId = setTimeout(tick, 1000);
      }
    };

    if (timeLeftRef.current > 0) {
      tick();
    }

    return () => clearTimeout(timeoutId);
  }, [onFinish, setTimeLeft, timeLeftRef]);

  return (
    <SimpleTooltip text="Click to move job into Processing">
      <Button
        data-testid="countdown-button"
        variant="text"
        onClick={() => onFinish(false)}
        sx={{
          color: "#AFAFAF",
          fontSize: "13",
          fontWeight: "normal",
          whiteSpace: "nowrap",
          padding: "6px 2px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScheduleIcon
            fontSize="small"
            sx={{ color: "#F7B916", width: "14px", height: "14px" }}
          />
        </Box>
        <Box sx={{ marginLeft: "8px" }}>
          Processing in 0:{timeLeft < 10 ? "0" + timeLeft : timeLeft}
        </Box>
      </Button>
    </SimpleTooltip>
  );
}

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import useEventTracker from "api/hooks/useEventTracker";
import { ReactComponent as QuestionMark } from "icons/UI/question-mark.svg";
import { useState } from "react";
import { SUPPORT_EMAIL } from "utils/constants";

export default function SupportPopup({ styles }) {
  const tracker = useEventTracker();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);

    tracker.track(
      "Clicked support menu",
      "click",
      "navigation",
      "Sidebar navigation"
    );
  };

  const track = (value) => {
    tracker.track(
      "Clicked support menu item",
      "click",
      value,
      "Sidebar navigation"
    );
  };

  const openZenDesk = () => {
    window.open("/helpdesklogin", "_blank");
    track("Help Center");
  };

  const openChangelog = () => {
    window.open(
      "https://blackbirdai.zendesk.com/hc/en-us/articles/9155931581201-Changelog-What-s-new-in-the-Constellation-Dashboard-",
      "_blank"
    );
    track("Release Log");
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ListItem dense sx={styles.listItem} id="helpCenterButton">
        <ListItemButton onClick={handleOpen} sx={styles.listItemButton}>
          <ListItemIcon sx={styles.listItemIcon}>
            <QuestionMark />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "userNameButton",
        }}
      >
        <Typography
          sx={{
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "18px",
            color: "text.muted",
            padding: "8px 20px 4px 20px",
          }}
        >
          SUPPORT
        </Typography>

        <MenuItem onClick={openZenDesk} id="EditProfileButton" data-testid="help-center-button">
          Help Center
        </MenuItem>

        <MenuItem onClick={openChangelog} id="ReleaseLog" data-testid="release-log-button">
          Release Log
        </MenuItem>

        <MenuItem id="EmailItem" data-testid="email-support-button">
          <a
            style={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: "inherit",
            }}
            href={`mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            rel="noreferrer"
          >
            Email Support
          </a>
        </MenuItem>
      </Menu>
    </>
  );
}

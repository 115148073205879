import {
  Pagination, //Could use TablePagination
  PaginationItem,
  Typography,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowRightAlt";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 2,
    marginBottom: 2,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  arrowRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 1,
    margin: "0px",
  },
  iconRight: {
    marginLeft: 1,
    marginRight: 0,
    color: "#76748D",
  },
  iconLeft: {
    marginLeft: 0,
    marginRight: 1,
    color: "#76748D",
    transform: "scaleX(-1)",
  },
  text: {
    color: "#76748D",
    fontSize: "14px",
  },
  title: {
    color: "#76748D",
    marginRight: "20px",
    fontSize: "14px",
  },
  select: {
    minWidth: "62px",
    height: "40px",
    "& fieldset": {
      borderColor: "#282C38",
    },
  },
  input: {
    maxWidth: "75px",
    height: "40px",
    "& fieldset": {
      borderColor: "#282C38",
    },
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "transparent !important",
      color: "#19D5C6",
    },
    "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
      {
        color: "#76748D",
      },
    "& .MuiPaginationItem-ellipsis": {
      color: "#77758E",
    },
  },
};

const Previous = () => {
  return (
    <Box sx={styles.arrowRow}>
      <ArrowForwardIcon sx={styles.iconLeft} />
      <Typography sx={styles.text}>Previous</Typography>
    </Box>
  );
};

const Forward = () => {
  return (
    <Box sx={styles.arrowRow}>
      <Typography sx={styles.text}>Next</Typography>
      <ArrowForwardIcon sx={styles.iconRight} />
    </Box>
  );
};

function TablePaginationActions({
  page,
  onPageChange,
  onPageSizeChange,
  pageSize,
  resultCount,
  loading,
  pageCount,
}) {
  const [localPage, setLocalPage] = useState(page + 1);
  const count = pageCount || Math.ceil(resultCount / pageSize) || 0;

  const handlePageChange = (event, value) => {
    onPageChange(value - 1);
    setLocalPage(value);
  };

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      handlePageChange(null, value);
    },
    // delay in ms
    1000
  );

  const handleInput = (e) => {
    const value = e.target.value;
    const pageNum = Math.max(1, Math.min(count, +value));

    setLocalPage(pageNum);
    debounced(pageNum);
  };

  const handlePageSizeChange = (event) => {
    onPageSizeChange(event.target.value);
  };

  useEffect(() => {
    setLocalPage(page + 1);
  }, [page]);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.row}>
        <Typography sx={styles.title}>Records per page:</Typography>
        <Select
          data-testid="page-size-select"
          labelId="page_size_select"
          value={pageSize}
          onChange={handlePageSizeChange}
          sx={styles.select}
          disabled={loading}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </Box>

      {count > 1 && (
        <>
          <Box sx={styles.row}>
            <Pagination
              disabled={loading}
              sx={styles.pagination}
              count={count}
              size="large"
              siblingCount={1}
              boundaryCount={3}
              page={localPage}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: Previous, next: Forward }}
                  {...item}
                />
              )}
            />
          </Box>

          <Box sx={styles.row}>
            <Typography sx={styles.title}>Go to:</Typography>
            <TextField
              disabled={loading}
              type="number"
              value={localPage}
              onChange={handleInput}
              size="small"
              variant="outlined"
              sx={styles.input}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default TablePaginationActions;

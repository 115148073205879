import { Button, Typography, Box } from "@mui/material";
import FancySpinner from "components/UI/FancySpinner";
import { useHistory } from "react-router-dom";

const ProcessingPage = () => {
  const history = useHistory();

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ py: 3 }}>
        <FancySpinner />
      </Box>
      <Typography variant="h6" sx={{ fontWeight: 400, fontSize: "28px", mb: 3 }}>
        Your data is processing...
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: "#AFAFAF", fontWeight: 400, fontSize: "17px" }}
      >
        We’ll notify you via email when your new project is ready.
        <br></br>
        This process takes a little while.
      </Typography>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          data-testid="processing-page-continue-button"
          variant="contained"
          sx={{ minWidth: 180 }}
          onClick={() => history.push("/manage/projects")}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default ProcessingPage;

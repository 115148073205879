import { Box, Button } from "@mui/material";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import Preloader from "components/UI/Preloader";
import FormItem from "./FormItem";
import { getOptions } from "../utils/utils";
import { states } from "./CreateNewConnector";
import StatusResponse from "./StatusResponse";

export default function ConnectionConfig({
  fields,
  buttonState,
  onSubmit,
  disabled,
  response,
}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    fields.forEach((field) => {
      if (field.required && !formData[field.field]?.trim()) {
        newErrors[field.field] = `This field is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    onSubmit(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      {fields.map((conf, i) => (
        <FormItem
          {...conf}
          key={i}
          loading={false}
          options={getOptions({}, conf.source)}
          state={{
            error: errors,
            ...formData,
          }}
          disabled={disabled}
          onChange={(value) =>
            setFormData((currentValue) => ({
              ...currentValue,
              [conf.field]: value,
            }))
          }
        />
      ))}

      {response && (
        <Box sx={{ mb: 2 }}>
          <StatusResponse {...response} />
        </Box>
      )}

      <Box>
        <Button
          data-testid="connection-config-connect-button"
          type="submit"
          variant="outlined"
          size="medium"
          disabled={
            buttonState === states.loading || buttonState === states.complete
          }
          startIcon={
            buttonState === states.loading ? (
              <Preloader size={16} thickness={1} />
            ) : buttonState === states.loading ? (
              <DoneIcon />
            ) : null
          }
        >
          {buttonState === states.loading
            ? "Connecting"
            : buttonState === states.complete
            ? "Connected"
            : "Connect"}
        </Button>
      </Box>
    </Box>
  );
}

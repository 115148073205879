import {
  EventTypes,
  trackUserEvent,
} from "api/monitoring/userEventTrackingApi";
import { useAppContext } from "context/Context";
import { useCallback } from "react";

export default function useEventTracker() {
  const {
    state: { narrative, project, platform },
  } = useAppContext();

  return {
    track: useCallback((message, type, value, path) => {
      const eventType = EventTypes[type] || String(type);

      trackUserEvent(message, {
        type: eventType,
        value,
        path,
        project: project.name,
        narrative: narrative.name,
        platform,
      });
    }, [narrative.name, project.name, platform]),
  };
}

import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { hotjar } from 'react-hotjar';
import { environment } from "index";

function monitorIdentityHotjar({ username, email, id, organization, orgId }) {
	if (hotjar.initialized()) {
		hotjar.identify(String(id), {
			username,
			email,
			organization,
			orgId,
			environment
		})
	}
}

export function monitorIdentity({ username, email, id, organization, orgId }) {

	const isBugBash = username.includes("bugbuster");
	let r = (Math.random() + 1).toString(36).substring(7);

	LogRocket.identify(isBugBash ? id + r : id, {
		name: isBugBash ? username + r : username,
		email,
	});

	mixpanel.people.set({
		$name: username,
		$email: email,
		Name: username,
		Email: email,
		Org: organization
	});

	mixpanel.identify(id);

	monitorIdentityHotjar({ username, email, id, organization, orgId })

	if (window.userpilot && window.userpilot.initialized) {
		window.userpilot.identify(String(id), {
			username,
			email,
			organization,
			orgId,
			environment
		})
	}
}

import { TextField } from "@mui/material";

export default function TextAreaValidate(props) {
  const {
    errorMsg = "",
    placeholder = "",
    disabled,
    onChange,
    minRows = 1,
		onBlur
  } = props;

  return (
    <TextField
      multiline
      fullWidth
      variant="outlined"
      error={!!errorMsg}
      minRows={minRows}
      disabled={disabled}
      value={props.value}
      placeholder={placeholder}
      onChange={onChange}
      sx={{ "& .MuiInputBase-root": { height: "auto !important" } }}
      inputProps={{ style: { resize: "vertical" } }}
      helperText={errorMsg}
			onBlur={onBlur}
    />
  );
}

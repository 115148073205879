import React from "react";
import reducer, { initialState } from "./reducer";

const Context = React.createContext({
  state: initialState,
  dispatch: () => {},
});

function ContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

const useAppContext = () => React.useContext(Context);

export { ContextProvider, useAppContext };

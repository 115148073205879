import axios from "axios";
import { handleError } from "api/errors/errors";
import { setupCache } from "axios-cache-adapter";
import { JWT_TOKEN_FIELD } from "utils/constants";

const API_URL = process.env.REACT_APP_UPLOADER_API_URL || "/upload-api";

const cache = setupCache({
  maxAge: 60 * 1000,
  exclude: {
    methods: ["patch", "put", "delete"],
  },
  // Invalidate only when a specific option is passed through config
  invalidate: async (config, request) => {
    if (request.clearCacheEntry) {
      await config.store.removeItem(config.uuid);
    }
  },
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
  baseURL: API_URL,
  timeout: 24 * 60 * 60 * 1000, // 24 hours max upload time
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(JWT_TOKEN_FIELD);
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common["Authorization"] = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // show error toaster
  handleError(error);
  return Promise.reject(error);
});

export default api;
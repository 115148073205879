import {
  Alert,
  Box,
  Button,
  Collapse,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import BreakdownGroup from "./BreakdownGroup";
import { JOB_STATUSES } from "pages/DataUploader/utils/constants";
import JobError from "./JobError";
import { DataReceipt } from "pages/DataUploader/utils/dataReceipt";
import BreakdownRow from "./BreakdownRow";

const ToggleButton = styled(Button)({
  padding: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ":hover": {
    background: "transparent",
  },
});

const ToggleView = ({ children, buttonLabel, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Box sx={{ py: 2 }}>
      <ToggleButton disableRipple onClick={() => setOpen((val) => !val)}>
        <Typography variant="toggleButton">{buttonLabel}</Typography>
        {open ? (
          <ExpandLessIcon sx={{ color: "text.muted", width: 16, height: 16 }} />
        ) : (
          <ExpandMoreIcon sx={{ color: "text.muted", width: 16, height: 16 }} />
        )}
      </ToggleButton>

      <Collapse in={open}>
        <Box
          sx={{
            pt: 2,
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default function JobDataReceipt({ job }) {
  if (job.status === JOB_STATUSES.failed) {
    return <JobError job={job} />;
  }

  const dataReceipt = new DataReceipt(job);
  const groups = dataReceipt.getCollapsibles();
  const estimatedDocCount = dataReceipt.getEstimatedDocCount();

  const isProcessing =
    job.status === JOB_STATUSES.processing ||
    job.status === JOB_STATUSES.running;

  return (
    <Box>
      {job.status !== JOB_STATUSES.failed && estimatedDocCount && (
        <BreakdownRow {...estimatedDocCount} />
      )}

      {job.status === JOB_STATUSES.completed &&
        groups.map((group, i) => (
          <ToggleView
            key={i}
            buttonLabel={group.title}
            defaultOpen={group.defaultOpen}
          >
            {group.items.map((d, i) => (
              <Box key={i}>
                <BreakdownGroup config={d} />
              </Box>
            ))}
          </ToggleView>
        ))}

      {isProcessing && (
        <Alert icon={false} variant="filled" severity="info" sx={{ mb: 3 }}>
          Processed document counts are available when this job is complete
        </Alert>
      )}
    </Box>
  );
}

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, useTheme } from "@mui/material";

const useStyles = ({ theme }) => ({
  common: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    fontSize: "12px",
    "& svg": {
      marginRight: "8px",
    },
  },
  error: {
    color: "#CD4051",
    background: "rgba(205, 64, 81, 0.06)",
  },
  success: {
    color: theme.palette.primary.main,
    background: "rgba(57, 243, 197, 0.1)",
  },
});

export default function Message({ type, message }) {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <Box
      sx={{
        ...styles.common,
        ...(type === "error" ? styles.error : styles.success),
      }}
    >
      {type === "error" ? <ErrorIcon /> : <CheckCircleIcon />} {message}
    </Box>
  );
}

import axios from "axios";
import { handleError } from "api/errors/errors";
import { JWT_TOKEN_FIELD } from "utils/constants";

const strapiApi = process.env.REACT_APP_STRAPI_API || "/apiv4";

const strapi = axios.create({
  baseURL: strapiApi,
  timeout: 600000,
});

strapi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(JWT_TOKEN_FIELD);
    if(token) {
      const auth = `Bearer ${token}`;
      config.headers.common["Authorization"] = auth;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

strapi.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // show error toaster
  handleError(error);
  return Promise.reject(error);
});

export default strapi;

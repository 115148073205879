import { useEffect, useMemo, useState } from "react";
import Tabs from "components/UI/TabsNew";
import SimpleTable from "components/UI/SimpleTable";
import { VersionColumns } from "pages/DashboardPage/Shared/columns";
import CollectionRules from "./CollectionRules";
import { useAppContext } from "context/Context";
import HarmTab from "./HarmTab";
import ClassifierTab from "./ClassifierTab";
import { useFlags } from "launchdarkly-react-client-sdk";
import useEventTracker from "api/hooks/useEventTracker";
import useApi from "api/hooks/useApi";
import { getModelVersions } from "api/endpoints/AnalyticsApi";
import { QUERY_KEYS } from "utils/constants";
import { Box } from "@mui/material";

const tabNames = {
  general: "Data",
  classifiers: "Classifiers",
  harm: "Risk Profiles",
  version: "Version",
};

const createSettingsTabs = (flags, aiModelVersions) => {
  const settingsTabs = [
    {
      name: tabNames.general,
      rows: [],
    },
    ...(flags.classifierProfiles
      ? [
          {
            name: tabNames.classifiers,
            rows: [],
          },
        ]
      : []),
    ...(flags.harmProfile
      ? [
          {
            name: tabNames.harm,
            rows: [],
          },
        ]
      : []),
    ...(flags.isBlackBirdEmployee
      ? [
          {
            name: tabNames.version,
            rows: [],
          },
        ]
      : []),
  ];

  if (flags.isBlackBirdEmployee) {
    let versionTab = settingsTabs.find((x) => x.name === tabNames.version);

    if (aiModelVersions) {
      versionTab.rows.push(
        ...aiModelVersions.map((modelVersion) => ({
          label: modelVersion.model,
          version: modelVersion.version,
        }))
      );
    }

    // if version tab is empty
    if (versionTab.rows.length === 0) {
      return settingsTabs.filter((d) => d.name !== tabNames.version);
    }
  }

  return settingsTabs;
};

export default function Settings({ handleClose }) {
  const flags = useFlags();
  const tracker = useEventTracker();
  const [currentTab, setCurrentTab] = useState(0);
  const [tabName, setTabName] = useState("");
  const [settingsTabs, setSettingsTabs] = useState(null);

  const {
    state: { project, platform, projectSettings },
  } = useAppContext();

  const { data: modelVersions } = useApi({
    apiKey: QUERY_KEYS.model_versions,
    apiFn: getModelVersions,
    payload: useMemo(
      () => ({
        db: project?.name,
        req: { platform },
      }),
      [platform, project?.name]
    ),
    enabled: Boolean(project?.name && platform)
  });
  useEffect(() => {
    const localSettingsTabs = createSettingsTabs(flags, modelVersions);
    setSettingsTabs(localSettingsTabs);
  }, [modelVersions, flags]);

  useEffect(() => {
    if (settingsTabs && settingsTabs.length) {
      setTabName(settingsTabs[currentTab]?.name);
    } else {
      setTabName({
        name: tabNames.general,
        rows: [],
      });
    }
  }, [settingsTabs, currentTab]);

  const tabChangeHandler = (e, newTab) => {
    const tab = settingsTabs[newTab];

    if (tab && tabNames.harm) {
      tracker.track(
        "Clicked project settings tab",
        "click",
        tab.name,
        "Top bar / project settings"
      );
    }

    setCurrentTab(newTab || 0);
  };

  return (
    <div>
      <div>
        {!!settingsTabs && (
          <Tabs
            value={currentTab}
            onChange={tabChangeHandler}
            tabs={settingsTabs}
            tabColor="rgb(29, 26, 45)"
          />
        )}
      </div>

      <Box sx={{ p: 2 }}>
        {tabName === tabNames.general && <CollectionRules />}

        {flags.harmProfile &&
          projectSettings &&
          tabName === tabNames.classifiers && (
            <ClassifierTab hidePopup={handleClose} />
          )}

        {tabName === tabNames.harm && <HarmTab hidePopup={handleClose} />}

        {tabName === tabNames.version && !!modelVersions && (
          <SimpleTable
            data={settingsTabs[currentTab].rows}
            columns={VersionColumns}
          />
        )}
      </Box>
    </div>
  );
}

import Box from "@mui/material/Box";

const FancySpinner = () => {
  return (
    <Box sx={{ width: 200, height: 200, margin: "auto" }}>
      <Box className={"fancy_spinner"}></Box>
    </Box>
  );
};

export default FancySpinner;

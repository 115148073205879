import React, { useContext, useReducer } from "react";
import { initialState } from "../utils/constants";
import reducer from "./reducer";

const Context = React.createContext({
  state: initialState,
  dispatch: () => {},
});

function UploaderContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

export const useUploaderContext = () => useContext(Context);

export const withUploaderProvider = (Comp) => {
  return function provider(props) {
    return (
      <UploaderContextProvider>
        <Comp {...props} />
      </UploaderContextProvider>
    );
  };
};

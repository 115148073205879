import { useAppContext } from "context/Context";
import { Button, Typography, Box } from "@mui/material";
import { ALL_PROJECT_INDEX } from "utils/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { clearProjectCache } from "api/endpoints/AnalyticsApi";
import useDocumentsUsage from "hooks/useDocumentsUsage";
import { formatNumberkANDcomma } from "utils/format";
import AddDataButton from "pages/DashboardPage/TopBar/AddDataButton";
import SimpleTable from "components/UI/SimpleTable";
import useMessage from "hooks/useMessage";
import { capitalizeWords, formatMonthAbrDay, getTimeZoneObjectByOffset, getTimezoneString } from "utils/format";

const getInfoList = (project, narratives, timezoneOffset) => {
  const { createdAt } = project;

  const timeframe =
    narratives && narratives.length
      ? `${formatMonthAbrDay(
          narratives[ALL_PROJECT_INDEX].start_date
        )} to ${formatMonthAbrDay(narratives[ALL_PROJECT_INDEX].end_date)}`
      : "";

  const timezone = getTimeZoneObjectByOffset(timezoneOffset);
  const connectors = project?.dataProviders?.length
    ? project?.dataProviders
    : ["File Upload"];

  return [
    ...(createdAt
      ? [{ label: "Date created", value: formatMonthAbrDay(createdAt) }]
      : []),
    { label: "Data source", value: connectors.map(capitalizeWords).join(", ") },
    { label: "Dataset date range", value: timeframe },
    ...(timezone
      ? [
          {
            label: "Time zone",
            value: getTimezoneString(timezone.Offset),
          },
        ]
      : []),
  ];
};

const columns = [
  {
    field: "label",
    sort_field: "label",
    text: "Platform",
    width: "99%",
  },
  {
    field: "value",
    sort_field: "value",
    text: "Documents",
    align: "right",
    format: (value) => formatNumberkANDcomma(value),
  },
  {
    field: "percent",
    sort_field: "percent",
    text: "Project %",
    align: "right",
    format: (v) => v,
  },
];

export default function CollectionRules() {
  const flags = useFlags();
  const showMessage = useMessage();

  const {
    state: { project, narratives, timezoneOffset },
  } = useAppContext();

  const { documentsByPlatform } = useDocumentsUsage();
  const infoList = getInfoList(project, narratives, timezoneOffset);

  const clearCache = async () => {
    const clearCacheRes = await clearProjectCache(project.name);
    if (clearCacheRes) {
      showMessage("Project cache cleared", "success");
    } else {
      showMessage("Error clearing Project cache", "error");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px 0px 5px 0px",
        margin: 0,
        overflow: "auto",
        minHeight: 500,
      }}
    >
      <Box sx={{ py: 1.5 }}>
        <AddDataButton />
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {infoList.map((d, i) => (
          <Box sx={{ width: "50%", py: 1.5, px: "10px" }} key={i}>
            <Typography
              sx={{
                fontWeight: "normal",
                fontSize: "14px",
                color: "#AFAFAF",
                mb: 0.5,
              }}
            >
              {d.label}
            </Typography>
            <Typography
              sx={{ fontWeight: "normal", fontSize: "14px", color: "#FFFFFF" }}
            >
              {d.value}
            </Typography>
          </Box>
        ))}
      </Box>

      {flags.documentsUsage && (
        <Box sx={{ py: 2.5 }}>
          <SimpleTable
            columns={columns}
            data={documentsByPlatform}
          ></SimpleTable>
        </Box>
      )}

      <Box>
        {flags.isBlackBirdEmployee && (
          <Button data-testid="collection-rules-clear-project-cache-button" variant="outlined" color="primary" onClick={clearCache}>
            Clear Project Cache
          </Button>
        )}
      </Box>
    </Box>
  );
}

import { Grid, Skeleton, Typography, Box, useTheme } from "@mui/material";
import Hint from "components/UI/Hint";

const useStyles = ({ theme, ...props }) => ({
  root: {
    padding: theme.spacing(2),
    height: "75px",
    paddingRight: 0,
  },
  value: {
    height: "100%",
    flexGrow: 1,
    overflow: "hidden",
    paddingRight: theme.spacing(1),
    borderRight: props.isLast
      ? "none"
      : "1px solid " + theme.palette.border.main,
  },
  icon: {
    backgroundColor: "#2D283F",
    marginRight: "15px",
    minWidth: "42px",
    height: "42px",
    width: "42px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#757B8C",
    textTransform: "uppercase",
  },
  question: {
    marginLeft: "8px",
    width: "20px",
    height: "20px",
  },
  number: {
    fontStyle: "normal",
    fontSize: "15px !important",
    lineHeight: "20px !important",
    fontWeight: "normal",
    color: "#ffffff",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
});

// Summary Item component that displays the summary data for platforms
export default function SummaryItem(props) {
  const Icon = props.icon;
  const theme = useTheme()
  const styles = useStyles({
    theme,
    isLast: props.isLast,
  });

  const loader = (
    <Skeleton
      variant="rectangular"
      height={20}
      sx={{ marginTop: "4px", borderRadius: "4px", color: "#242234" }}
    />
  );

  return (
    <Grid container alignItems="center" wrap="nowrap" sx={styles.root}>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        sx={styles.icon}
      >
        <Icon style={{ width: 24, height: 24 }} />
      </Grid>

      <Grid item sx={styles.value}>
        <Box sx={styles.title}>
          <Typography variant={"body2"} noWrap sx={styles.label}>
            {props.name}
          </Typography>
          {props.tooltipText && <Hint tooltipText={props.tooltipText} dataTestId={props.name} />}
        </Box>

        <Typography
          variant={"h6"}
          sx={styles.number}
          noWrap
          id={props.id}
          title={typeof props.value === "string" ? props.value : null}
        >
          {props.loading ? loader : props.value}
        </Typography>
      </Grid>
    </Grid>
  );
}

import { Box, Typography } from "@mui/material";
import { useAppContext } from "context/Context";
import { useMemo } from "react";
import {
  formatDateSlash,
  getTimezoneString,
  getPlatformLabel,
  clearProjectFiles,
} from "utils/format";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { JOB_STATUSES } from "pages/DataUploader/utils/constants";
import {
  CONNECTORS,
  DATA_CONNECTORS_LOOKUP,
} from "pages/DataUploader/utils/dataConnectors";
import CustomTooltip from "components/UI/Tooltip";

const getItems = (job, timezoneOffset) => {
  const dataProviderName = job.dataProviderName;

  const connectorConfig = DATA_CONNECTORS_LOOKUP.get(dataProviderName);

  const isFileUpload = dataProviderName === CONNECTORS.fileupload;

  const connectorCols = (connectorConfig?.fields || [])
    .flatMap((d) => [d, ...(d.fields || [])])
    .map((d) => {
      return {
        label: d.displayName,
        field: d.infoField || d.field,
        format: (value) => {
          if (dataProviderName === CONNECTORS.twitter) {
            return (
              <CustomTooltip maxWidth={400} placement="bottom" text={value}>
                <Box
                  sx={{
                    pr: 2,
                    maxWidth: "100%",
                    maxHeight: "94px",
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "4",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                  }}
                >
                  {value}
                </Box>
              </CustomTooltip>
            );
          }

          if (Array.isArray(value)) {
            return (value || []).map(getPlatformLabel).join(", ");
          }
          return value;
        },
      };
    });

  const dateRange =
    job.startDateTime && job.endDateTime
      ? {
          label: "Data date range",
          field: "",
          format: () => {
            return (
              <>
                <Box>
                  {formatDateSlash(job.startDateTime, timezoneOffset)} -{" "}
                  {formatDateSlash(job.endDateTime, timezoneOffset)}
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: "13px",
                    lineHeight: "20px",
                    fontWeight: "normal",
                    color: "text.light",
                  }}
                >
                  {getTimezoneString(timezoneOffset)}
                </Typography>
              </>
            );
          },
        }
      : null;

  return [
    {
      label: "Job trigger",
      field: "",
      format: () => {
        if (isFileUpload) {
          return "File Upload";
        }
        return job.isAutoUpdate || job.status === JOB_STATUSES.scheduled ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ScheduleIcon fontSize="small" sx={{ color: "#F7B916", mr: 0.5 }} />{" "}
            Auto update
          </Box>
        ) : (
          "Manual update"
        );
      },
    },
    {
      label: "Data source",
      format: () => {
        if (!isFileUpload) {
          return connectorConfig?.displayName + " API";
        }

        if (job.projectFiles?.length) {
          return (
            <Box sx={{ wordBreak: "break-all" }}>
              {clearProjectFiles(job.projectFiles).join(", ")}
            </Box>
          );
        }

        return <Box sx={{ wordBreak: "break-all" }}>{job.name}</Box>;
      },
    },
    ...(!isFileUpload ? connectorCols : []),
    ...(dateRange ? [dateRange] : []),
  ];
};

export default function GeneralInfo({ job }) {
  const {
    state: { timezoneOffset },
  } = useAppContext();

  const items = useMemo(
    () => getItems(job, timezoneOffset),
    [job, timezoneOffset]
  );

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", py: 2 }}>
      {items.map((d, i) => (
        <Box key={i} sx={{ width: "50%", my: 2 }}>
          <Typography
            sx={{
              color: "white",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "10px",
            }}
          >
            {d.label}
          </Typography>
          <Box
            sx={{
              color: "text.light",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {d.format(job[d.field])}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

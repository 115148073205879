import { getPercentStr } from "utils/format";
import { useState, useEffect } from "react";
import {
  getOrganizationPostCountByPlatform,
  getOrganizationPostCountCap,
} from "api/endpoints/AnalyticsApi";
import { useAppContext } from "context/Context";
import { LANGUAGES, PLATFORMS, PLATFORM_CONFIG } from "utils/constants";
import { rollups, sum } from "d3-array";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function useDocumentsUsage() {
  const flags = useFlags();

  const [postCount, setPostCount] = useState({});
  const [postCountCap, setPostCountCap] = useState(1500000);

  const {
    state: { languages },
  } = useAppContext();

  useEffect(() => {
    const getOrgCount = async () => {
      const organizationPostCount = await getOrganizationPostCountByPlatform();
      setPostCount(organizationPostCount);
      const organizationPostCountCap = await getOrganizationPostCountCap();
      if (organizationPostCountCap) {
        setPostCountCap(organizationPostCountCap);
      }
    };
    if (flags.documentsUsage) {
      getOrgCount();
    }
  }, [flags.documentsUsage]);

  const documents = Object.keys(PLATFORMS).map((d) => {
    return {
      label: PLATFORMS[d],
      value: postCount[d],
    };
  });

  const totalSum = sum(documents, (d) => d.value);
  const documentsByPlatform = rollups(
    documents,
    (arr) => {
      const summed = sum(arr, (d) => d.value);
      const platform = arr[0].label;

      const langs = (languages[platform] || [])
        .filter((x) => LANGUAGES[x.name])
        .map((x) => {
          return {
            label: LANGUAGES[x.name]?.name,
            lang: x.name,
            value: x.post_ct,
            percent: getPercentStr(x.post_ct, summed, 1),
          };
        });

      return {
        value: summed,
        platform: platform,
        label: PLATFORM_CONFIG[platform]?.name,
        icon: PLATFORM_CONFIG[platform]?.icon,
        languages: langs,
        percent: getPercentStr(summed, totalSum, 1),
      };
    },
    (d) => d.label
  ).map((d) => d[1]);

  // const postCountForPlatform =
  //   sum(
  //     documentsByPlatform.filter((d) => d.platform === platform),
  //     (d) => d.value
  //   ) || 0;

  const documentsByLanguage = rollups(
    documentsByPlatform.flatMap((d) => d.languages),
    (arr) => {
      const summed = sum(arr, (d) => d.value);
      const label = arr[0].label;
      return {
        value: summed,
        label: label,
        emoji: LANGUAGES[arr[0].lang]?.emoji,
        percent: getPercentStr(summed, totalSum, 1),
      };
    },
    (d) => d.label
  ).map((d) => d[1]);

  return {
    // postCountForPlatform,
    postCountCap,
    documentsByPlatform,
    documentsByLanguage,
  };
}

const withFallbackValue = (object, fallbackValue) => {
  return new Proxy(object, {
    get: (target, property) => {
      return (
        target[property] ||
        (typeof fallbackValue === "function"
          ? fallbackValue(property)
          : fallbackValue)
      );
    },
  });
};

export default withFallbackValue;
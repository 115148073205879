import { Box, Skeleton, useTheme } from "@mui/material";
import Hint from "components/UI/Hint";
import { useState } from "react";
import { formatNumber } from "utils/format";
import ChildSummaryPlatformBreakdown from "./ChildSummaryPlatformBreakdown";

const useStyles = ({ theme }) => {
  return {
    root: {
      display: "flex",
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: theme.spacing(0.75, 2),
    },
    label: {
      color: theme.palette.text.light,
      fontSize: "13px",
      lineHeight: "20px",
      fontWeight: "normal",
      wordBreak: "break-word",
      "& svg": {
        verticalAlign: "middle",
      },
    },
    value: {
      textAlign: "right",
    },
    mainNumber: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
    },
    percent: {
      color: theme.palette.text.muted,
      fontSize: "12px",
    },
    loader: {
      padding: theme.spacing(1, 2),
    },
    skeleton: {
      width: "100%",
      background: theme.palette.tile.main,
      borderRadius: "6px",
    },
    title: {
      height: "100%",
      maxWidth: "75%",
    },
  };
};

// Stats Overview Details Child Summary component that displays the summary data for platforms
export default function ChildSummary({
  value,
  percent,
  breakdownValues,
  platformBreakdownExclusion = [],
  label,
  loading,
  tooltipText,
  id,
  hidePercentage,
}) {
  const theme = useTheme();
  const styles = useStyles({ theme });
  
  const [showBreakdown, setShowBreakdown] = useState(false);

  const handleMouseEnter = () => {
    setShowBreakdown(true);
  };

  const handleMouseLeave = () => {
    setShowBreakdown(false);
  };

  const isValueObjectWithKeys = value && typeof value === 'object' && Object.keys(value).length > 0;

  if (loading || isValueObjectWithKeys) {
    return (
      <Box sx={styles.loader} className="detail_row">
        <Skeleton sx={styles.skeleton} variant="rectangular" height={30} />
      </Box>
    );
  }

  return (
    <Box sx={styles.root} className="detail_row">
      <Box sx={styles.title}>
        <Box sx={styles.label}>
          <Box 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {label}{tooltipText && <Hint tooltipText={tooltipText} dataTestId={label} />}
          </Box>
          
          {/* Show the breakdown data on hover*/}
          {showBreakdown && (
            <ChildSummaryPlatformBreakdown title={label} breakdownValues={breakdownValues} platformBreakdownExclusion={platformBreakdownExclusion} />
          )}
        </Box>
      </Box>

      <Box sx={styles.value}>
        <Box sx={styles.mainNumber} id={id}>
          {value !== null ? formatNumber(value) : "-"}
        </Box>
        {!hidePercentage && (
          <Box sx={styles.percent}>
            {percent !== null ? `(${percent}%)` : "-"}
          </Box>
        )}
      </Box>
    </Box>
  );
}

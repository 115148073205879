import moment from "moment";
import { JOB_STATUSES, SCHEDULE_OPTIONS } from "./constants";

const AUTOUPDATES_FRESHNESS_WINDOW_NUMBER_DAYS = 7;

export const isDateOutsideFreshnessWindow = (date) => {
  const dateToCompare = moment().subtract(AUTOUPDATES_FRESHNESS_WINDOW_NUMBER_DAYS, "days")
  return moment(date).isBefore(dateToCompare)
};

export const formatTime = (str) => {
  const formatted = moment(str).format("M/DD/YYYY h:mm A");
  const [start, ...rest] = formatted.split(" ");
  return start + " " + rest.join("\u00a0");
};

export const validateScheduleConfig = (scheduleConfig) => {
  let error = null;

  const selected = SCHEDULE_OPTIONS.find(
    (x) => x.value === scheduleConfig.frequency
  );

  if (selected) {
    error = {};
    selected.validate.forEach((x) => {
      if (!scheduleConfig[x]) {
        error[x] = true;
      }
    });
  } else {
    error = { frequency: true };
  }

  return Object.keys(error).length ? error : null;
};

export const getProjectStatus = (jobs) => {
  // If some of the jobs processing, return processing
  if (
    jobs.some(
      (x) =>
        x.status === JOB_STATUSES.processing ||
        x.status === JOB_STATUSES.running
    )
  ) {
    return JOB_STATUSES.processing;
  }

  // If there is only one job, return that job's status
  const jobsWithoutScheduled = jobs.filter((x) => x.status !== JOB_STATUSES.scheduled)
  if (
    jobsWithoutScheduled.length === 1 &&
    jobsWithoutScheduled[0].status
  ) {
    if (jobsWithoutScheduled[0].status === JOB_STATUSES.completed) {
      return JOB_STATUSES.ready;
    }
    return jobsWithoutScheduled[0].status;
  }

  // Projects require at least one job to reach "complete" status in order for a project to be labeled as "Ready".
  if (jobs.some((d) => d.status === JOB_STATUSES.completed)) {
    return JOB_STATUSES.ready;
  }

  // Just return failed of none of the conditions matched above
  return JOB_STATUSES.failed;
};

export const getOptions = (data, source) => {
  let items = [];

  if (source.type === "static") {
    items = source.items;
  } else if (data && data[source.propName]) {
    items = data[source.propName];
  }

  return items.map((d) => {
    // Handle array of strings
    if (typeof d !== "object") {
      return {
        value: d,
        label: d,
        isBeta: d.isBeta ?? source.areAllBeta,
      };
    }
    return {
      ...d,
      value: String(d[source.key]),
      label: d[source.displayName],
      isBeta: d.isBeta ?? source.areAllBeta,
    };
  });
};

export const sanitizeScheduledJob = (job) => {
  if (job._obj) delete job._obj;
  return job;
};

/**
 * Finds next job run date and time
 * @param {Object} jobSchedule schedule object
 * @param {String} jobSchedule.day day of the week
 * @param {String} jobSchedule.frequency "daily" | "weekly"
 * @param {String} jobSchedule.time HH:MM 24 hour time format
 * @returns UTC time string
 */
export const getNextJobRun = (jobSchedule) => {
  if (!jobSchedule || !jobSchedule.time) {
    return null;
  }

  const weekdays = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  const [hour, minute] = jobSchedule.time.split(":").map((d) => +d);

  let nextJobRunMoment = moment();

  const timeToCheck = moment().set({
    hour: hour,
    minute: minute,
  });

  if (jobSchedule.frequency === "weekly") {
    const dayINeed = weekdays[jobSchedule.day];
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed && timeToCheck.isAfter(moment())) {
      // then just give me this week's instance of that day
      nextJobRunMoment = moment().isoWeekday(dayINeed);
    } else {
      // otherwise, give me *next week's* instance of that same day
      nextJobRunMoment = moment().add(1, "weeks").isoWeekday(dayINeed);
    }
  } else if (jobSchedule.frequency === "daily") {
    // if scheduled time is before current time, add 1 day
    if (timeToCheck.isBefore(moment())) {
      nextJobRunMoment = moment().add(1, "day");
    }
  }

  nextJobRunMoment.set({
    hour: hour,
    minute: minute,
  });

  // return UTC time string
  return nextJobRunMoment.toISOString();
};

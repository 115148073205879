import { Button, Menu, MenuItem, Box, useTheme } from "@mui/material";
import { useAppContext } from "context/Context";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { PROJECT_ACTIONS } from "pages/DataUploader/utils/constants";
import { getConnectors } from "pages/DataUploader/utils/dataConnectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import useEventTracker from "api/hooks/useEventTracker";

const useStyles = ({ theme }) => ({
  root: {
    padding: "8px",
  },
  popoverPaper: {
    background: theme.palette.background.$2,
    border: "1px solid " + theme.palette.background.$3,
    minWidth: "240px",
  },
  iconWrap: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#6F7494",
  },
  itemLabel: {
    padding: "8px 16px",
    color: "#6F7494",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "15px",
  },
  menuItem: {
    color: "#AFAFAF",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "18px",
    "&:hover": {
      background: theme.palette.background.$3,
    },
  },
});

export default function AddDataButton() {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const flags = useFlags();
  const tracker = useEventTracker();

  const {
    state: { project },
  } = useAppContext();

  const availableConnectors = getConnectors(flags);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.target);
    tracker.track(
      "Clicked add data to project",
      "click",
      "",
      "Top bar / Project settings / Data tab"
    );
  };

  const handleImport = (target) => {
    history.push(
      `/manage/projects?activeProject=${project.id}&action=${PROJECT_ACTIONS.import}&target=${target}`
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!project?.id) {
    return null
  }

  return (
    <Box sx={styles.root}>
      <Button
        id="addDataButton"
        data-testid="add-data-to-project-button"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        sx={{ fontWeight: "normal", borderColor: "border.main" }}
      >
        + Add data to project
      </Button>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiMenu-paper": styles.popoverPaper,
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {availableConnectors.map((conf, i) => {
          const Icon = conf.icon || null;
          return (
            <MenuItem
              key={i}
              onClick={() => handleImport(conf.id)}
              disableRipple
              sx={styles.menuItem}
              id={conf.id + "Button"}
            >
              <Box sx={styles.iconWrap}>
                <Icon />
              </Box>
              {conf.actionLabel}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

import { Button, Typography, Box } from "@mui/material";

const useStyles = (props) => ({
  root: {
    width: "337px",
    height: "112px",
    background: props.backgroundColor,
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "18px 16px 18px 16px",
    flex: 1,
  },
  action: {
    backgroundColor: props.actionBackground,
    color: props.actionTextColor,
    width: "100%",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "17px",
    padding: "8px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: props.borderTop,
    "&:hover": {
      backgroundColor: "#2E2C3E",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: "white",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "17px",
    color: "rgba(255, 255, 255, 0.5)",
    marginTop: "4px",
  },
  icon: {
    width: "18px",
    height: "18px",
    color: props.iconColor,
  },
  iconContainer: {
    padding: "9px 10px 9px 10px",
    borderRadius: "4px",
    backgroundColor: props.iconBackground,
    marginRight: "12px",
  },
  text: {},
});

const ErrorSnack = ({
  title,
  icon,
  iconColor,
  iconBackground,
  actionBackground,
  backgroundColor,
  subtitle,
  borderTop,
  actionText,
  actionTextColor,
  onActionClick,
}) => {
  const styles = useStyles({
    iconColor,
    iconBackground,
    actionBackground,
    backgroundColor,
    borderTop,
    actionTextColor,
  });

  const Icon = icon;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.row}>
        <Box sx={styles.iconContainer}>
          <Icon sx={styles.icon} />
        </Box>

        <Box sx={styles.text}>
          <Typography sx={styles.title}>{title}</Typography>
          <Typography sx={styles.subtitle}>{subtitle}</Typography>
        </Box>
      </Box>

      <Button data-testid={title.replace(/\s/g, "-").toLowerCase()} sx={styles.action} onClick={onActionClick}>
        {actionText}
      </Button>
    </Box>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default ErrorSnack;

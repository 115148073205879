import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { login } from "api/endpoints/AnalyticsApi";
import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import toast from "react-hot-toast";
import { JWT_TOKEN_FIELD, NARRATIVE_FEED_KEY } from "utils/constants";
import { Helmet } from "react-helmet";
import useTitle from "hooks/useTitle";

function App() {
  const title = useTitle();

  const [identifier, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const { dispatch } = useAppContext();

  const history = useHistory();

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      // Reset error popups
      toast.dismiss();

      // Ensure context is reset
      dispatch({ type: ACTIONS.LOGOUT });

      const res = await login(identifier, password);
      if(res) {
        localStorage.setItem(NARRATIVE_FEED_KEY, !!res?.user?.organization?.has_narrative_feed);
        localStorage.setItem(JWT_TOKEN_FIELD, res.jwt);
        setLoggedIn(true);
        setLoading(false);
      } else {
        // Extra handling in case there is no JWT in response
        setLoading(false);
        setLoggedIn(false)
        setLoginError("Error logging in. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      setLoginError("Incorrect email or password");
    }
  };

  const forgotPassword = () => {
    history.push("/forgotpassword");
  };

  if (loggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <LoginBackground>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Typography variant="h4" mb={2}>
        Login
      </Typography>

      {loginError && <Message type="error" message={loginError} />}

      <form autoComplete="true" onSubmit={onSubmit}>
        <Box sx={{ my: 2 }}>
          <Typography
            variant="label1"
            component="div"
            sx={{ mb: 1, color: "text.light" }}
            data-testid="login-username-label"
          >
            Username or E-mail
          </Typography>
          <InputValidate
            value={identifier}
            onChange={(event) => setUsername(event.target.value)}
            fieldProps={{
              autoFocus: true,
              disabled: loading,
              id: "SignInUsername",
              "data-testid": "login-username-input"
            }}
          />
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography
            variant="label1"
            component="div"
            sx={{ mb: 1, color: "text.light" }}
            data-tesid="login-password-label"
          >
            Password
          </Typography>
          <InputValidate
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fieldProps={{
              type: "password",
              disabled: loading,
              id: "SignInPassword",
              "data-testid": "login-password-input"
            }}
          />
        </Box>

        <Button
          data-testid="login-button"
          type={"submit"}
          size="medium"
          variant={"contained"}
          fullWidth
          disabled={loading}
          id="SignInButton"
        >
          {loading ? (
            <CircularProgress color="secondary" size={24} thickness={3} />
          ) : (
            "Submit"
          )}
        </Button>
      </form>

      <Button
        variant="text"
        size="medium"
        sx={{ px: 1, mt: 0.5, transform: "translate(0px, 12px)" }}
        onClick={forgotPassword}
        id="ForgotPasswordButton"
        data-testid="forgot-password-button"
      >
        Forgot Password?
      </Button>
    </LoginBackground>
  );
}

export default App;

import { Box, Button, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import AddIcon from "@mui/icons-material/Add";

export default function ExistingConnectorSelect({
  options,
  value,
  onChange,
  onChangeView,
  label = "Choose API key",
  buttonText = "Enter a new API key",
  error,
  loading,
  disabled,
  loadingText,
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mb: "8px",
        }}
      >
        <Typography
          sx={{ color: "#AFAFAF", fontSize: "15px", lineHeight: "19px" }}
        >
          {label}
        </Typography>

        <Button
          data-testid="existing-connector-change-view-button"
          type="text"
          size="small"
          startIcon={<AddIcon fontSize="small" />}
          sx={{ fontWeight: "normal", whiteSpace: "nowrap" }}
          onClick={onChangeView}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </Box>

      <CustomDropdown
        id="SavedConnectionsDropdown"
        dataTestId="existing-connector-select"
        placeholder={"- Select -"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        options={options}
        searchEnabled={options.length > 5}
        error={!!error}
        helperText={error || ""}
        loading={loading}
        disabled={disabled}
        loadingText={loadingText}
      />
    </Box>
  );
}

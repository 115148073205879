import { Alert, AlertColor } from "@mui/material";

export interface StatusBody {
  code: string;
  message: string;
}

export interface StatusResponsePropsType {
  error_msg?: string,
  status_message: "OK" | "Failed" | "No credits remaining";
  warning?: StatusBody;
  failure?: StatusBody;
}

export const getStatusMessage = (
  props: StatusResponsePropsType
): { message: string; severity: string } => {
  let message = props.error_msg || "Something unexpected happened";
  let severity = "error";

  if (props.warning) {
    severity = "warning";
    message = props.warning.message;
  } else if (props.failure) {
    severity = "error";
    message = props.failure.message;
  } else if (props.status_message === "No credits remaining") {
    severity = "warning";
    message = props.status_message;
  }

  return {
    message,
    severity,
  };
};

export default function StatusResponse(props: StatusResponsePropsType) {
  const { message, severity } = getStatusMessage(props);
  return <Alert severity={severity as AlertColor}>{message}</Alert>;
}

import {
  loadDomains,
  loadHashtagsKeywords,
  loadParentUsersKeywords,
  loadPosts,
  loadPostsKeywords,
  loadSubreddits,
  loadUrls,
  loadUsersKeywords,
} from "api/endpoints/AnalyticsApi";

import {
  ENTITY_TYPES,
  PLATFORMS,
  QUERY_KEYS,
  USER_LABEL,
  lookupPlatformConfigItem,
} from "utils/constants";

export const getAnalyzeTabs = (platform) => {
  const subreddits = platform?.includes(PLATFORMS.reddit)
    ? [
        {
          name: "Subreddits",
          tabMapperKey: QUERY_KEYS.subreddits,
          filterKey: QUERY_KEYS.subreddits,
        },
      ]
    : [];

  const domains = lookupPlatformConfigItem(platform, "has_domains_stats")
    ? [
        {
          name: "Domains",
          tabMapperKey: QUERY_KEYS.domains,
          filterKey: QUERY_KEYS.domains,
        },
      ]
    : [];

  return [
    {
      name: "Posts",
      tabMapperKey: QUERY_KEYS.posts,
      filterKey: QUERY_KEYS.clusters,
    },
    {
      name: USER_LABEL,
      tabMapperKey: QUERY_KEYS.users,
      filterKey: QUERY_KEYS.users,
    },
    ...subreddits,
    ...domains,
    {
      name: "Hashtags",
      tabMapperKey: QUERY_KEYS.hashtags,
      filterKey: QUERY_KEYS.hashtags,
    },
    {
      name: "URLs",
      tabMapperKey: QUERY_KEYS.urls,
      filterKey: QUERY_KEYS.urls,
    },
  ];
};

const stringToArray = (str, charToRemove) => {
  return str.split(",").map((d) => {
    let s = d.trim();
    if (Array.isArray(charToRemove)) {
      charToRemove.forEach((char) => {
        s = s.replace(char, "");
      });
      return s;
    }
    return s.replace(charToRemove, "");
  });
};

const entityDelimiters = {
  hashtags: "#",
  users: ["@", "u/"],
  urls: " ",
  subreddits: "r/",
  domains: " ",
};

const getCustomCategoryFilter = (value, type) => {
  if (type === ENTITY_TYPES.domain) {
    return {
      dynamic: {
        field: "domain",
        value: value.map((d) => ({
          elasticSearchField: "domain.domain.keyword",
          boolType: "category",
          isTunable: null,
          value: d,
        })),
      },
    };
  }

  return { [type]: value };
};

/**
 * Merges customSearchQuery's dynamicFilters to analyzeFilters dynamicFilters
 * @param {Object} customSearchQuery custom search query built from analyze dropdown + search
 * @param {Object} analyzeFilters filters object
 * @returns
 */
export const mergeQueries = (customSearchQuery, analyzeFilters) => {
  if (customSearchQuery.dynamic) {
    const obj = JSON.parse(JSON.stringify(analyzeFilters));

    // if filters has dynamicFilters, merge them
    if (obj.dynamicFilters) {
      const filter = obj.dynamicFilters.find(
        (d) => d.field === customSearchQuery.dynamic.field
      );

      if (filter) {
        filter.value.push(...customSearchQuery.dynamic.value);
      } else {
        obj.dynamicFilters.push(customSearchQuery.dynamic);
      }
    }
    // otherwise create it
    else {
      obj.dynamicFilters = [customSearchQuery.dynamic];
    }

    return obj;
  }
  return {
    ...customSearchQuery,
    ...analyzeFilters,
  };
};

export const getCustomEntityQuery = (value, type) => {
  if (!value) return {}; //do not create entity list when search is empty

  // as per PREDASH-3012
  const searchType =
    type.toLowerCase() === USER_LABEL.toLowerCase()
      ? "users"
      : type.toLowerCase();
  let searchValue = value;

  if (Object.keys(entityDelimiters).includes(searchType)) {
    searchValue = stringToArray(value, entityDelimiters[searchType]);
  }

  //return object with array of entities, ex. { users: [realdonaldtrump] }
  return getCustomCategoryFilter(searchValue, searchType);
};

export const getApiFn = (key) => {
  const mapping = {
    [QUERY_KEYS.users]: loadUsersKeywords,
    [QUERY_KEYS.parents]: loadParentUsersKeywords,
    [QUERY_KEYS.hashtags]: loadHashtagsKeywords,
    [QUERY_KEYS.urls]: loadUrls,
    [QUERY_KEYS.postsInCluster]: loadPosts,
    [QUERY_KEYS.clusters]: loadPostsKeywords,
    [QUERY_KEYS.subreddits]: loadSubreddits,
    [QUERY_KEYS.domains]: loadDomains,
  };
  return mapping[key];
};

export const processHashtags = (data) => {
  return data.map((d) => {
    return {
      ...d,
      hashtag: d.display_name,
    };
  });
};

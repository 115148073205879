import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { getConnectors } from "../utils/dataConnectors";
import { useEffect, useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import useEventTracker from "api/hooks/useEventTracker";

const BTN_WIDTH = 180

const useStyles = ({theme}) => ({
  root: {
    textAlign: "center",
  },
  inner: {
    margin: "0 auto",
    display: "inline-block",
  },
  rect: {
    margin: "0 auto",
    background: theme.palette.background.$1,
    border: theme.palette.border.widget,
    borderRadius: "6px",
    width: "145px",
    height: "145px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      maxWidth: "83px",
      maxHeight: "87px",
    },
    "& img": {
      maxWidth: "83px",
      maxHeight: "87px",
    },
  },
  btns: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: 'flex-start',
    maxWidth: BTN_WIDTH * 3 + "px",
  },
  btn: {
    display: "block",
    textAlign: "center",
    width: BTN_WIDTH,
    borderRadius: "6px",
    padding: theme.spacing(1, 0),
  },
  btnTxt: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "8px",
  },
});

const SelectFlow = ({ onSelect }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const flags = useFlags();
  const tracker = useEventTracker();

  const options = useMemo(() => getConnectors(flags), [flags]);

  useEffect(() => {
    tracker.track(
      "Step 1 - Data source",
      "select",
      "",
      "Data Connectors / Select Data Source"
    );
  }, [])

  return (
    <Box sx={styles.root}>
      <Box sx={styles.inner}>
        <Typography sx={{ mb: 2 }}>
          How do you want to create your new project?
        </Typography>

        <Box sx={styles.btns}>
          {options.map((config, i) => (
            <Button
              key={i}
              sx={styles.btn}
              onClick={() => onSelect(config)}
              id={config.id + "Button"}
              data-testid={config.id.replace(/\s/g, "-").toLowerCase() + "-button"}
            >
              <Box sx={styles.rect}>
                <img src={config.logo} />
              </Box>
              <Box>
                <Typography sx={styles.btnTxt}>
                  {config.actionLabel}
                </Typography>
              </Box>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectFlow;

import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useCallback } from "react";

export default function useMessage() {
  const { dispatch } = useAppContext();

  return useCallback((msg, type = "info") => {
    dispatch({
      type: ACTIONS.SHOW_MESSAGE,
      payload: msg
        ? {
            message: msg,
            type: type,
          }
        : null,
    });
  }, [dispatch]);
}

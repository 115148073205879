import { Route, Redirect } from "react-router-dom";
import { JWT_TOKEN_FIELD } from "utils/constants";

export default function PrivateRoute({ children, ...rest }) {
	const isAuthenticated = !!localStorage.getItem(JWT_TOKEN_FIELD);

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

import { useAppContext } from "context/Context";

const useTitle = () => {
  const {
    state: { projects },
  } = useAppContext();

  const suffix = " | Blackbird.AI";
  const urlParts = window.location.pathname.split("/");
  const page = urlParts[urlParts.length - 1];
  const dashboardIndex = urlParts.findIndex((d) => d === "dashboard");
  const path = page ? page[0].toUpperCase() + page.slice(1) : "";

  let projectName = "";

  // if project found, show project
  if (dashboardIndex > -1) {
    const project = (projects || []).find(
      (d) => d.name === urlParts[dashboardIndex + 1]
    );

    if (project) {
      projectName = project.displayName;
    } else {
      projectName = urlParts[dashboardIndex + 1];
    }
  }

  if (urlParts.includes("create")) {
    return "Create a Project" + suffix;
  }

  if (projectName) {
    if (path) {
      return projectName + " | " + path + suffix;
    } else {
      return projectName + suffix;
    }
  } else if (path) {
    return path + suffix;
  }

  return "Narrative & Risk Intelligence Platform" + suffix;
};

export default useTitle;

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CustomTooltip from "./Tooltip";
import { cleanDataTestId } from "utils/format";

export default function Hint({ tooltipText, width = 16, height = 16, dataTestId = "" }) {
  return (
    <CustomTooltip text={tooltipText}>
      <HelpOutlineIcon
        data-testid={cleanDataTestId(dataTestId)}
        sx={{
          width: width + "px",
          height: height + "px",
          marginLeft: "6px",
          fontWeight: "normal",
          color: "#6F7494",
          cursor: "pointer",
        }}
      />
    </CustomTooltip>
  );
}
